import React, {
  useMemo,
  // useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { HiDotsVertical } from 'react-icons/hi';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import styles from './products-table.module.scss';
import { IProduct } from '~/types/products';
import PositionalModal from '~/lib/PositionalModal';
import withModalConfirm from '~/lib/withModalConfirm';
import { useDeleteOrderProduct } from '~/hooks/orders';

//* Products magasins orders

const columns = [{
  header: 'Nom de l\'article',
  accessorKey: 'product.name',
}, {
  header: 'Libellé de la marque',
  accessorKey: 'product.brand',
}, {
  header: 'P. Unité',
  accessorKey: 'product.price',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return `${value}€`;
  },
}, {
  header: 'Quantité',
  accessorKey: 'quantity',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return <span>{value}</span>;
  },
}, {
  header: 'Remise',
  accessorKey: 'discount',
  cell: ({ row } : { row: any }) => {
    const data = row.original;
    // const discount = ((data.product.pricePA - data.product.price) * data.quantity).toFixed(2);
    return <span>{`${(data.product.discount || 0).toFixed(2)}€`}</span>;
  },
}, {
  header: 'P. Total',
  accessorKey: 'totalPrice',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return `${value}€`;
  },
}];

// eslint-disable-next-line max-len
const ButtonActions = withModalConfirm(({ confirm, productId, orderId } : { confirm: any, productId: string, orderId: string }) => {
  type ModalRefHandler = React.ElementRef<typeof PositionalModal>;
  const modalRef = useRef<ModalRefHandler>(null);
  const cellRef = useRef<HTMLParagraphElement>(null);
  const navigate = useNavigate();
  const {
    mutate: deleteOrderProduct,
    isLoading: isDeleteOrderProductLoading,
  } = useDeleteOrderProduct();

  const handleDelete = () => {
    confirm(
      'Êtes-vour certain de vouloir supprimer ce produit de la commande ?',
      () => {
        deleteOrderProduct({
          orderId,
          productId,
        });
      },
    );
  };

  return (
    <>
      <span
        onClick={() => modalRef.current?.open()}
      >
        <HiDotsVertical size={24} />
        <span ref={cellRef} className={styles.position}></span>
      </span>
      <PositionalModal
      ref={modalRef}
      parent={cellRef}
      containerStyles={{
        padding: 5,
      }}
      >
        <div
          className={styles.modalActions}
          onMouseLeave={() => {
            modalRef.current?.close();
          }}
        >
          <p onClick={() => navigate(`/produits/${productId}`)}>Modifier</p>
          <p
            style={isDeleteOrderProductLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}}
            onClick={handleDelete}
          >
            Supprimer
          </p>
        </div>
      </PositionalModal>
    </>

  );
});

const ProductsTable = (
  {
    products,
    orderId,
    orderStatus,
    isProvider = false,
  } : {
    products: { product: IProduct, quantity: number, totalPrice: number }[],
    orderId: string,
    orderStatus: string,
    isProvider?: boolean
  },
) => {
  const [sorting, setSorting] = useState<any>([]);

  const data = useMemo(() => products, [products]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  // const onRowClick = (row: Row<IProduct>) => {
  //   console.log(row);
  // };

  return (
    <div className={styles.productsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
              {orderStatus !== 'valid' && (
                <th style={{ width: 0 }}></th>
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  <p>
                    {flexRender(
                      cell.column.columnDef.cell,
                      {
                        ...cell.getContext(),
                      },
                    )}
                  </p>
                </td>
              ))}
              {(!isProvider && orderStatus !== 'valid') && (
                <td className={styles.actions}>
                  <ButtonActions orderId={orderId} productId={row.original.product._id} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// const ResponsivProductsTable = (
//   {
//     products,
//     handleSorting,
//     pathToDetail,
//   } : {
//     products: IProduct[],
//     handleSorting: any,
//     pathToDetail?: string,
//   },
// ) => {
//   const isTablet = useMediaQuery('(max-width: 1180px)');
//   return (
//     <>
//       {isTablet ? (
//         <ul className={styles.listTablet}>
//           {products.map((product: IProduct) => (
//             <li key={`product-${product._id}`}>
//               <div className={styles.product}>
//                 <p className={styles.name}>{product.name}</p>
//                 <p>{product.brand}</p>
//                 <div className={styles.ref}>
//                   <p>
//                     Référence produit <span>{product.ref}</span>
//                   </p>
//                 </div>
//               </div>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <ProductsTable
//           products={products}
//           handleSorting={handleSorting}
//           pathToDetail={pathToDetail}
//         />
//       )}
//     </>
//   );
// };

export default ProductsTable;
