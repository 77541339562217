import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAccount } from '~/hooks/account';
import style from './offers.module.scss';
import objetVectoriel1 from '../../../assets/images/exhibitions/objet-dynamique vectoriel-1.png';
import objetVectoriel2 from '../../../assets/images/exhibitions/objet-dynamique-vectoriel-2.png';
import salonOffres from '../../../assets/images/exhibitions/salon-offres.png';
import yellowCircle from '../../../assets/images/exhibitions/yellow-circle.png';

// const Offers = ({ secondButton }: { secondButton?: boolean }) => {
const Offers = () => {
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } : any = account || {};

  return (
    <div className={style.offers}>
      <div className={style.content}>
        <img src={objetVectoriel1} alt="objet dynamique vectoriel" className={style.objetVectoriel1}/>
        <div className={style.mainContent}>
        <img src={salonOffres} alt="Les offres Bazar 2024"/>
        <p>Sélectionnez les offres qui vous intéressent et ajoutez-les à votre panier.
          Présentez-vous au Salon U Bazar pour valider vos offres.</p>
          {companyAccount?.type === 'magasin' && (
            <>
              <NavLink to='/produits'>
                <button className={style.yellowButton}>Précommander mes offres</button>
              </NavLink>
              {/* {secondButton && (
                <button className={style.whiteButton}>Voir la liste des fournisseurs</button>
              )} */}
            </>
          )}
          {companyAccount?.type === 'fournisseur' && (
            <>
              <NavLink to='/produits'>
                <button className={style.yellowButton}>Mes offres</button>
              </NavLink>
            </>
          )}
        </div>
      </div>
      <div className={style.secondContent}>
        <img src={yellowCircle} alt="Homme dans un caddie avec un haut-parleur"/>
        <img src={objetVectoriel2} alt="objet dynamique vectoriel" className={style.objetVectoriel2}/>
      </div>
    </div>
  );
};

export default Offers;
