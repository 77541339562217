import React, { useMemo } from 'react';
import { useForgotPassword } from '../../hooks/account';
import getErrorAuthMessage from '../../utils/auth-helpers';
import EmailResetPwdForm from '../../components/form/EmailResetPwdForm';
import logoU from '../../assets/images/logo-u.png';
import imgSignin from '../../assets/images/signin-logo.png';
import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const {
    mutate: forgotPassword, isSuccess, error,
  } = useForgotPassword();
  const errorApi: any = error;

  const submitForgot = async (data: { email: string }) => {
    try {
      await forgotPassword(data);
    } catch (err) {
      console.log(err);
    }
  };

  // const errorMessage = useMemo(() => {
  //   if (!isError) return '';
  //   if (errorApi?.response?.status === 422)
  // return 'Aucun compte ne correspond à cette adresse mail.';
  //   return 'Une erreur est survenue';
  // }, [errorApi, error]);

  const errorMessage = useMemo(() => {
    if (!error) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.forgotPassword}>
      <div className={styles.logo}>
        <img src={logoU} alt= "Logo U enseigne" />
      </div>
      <div className={styles.container}>
          <div>
            <h1>Bienvenue au</h1>
            <img src={imgSignin} alt= "Bienvenue au salon U" />
          </div>
          <div className={styles.form}>
            <h1>Mot de passe oublié</h1>
            <EmailResetPwdForm
              submit={submitForgot}
              signinLink="/login"
              succeedMessage={isSuccess ? 'Un lien permettant de réinitialiser votre mot de passe vous a été envoyé' : ''}
              errorMessage={errorMessage}
            />
          </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
