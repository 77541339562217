import React from 'react';
import Footer from '~/components/Footer';
import styles from './waiting.module.scss';

const WaitingProduct = () => (
  <div className={styles.waiting}>
    <div className={styles.content}>
      <h1>
        Mise en ligne des offres à partir du 3 juin.
      </h1>
    </div>
    <Footer />
  </div>
);

export default WaitingProduct;
