import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import styles from './pagination.module.scss';

export type PaginationFormHandle = {
  setPage: (page: number) => void;
};

type PaginationFormProps = {
  pages: number,
  defaultPage: number,
  handleChange: (p: number) => void,
};

const Pagination: ForwardRefRenderFunction<
PaginationFormHandle, PaginationFormProps> = (
  { pages = 0, defaultPage = 0, handleChange },
  ref,
) => {
  const [page, setPage] = useState(defaultPage);

  useImperativeHandle(ref, () => ({
    setPage: (p: number) => setPage(p),
  }), []);

  const changePage = (p: number) => {
    handleChange(p);
    setPage(p);
  };

  const getIndex = (i: number) => {
    if (pages <= 9) return i + 1;
    if (page > pages - 4) return i + pages - 8;
    return page - 4 > 0 ? i + (page - 4) : i + 1;
  };

  return (
    <div className={styles.pagination}>
      {pages <= 9 ? (
        <ul>
          {new Array(pages).fill(null).map((d, i) => (
            <React.Fragment key={`page-${i}`}>
              <li>
                <div
                  onClick={() => changePage(getIndex(i))}
                  className={
                    page === getIndex(i)
                      ? `${styles.square} ${styles.active}`
                      : styles.square
                  }
                >
                  <span>{getIndex(i)}</span>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <>
          <div
            onClick={() => changePage(page - 1)}
            className={
              page === 1 ? `${styles.square} ${styles.disabled}` : styles.square
            }
          >
            <FaChevronLeft />
          </div>
          <ul>
            {getIndex(0) > 1 && (
              <>
                <li>
                  <div
                    onClick={() => changePage(1)}
                    className={
                      page === 1
                        ? `${styles.square} ${styles.active}`
                        : styles.square
                    }
                  >
                    1
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => changePage(getIndex(1))}
                    className={
                      page === getIndex(0)
                        ? `${styles.square} ${styles.active}`
                        : styles.square
                    }
                  >
                    <span>...</span>
                  </div>
                </li>
              </>
            )}
            {new Array(9).fill(null).map((d, i) => (
              <React.Fragment key={`page-${i}`}>
                {((page < 6 && i < 7) || (page < pages - 4 && page >= 6 && i > 1 && i < 7)
                  || (page >= pages - 4 && i > 1)) && (
                  <li>
                    <div
                      onClick={() => changePage(getIndex(i))}
                      className={
                        page === getIndex(i)
                          ? `${styles.square} ${styles.active}`
                          : styles.square
                      }
                    >
                      <span>{getIndex(i)}</span>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
            {getIndex(8) !== pages && (
              <>
                <li>
                  <div
                    onClick={() => changePage(getIndex(7))}
                    className={
                      page === getIndex(7)
                        ? `${styles.square} ${styles.active}`
                        : styles.square
                    }
                  >
                    <span>...</span>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => changePage(pages)}
                    className={
                      page === pages
                        ? `${styles.square} ${styles.active}`
                        : styles.square
                    }
                  >
                    {pages}
                  </div>
                </li>
              </>
            )}
          </ul>
          <div
            onClick={() => changePage(page + 1)}
            className={
              page === pages
                ? `${styles.square} ${styles.disabled}`
                : styles.square
            }
          >
            <FaChevronRight />
          </div>
        </>
      )}
    </div>
  );
};

export default forwardRef(Pagination);
