import React, {
  ForwardRefRenderFunction,
  useRef, useContext, forwardRef, useImperativeHandle, useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { IoQrCode, IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import { IFormation } from '~/types/formations';
import { IOption } from '~/types/options';
import { downloadSlots, convertDecimalTime } from '~/utils';
import { AccountContext } from '~/accountContext';
import { useGetFormationsList } from '~/hooks/formations';
import { useAccount } from '~/hooks/account';
import { useValidateUserFormationPresence } from '~/hooks/users';
import useFetch from '~/hooks/useFetch';
import iconExport from '~/assets/images/icon-export.svg';

import Modal from '~/lib/Modal';
import ModalScan from '../ModalScan';
import styles from './modal-timeslot-export.module.scss';

export type ModalTimeslotExportHandle = {
  open: () => void,
  close: () => void;
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalTimeslotExportHandle, {
  formation: IFormation,
  timeslot?: any,
  handleClose: () => void,
}> = ({
  formation,
  timeslot,
  handleClose,
}, ref) => {
  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const modalRef = useRef<any>(null);
  const { data } = useAccount();
  const { user: currentUser } = useContext(AccountContext);
  const account = data?.account;

  const { data: response, get: getFormationTimeslot } = useFetch(
    `${process.env.REACT_APP_API_URL}/formations/${formation?._id}/timeslots/${timeslot?._id}?getBy=${currentUser._id}`,
    true,
    !(!formation?._id || !timeslot?._id),
  );
  const timeslotData = response?.formation;
  const currentTimeslot = timeslotData?.timeslots?.[0];

  const { data: list } = useGetFormationsList();
  const {
    days = [],
  } = list || {};

  const {
    mutateAsync: validateUserFormationPresence,
  } = useValidateUserFormationPresence();

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }), []);

  const submit = async (result: { userId: string | null } | null) => {
    let succeed = false;
    if (result?.userId) {
      try {
        await validateUserFormationPresence({
          _id: result.userId,
          timeslot: timeslot._id,
          formation: formation._id,
        });
        getFormationTimeslot();
        succeed = true;
      } catch (err: any) {
        if (err.response.data.error === 'user presence already validated') {
          toast.error('Cette utilisateur a déjà été validé');
        } else if (err.response.data.error === 'The requested user was not found') {
          toast.error('L’utilisateur n’a pas été reconnu');
        } else {
          toast.error('La présence n\'a pu être confirmer !');
        }
      }
    }
    return succeed;
  };

  const labelDay = useMemo(() => {
    if (!currentTimeslot?.day) return '';
    return days.find((option: IOption) => option.value === currentTimeslot.day)?.label || '';
  }, [days, currentTimeslot?.day]);

  const availablePlaces = useMemo(() => {
    if (!formation || typeof formation.seats !== 'number' || !currentTimeslot?.registeredUsers) return 0;
    return formation.seats - currentTimeslot.registeredUsers.length;
  }, [formation, currentTimeslot]);

  const isAllRegisteredUsersScanned = useMemo(() => {
    if (!timeslot) return true;
    const bool = (currentTimeslot?.registeredUsers || [])
      .every((user: any) => user.attended.includes(timeslot._id));
    return bool;
  }, [timeslot]);

  return (
    <>
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider la présence'
        submit={submit}
        validate={() => true}
        // validate={
        //   (result) => (currentTimeslot?.registeredUsers || [])
        //     .map((user: any) => user._id).includes(result?.userId)}
        >
        {(success, retry) => (
          <>
            {success ? (
              <div className={styles.presenceValidated}>
                <h1>Présence confirmée !</h1>
                {(availablePlaces > 0 || !isAllRegisteredUsersScanned) && (
                  <button onClick={() => retry()}>
                    Scanner un nouveau badge
                  </button>
                )}
              </div>
            ) : (
            <div className={styles.orderValidated}>
              <h1 className={styles.error}>{'Impossible de confirmer la présence ! Veuillez vérifier que la personne est bien inscrite.'}</h1>
              <button onClick={() => modalScanRef.current?.close()}>Retour</button>
            </div>
            )}
          </>
        )}
      </ModalScan >
      <Modal
        width={720}
        ref={modalRef}
        handleClose={() => {
          handleClose();
        }}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            <h3>
              {labelDay}
              {currentTimeslot && (
                <span>
                  &nbsp;{currentTimeslot.label}&nbsp;-&nbsp;
                  {convertDecimalTime(currentTimeslot.value + (currentTimeslot.duration / 60))}
                </span>
              )}
            </h3>
            {(availablePlaces > 0 || !isAllRegisteredUsersScanned) && (
              <a className={styles.scanner}>
                <button onClick={() => modalScanRef.current?.open()}>
                  <IoQrCode size={30} />
                  <span>Scan badge</span>
                </button>
              </a>
            )}
            <button onClick={() => downloadSlots(formation._id, timeslot?._id, account?.role === 'Member' ? currentUser._id : '')}>
              <img src={iconExport} />
            </button>
          </div>
          <div className={styles.users}>
            {(currentTimeslot?.registeredUsers || []).map((user: any) => (
              <div key={`user-${user._id}`} className={styles.user}>
                <p className={styles.name}>{user.profile.firstName} {user.profile.lastName}</p>
                <div className={styles.detail}>
                  <p>
                    <span>Mail:</span> {user.email}
                  </p>
                  <p>
                    <span>Fonction:</span> {user.userFunction || 'Non renseigné'}
                  </p>
                  <p>
                    <span>Magasin:</span> {user?.company?.name}
                  </p>
                  {timeslot && (
                    <p className={(user.attended || []).includes(timeslot?._id) ? `${styles.presence} ${styles.validated}` : styles.presence}>
                      Participé
                      {(user.attended || []).includes(timeslot._id)
                        ? <FaCheck />
                        : <IoClose />
                      }
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default forwardRef(ModalTimeslots);
