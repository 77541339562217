import React, { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetFormations } from '~/hooks/formations';
import FormationsTable from '~/components/table/FormationsTable';
import styles from './list-formations.module.scss';
import { IFormation, IFormationTimeSlot } from '~/types/formations';
import InputSearch from '~/components/InputSearch';
import { AccountContext } from '~/accountContext';

const ListFormations = () => {
  const { user: currentUser } = useContext(AccountContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: formations } = useGetFormations({
    ...paramsObject,
    speaker: currentUser._id,
    search: debouncedSearch,
  });

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const formatedFormations = useMemo(() => (formations || []).map((formation: IFormation) => ({
    ...formation,
    'timeslots-day1': formation.timeslots.filter((timeslot: IFormationTimeSlot) => timeslot.day === 'day1').length,
    'timeslots-day2': formation.timeslots.filter((timeslot: IFormationTimeSlot) => timeslot.day === 'day2').length,
  })), [formations]);

  return (
    <div className={styles.formations}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <h1>{formations?.length} Événement{formations?.length > 1 && 's'}</h1>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
        </div>
        {formations && (
          <FormationsTable
            handleSorting={handleSorting}
            formations={formatedFormations}
          />
        )}
      </div>
    </div>
  );
};

export default ListFormations;
