import React, {
  useState, useRef, useCallback, useContext, useMemo,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useQueryClient,
} from '@tanstack/react-query';

import { convertDecimalTime, downloadSlots } from '~/utils';
import {
  useGetFormation, useGetFormationsList,
} from '~/hooks/formations';
import { useUpdateUser } from '~/hooks/users';

import { IFormationTimeSlot } from '~/types/formations';
import { IOption } from '~/types/options';
import { AccountContext } from '~/accountContext';
import { useAccount } from '~/hooks/account';
import ModalSubscribeFormation from '~/components/ModalSubscribeFormation';
import BackButton from '~/components/BackButton';
import Loader from '~/components/Loader';
import Modal from '~/lib/Modal';
import iconExport from '../../../assets/images/icon-export.svg';
import styles from './detail-formation.module.scss';
import ModalTimeslotExport from '~/components/ModalTimeslotExport';

const Formation = () => {
  type ModalSubscribeFormationHandle = React.ElementRef<typeof ModalSubscribeFormation>;
  type ModalTimeslotHandle = React.ElementRef<typeof Modal>;
  const modalRef = useRef<ModalSubscribeFormationHandle>(null);
  const modalTimeslotRef = useRef<ModalTimeslotHandle>(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useAccount();
  const queryClient = useQueryClient();
  const token = localStorage.getItem('token')?.replace('JWT ', '') || '';
  const { user: currentUser } = useContext(AccountContext);
  const account = data?.account;
  const [selectedTimeslot, setSelectedTimeslot] = useState<IFormationTimeSlot | null>(null);
  const {
    mutateAsync: updateUser,
  } = useUpdateUser();

  // Get
  const {
    data: formation,
    isLoading,
  } = useGetFormation(id || '', !!id);
  const {
    data: list = {},
  } = useGetFormationsList();

  const {
    days = [],
    regions = [],
    formationTypes = [],
    formationCategories = [],
    formationLocations = [],
  } = list;

  const currentUserFormationSlots = useMemo(() => {
    const slots = (currentUser?.formationSlots || []).filter((d: any) => d);
    return slots.map((d: any) => d._id);
  }, [currentUser?.formationSlots]);

  const openModalTimeslot = (timeslot: IFormationTimeSlot) => {
    setSelectedTimeslot(timeslot);
    modalTimeslotRef.current?.open();
  };

  const getTimeslotsPerDay = useCallback((day: string) => (
    (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === day)
  ), [formation?.timeslots]);

  const currentUserAttendToFormation = useMemo(() => {
    const day1Slots = getTimeslotsPerDay('day1');
    const day2Slots = getTimeslotsPerDay('day2');
    const formationSlotsId = [...day1Slots, ...day2Slots].map((d: IFormationTimeSlot) => d._id);
    const attendTo = currentUserFormationSlots
      .some((idSlot: string) => formationSlotsId.includes(idSlot));
    return attendTo;
  }, [
    currentUserFormationSlots,
    formation?.timeslots,
  ]);

  const handleUnsubscribeFormationSlot = async () => {
    const day1Slots = getTimeslotsPerDay('day1');
    const day2Slots = getTimeslotsPerDay('day2');
    const formationSlotsId = [...day1Slots, ...day2Slots].map((d: IFormationTimeSlot) => d._id);
    const formationSlots = currentUserFormationSlots
      .filter((idSlot: string) => !formationSlotsId.includes(idSlot));

    await updateUser({
      _id: currentUser._id,
      formationSlots,
    });
    queryClient.invalidateQueries({ queryKey: ['formation', id] });
  };

  const getDay = useCallback((value: string) => days
    .find((day: IOption) => day.value === value)?.label || '', [days]);

  const renderDay = useCallback((day: string) => (
    <>
      {getTimeslotsPerDay(day).length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay(day)}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay(day).map((d: IFormationTimeSlot) => (
              <button className={currentUserFormationSlots.includes(d._id) ? styles.selected : ''} key={`timeslots-${d._id}`}>
                {d.label}&nbsp;-&nbsp;
                {convertDecimalTime(d.value + (d.duration / 60))}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  ), [
    currentUserFormationSlots,
    formation?.timeslots,
    currentUser.formationSlots,
    days,
  ]);

  const renderSlotsForAnimator = useCallback(() => (
    <>
      {getTimeslotsPerDay('day1').length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay('day1')}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay('day1').map((d: IFormationTimeSlot) => (
              <div key={`timeslots-${d._id}`} onClick={() => openModalTimeslot(d)}>
                <p>
                  {d.label}&nbsp;-&nbsp;
                  {convertDecimalTime(d.value + (d.duration / 60))}
                </p>
                {typeof d?.seats === 'number' && typeof d?.availableSeats === 'number' && (
                  <p>
                    {d.seats - d.availableSeats} inscrits
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {getTimeslotsPerDay('day2').length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay('day2')}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay('day2').map((d: IFormationTimeSlot) => (
              <div key={`timeslots-${d._id}`} onClick={() => openModalTimeslot(d)}>
                <p>
                  {d.label}&nbsp;-&nbsp;
                  {convertDecimalTime(d.value + (d.duration / 60))}
                </p>
                {typeof d?.seats === 'number' && typeof d?.availableSeats === 'number' && (
                  <p>
                    {d.seats - d.availableSeats} inscrits
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  ), [formation?.timeslots, currentUser.formationSlots, days]);

  const getLabel = useCallback((options: IOption[], value: string | number) => {
    const label = (options || []).find((opt: IOption) => opt.value === value)?.label || '';
    return label;
  }, []);

  const currentUserIsSpeaker = useMemo(() => {
    const speakersId = formation?.speakers.map((d: any) => d._id);
    return speakersId?.includes(currentUser._id);
  }, [formation?.speakers, currentUser]);

  return (
    <>
      <div className={styles.formation}>
        <div className={styles.content}>
          {isLoading && <div className={styles.loader}><Loader /></div>}
          {(formation) && (
            <>
              <div className={styles.header}>
                <BackButton />
                {(!currentUserIsSpeaker && (account?.role === 'Admin' || account?.company?.type === 'magasin')) && (
                  <>
                    {currentUserAttendToFormation ? (
                      <button
                        className={styles.unsubscribe}
                        onClick={handleUnsubscribeFormationSlot}
                      >
                        Je me désinscris
                      </button>
                    ) : (
                      <button onClick={() => modalRef.current?.open()}>Réserver un créneau</button>
                    )}
                  </>
                )}
                {(currentUserIsSpeaker) && (
                  <button onClick={() => navigate(`/animateur/formations/edit/${id}`)}>Modifier</button>
                )}
              </div>
              <div className={styles.information}>
                <div className={styles.detail}>
                  <section>
                    <div className={styles.formationName}>
                      <h1>
                        {formation.name}
                        {formation.region && <span className={styles.region}>{` - ${getLabel(regions, formation.region)}`}</span>}
                      </h1>
                      <span>{getLabel(formationTypes, formation.type)}</span>
                      <span>{getLabel(formationCategories, formation.category)}</span>
                    </div>
                    <h3>{getLabel(formationLocations, formation?.location)}</h3>
                  </section>
                  <section>
                    <label>Animateurs</label>
                    {formation?.speakers && (
                      <div className={styles.speakers}>
                        {formation?.speakers.map((user: any) => (
                          <div key={`speaker-${user._id}`} className={styles.speaker}>
                            <div className={styles.picture}>
                              {user?.picture?.path ? (
                                <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}?token=${token}`} />
                              ) : (
                                <p>{user.profile.firstName[0]}.{user.profile.lastName[0]}</p>
                              )}
                            </div>
                            <div className={styles.detail}>
                              <p className={styles.name}>
                                {user.profile.firstName} {user.profile.lastName}
                              </p>
                              <p>
                                {user.userFunction}
                                {user.company && ` - ${user.company.name}`}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </section>
                  <section>
                    <label>Description</label>
                    {formation?.description && (
                      <p dangerouslySetInnerHTML={{ __html: formation.description }} />
                    )}
                  </section>
                  <section>
                    <div className={styles.headerTimeslots}>
                      <label>Créneaux</label>
                      {currentUserIsSpeaker && (
                        <button onClick={() => downloadSlots(formation._id, null, account?.role === 'Member' ? currentUser._id : '')}>
                          <img src={iconExport} />
                        </button>
                      )}
                    </div>
                    {!currentUserIsSpeaker ? (
                      <div className={styles.timeslots}>
                        {renderDay('day1')}
                        {renderDay('day2')}
                      </div>
                    ) : (
                      <div className={`${styles.timeslots} ${styles.animator}`}>
                        {renderSlotsForAnimator()}
                      </div>
                    )}
                  </section>
                </div>
                <div className={styles.containerImage}>
                  {formation?.picture && (
                    <div>
                      <img src={`${process.env.REACT_APP_API_URL}/files/public/${formation.picture.path}`} />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {formation && <ModalSubscribeFormation ref={modalRef} formation={formation} />}
      {(formation && currentUserIsSpeaker) && (
        <ModalTimeslotExport
          ref={modalTimeslotRef}
          formation={formation}
          timeslot={selectedTimeslot}
          handleClose={() => {
            setSelectedTimeslot(null);
          }}
        />
      )}
    </>
  );
};

export default Formation;
