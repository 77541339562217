/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ArgsGetFormations, IFormation } from '~/types/formations';

const getFormations = async (params: ArgsGetFormations) => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.formations;
};

const getFormation = async (id: string) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.formation;
};

const getFormationsList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/formations/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

const createFormation = async (data: FormData): Promise<{ formation: IFormation }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/formations`,
    data,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return response.data;
};

const updateFormation = async (data:
{ _id: string, updatedData: FormData }): Promise<{ formation: IFormation }> => {
  const token = localStorage.getItem('token');

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/formations/${data._id}`,
    data.updatedData,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

const deleteFormation = async (id: string): Promise<{ message: string }> => {
  const token = localStorage.getItem('token');
  const response = await axios.delete(
    `${process.env.REACT_APP_API_URL}/formations/${id}`,
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    },
  );
  return response.data;
};

export {
  getFormations,
  getFormation,
  getFormationsList,
  createFormation,
  updateFormation,
  deleteFormation,
};
