import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  InputText,
  ErrorField,
} from '~/lib/HooksFormFields';
import PresencesForm from '../../PresencesForm';
import { useAccountsList } from '~/hooks/account';
import styles from './collaborator-form.module.scss';

interface CollaboratorFormHandler {
  getCollaborator: () => void,
}

const CollaboratorForm = ({ type, from }
: { type: string, from?: string }, ref: Ref<CollaboratorFormHandler>) => {
  const {
    data: list,
  } = useAccountsList();
  const {
    days = [],
  } = list;

  const {
    control,
    setValue,
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();
  const errorsForm: any = errors;

  useImperativeHandle(ref, () => ({
    getCollaborator: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        reset();
      }
      return data;
    },
  }), []);

  return (
    <div className={styles.form}>
      <div className={styles.row} >
        <div className={styles.containerField}>
          <InputText
            label="Prénom du collaborateur"
            name="profile.firstName"
            control={control}
            rules={{
              required: 'Ce champs est obligatoire',
            }}
          />
          {errorsForm?.profile?.firstName && (
            <ErrorField message={errorsForm.profile.firstName.message} />
          )}
        </div>
        <div className={styles.containerField}>
          <InputText
            label="Nom  du collaborateur"
            name="profile.lastName"
            control={control}
            rules={{
              required: 'Ce champs est obligatoire',
            }}
          />
          {errorsForm?.profile?.lastName && (
            <ErrorField message={errorsForm.profile.lastName.message} />
          )}
        </div>
      </div>
      {type === 'magasin' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputText
              label="Fonction  du collaborateur"
              name='userFunction'
              maxlength={20}
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
                validate: (value: string) => {
                  if (value.length <= 20) return true;
                  return 'La fonction ne doit pas faire plus 20 caractères';
                },
              }}
            />
            {errors?.userFunction && <ErrorField message={errors?.userFunction?.message} />}
          </div>
        </div>
      )}
      <PresencesForm
        control={control}
        setValue={setValue}
        from={from || 'registerCollaborator'}
        days={days}
        errors={errors.presences || []}
      />
    </div>
  );
};

export default forwardRef(CollaboratorForm);
