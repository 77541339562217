import React, {
  CSSProperties,
  useMemo, useState, useRef, useCallback,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { BsUpcScan } from 'react-icons/bs';
import { useUpdateOrder, useGetOrdersList } from '~/hooks/orders';
import { IParsedOrder } from '~/types/orders';
import { IOption } from '~/types/options';
import ModalScan from '../ModalScan';
import ProductsTable from './ProductsTable';
import styles from './orders.module.scss';

type IOptionColor = IOption & { color: string };

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const Order = (
  {
    order, openedOrders, toggleOrder,
  } : {
    order: IParsedOrder, openedOrders: string[], toggleOrder: (orderId: string) => void,
  },
) => {
  const { data: list = {} } = useGetOrdersList();
  const isTablet = useMediaQuery('(max-width: 850px)');
  const navigate = useNavigate();
  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const {
    mutateAsync: updateOrder,
  } = useUpdateOrder();

  const {
    status = [],
  } = list;

  const productsTotalQuantity = useMemo(() => {
    let t = 0;
    order.products.forEach((product: any) => {
      t += product.quantity;
    });
    return t;
  }, [order]);

  // eslint-disable-next-line max-len
  const submit = async (companyProviderId: string, result: { userId: string, company: string | null } | null) => {
    let succeed = false;
    if (companyProviderId === result?.company) {
      const res: any = await updateOrder({
        _id: order._id,
        status: 'valid',
      });
      succeed = !!res?.order;
    }
    return succeed;
  };

  const getOptions = useCallback((options: IOptionColor[], value: string | number)
  : IOptionColor | null => {
    const option = (options || []).find((opt: IOptionColor) => opt.value === value) || null;
    return option;
  }, []);

  return (
    <>
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider la commande'
        submit={(result) => submit((order.companyProvider._id as string), result)}
        validate={(result) => order.companyProvider._id === result?.company}
      >
       {(success) => (
          <>
            {success ? (
              <div className={styles.orderValidated}>
                <h1>Commande validée !</h1>
                <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
              </div>
            ) : (
            <div className={styles.orderValidated}>
              <h1 className={styles.error}>{'La commande n\'a pas pu être validée !'}</h1>
              <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
            </div>
            )}
          </>
       )}
      </ModalScan >
      <div
        className={styles.order}
        key={`order-${order._id}`}
        onClick={() => {
          if (isTablet) navigate(`/paniers/${order._id}`);
        }}
      >
        <div className={styles.orderHeader}>
          <div className={styles.provider}>
            <p>{order.companyProvider.name}</p>
            <p>
              {'Nombre d\'offre au panier'}
              <span>{productsTotalQuantity}</span>
            </p>
          </div>
          <div className={styles.detail}>
            <p>Total panier <span>{order.totalPrice}€</span></p>
            <p className={styles.status}>
              <span className={styles.desktop}>Statut</span>
              <span style={{ '--tonic': getOptions(status, order.status)?.color || '#007d92' } as customCSS}>
                {getOptions(status, order.status)?.label}
              </span>
            </p>
          </div>
          <div className={styles.actions}>
            {order.status === 'pending' && (
              <button className={styles.validate} onClick={() => modalScanRef.current?.open()}>
                <span className='icon'>
                  <BsUpcScan size={25} />
                </span>
                <span>
                  Valider la commande
                </span>
              </button>
            )}
            <button className='invisible' onClick={() => toggleOrder(order._id)}>
              {
                openedOrders.includes(order._id)
                  ? <FaChevronUp size={22} />
                  : <FaChevronDown size={22} />
              }
            </button>
          </div>
        </div>
        {openedOrders.includes(order._id) && (
          <div className={styles.products}>
            <ProductsTable
              products={order.products}
              orderId={order._id}
              orderStatus={order.status}
            />
          </div>
        )}
      </div>
    </>
  );
};

const Orders = ({ orders } : { orders : IParsedOrder[] }) => {
  const [openedOrders, setOpenedOrders] = useState<string[]>([]);

  const toggleOrder = (_id: string) => {
    let arr = [...openedOrders];
    if (openedOrders.includes(_id)) {
      arr = arr.filter((d: string) => d !== _id);
    } else {
      arr = [...arr, _id];
    }
    setOpenedOrders(arr);
  };

  return (
    <div className={styles.orders}>
      {orders.map((order: IParsedOrder) => (
        <Order
          key={`order-${order._id}`}
          order={order}
          openedOrders={openedOrders}
          toggleOrder={toggleOrder}
        />
      ))}
    </div>
  );
};

export default Orders;
