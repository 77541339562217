import React from 'react';
import styles from './bazar.module.scss';
import Banner from '../../components/exhibitions/Banner/index';
import Card from '../../components/exhibitions/Card';
import Offers from '../../components/exhibitions/Offers/index';
import Items from '../../components/exhibitions/Items/index';
import Footer from '~/components/Footer';
import bazar from '../../assets/images/exhibitions/bazar.png';
import marqueU from '../../assets/images/exhibitions/marque-u.png';
import uLocation from '../../assets/images/exhibitions/u-location.png';

const Bazar = () => (
  <div className={styles.bazar}>
    <Banner />
    <div className={styles.content}>
      <h1>2 halls dédiés au Bazar :<br></br> découvrez les thématiques</h1>
      <div className={styles.planContainer}>
        <h2>Hall XXL</h2>
        <a
          href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_XXL_FILE_ID}`}
          target='_blank' rel='noreferrer'
        >
            Voir sur le plan
        </a>
      </div>
      <div className={styles.cardsContainer}>
        <Card
          img={bazar}
          className={styles.card}
        >
          <h3>Bazar</h3>
          <p>
            {'Les actualités du saisonnier dont une majeure partie de la commande plein air, ainsi que les actualités de l\'équipement de la maison et du BAS Culture.'}
          </p>
        </Card>
        <Card
          img={marqueU}
          className={styles.card}
        >
          <h3>La marque U</h3>
          <p>
            {'Les actualités produits, les dernières innovations et les engagements de la marque.'}
          </p>
        </Card>
      </div>
      <div className={styles.planContainer}>
        <h2>Hall Grand Palais</h2>
        <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_GP_FILE_ID}`} target='_blank' rel='noreferrer'>Voir sur le plan</a>
      </div>
      <Card
          img={uLocation}
          className={styles.aloneCard}
        >
          <h3>U Location & <br></br>Services</h3>
          <p>
            <span>
            {'Nouveaux services U Occasion et carte carburant pro U.'}
            </span>
            <br></br>
            <span>
            {'U Location : Véhicules / matériels festifs / vélos et nouvel outil de gestion de la location.'}
            </span>
          </p>
      </Card>
    </div>
    <Offers />
    <Items />
    <Footer />
  </div>
);

export default Bazar;
