import React, {
  useRef, useEffect, useContext, useCallback,
} from 'react';
import { NavLink } from 'react-router-dom';
import { TbIdBadge2 } from 'react-icons/tb';
import { LuLogOut } from 'react-icons/lu';
import { FieldValues, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useUpdateUser } from '~/hooks/users';
import { AccountContext } from '~/accountContext';
import { downloadBadge, convertDecimalTime } from '~/utils';
import {
  useSignOut,
  useAccount,
  useAccountsList,
  useAddUserToAccount,
} from '~/hooks/account';
import {
  useGetFormationsList,
} from '~/hooks/formations';
import {
  InputText,
  StyledSelect,
  ErrorField,
} from '~/lib/HooksFormFields';
import useFetch from '~/hooks/useFetch';
import Modal from '~/lib/Modal';
import PresencesForm from '~/components/form/RegisterForm/PresencesForm';
import Loader from '~/components/Loader';
import Icon from '~/components/Icon';
// import QRCOde from '~/components/QRCode';
import styles from './account.module.scss';
import { IOption } from '~/types/options';
import { IUser } from '~/types/users';
import CollaboratorForm from '~/components/form/RegisterForm/CollaboratorsForm/CollaboratorForm';

const UserFormations = () => {
  const { data: list } = useGetFormationsList();
  const {
    formationTypes = [],
  } = list || {};
  const { user: currentUser } = useContext(AccountContext);
  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_API_URL}/users/${currentUser._id}`,
    true,
  );
  const user = data?.user || {};
  const { formationSlots: formations = [] } = user;

  const getFormationType = useCallback((value: string) => formationTypes
    .find((formationType: IOption) => formationType.value === value)?.label || '', [formationTypes]);

  return (
    <div className={styles.formations}>
      {formations?.length === 0 && (
        <p>{'Vous n\'êtes inscrit à aucune formation.'}</p>
      )}
      {isLoading ? (
        <Loader small/>
      ) : formations.map((formation: any, index: number) => (
        <div className={styles.formation} key={`formation-${formation._id}-${index}`}>
          <NavLink to={`/formations/${formation.formationId}`}>
            <div className={styles.formationContent}>
              <div>
                <div>
                  <p className={styles.type}>{getFormationType(formation.type)}</p>
                  <p className={styles.name}>{formation.name}</p>
                  <p className={styles.day}>
                    {formation.label}&nbsp;-&nbsp;
                    {convertDecimalTime(formation.value + (formation.duration / 60))}
                  </p>
                </div>
                <div className={styles.speakers}>
                  <p >
                    {formation.speakers.map((speaker: { _id: string, profile: any }) => (
                      `${speaker.profile.firstName} ${speaker.profile.lastName}`
                    )).join(', ')}
                  </p>
                </div>
              </div>
              {formation.picture && (
                <img src={`${process.env.REACT_APP_API_URL}/files/public/${formation.picture.path}`} />
              )}
            </div>
          </NavLink>
        </div>
      ))}
    </div>
  );
};

const Account = ({ user } : { user: any }) => {
  const modalRef = useRef<any>();
  type CollaboratorFormHandler = React.ElementRef<typeof CollaboratorForm>;
  const collaboratorFormRef = useRef<CollaboratorFormHandler>(null);
  const { data } = useAccount();
  const account = data?.account;
  const {
    data: list,
  } = useAccountsList();
  const {
    isLoading: isUpdateUserLoading,
    mutate: updateUser,
  } = useUpdateUser('Les modifications ont été prise en compte');
  const {
    mutateAsync: addUserToAccount,
    isLoading: addUserToAccountLoading,
  } = useAddUserToAccount();

  const {
    days = [],
    activities = [],
  } = list;
  const type = account?.company?.type;
  const signOut = useSignOut();
  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      profile: {
        firstName: user?.profile?.firstName || '',
        lastName: user?.profile?.lastName || '',
      },
      userFunction: user?.userFunction || '',
      activity: user?.activity || '',
      presences: user?.presences || [],
    },
  });

  // function submit for update user
  const submit = useCallback(async (userData: FieldValues) => {
    updateUser({
      _id: user._id,
      ...userData,
    });
  }, [user]);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => watch(() => {
    handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  const submitCollaborator = async () => {
    const newCollaborator = await collaboratorFormRef.current?.getCollaborator();
    if (!account?._id || !newCollaborator) return;
    const res = await addUserToAccount({
      accountId: account?._id,
      userData: newCollaborator,
    });
    if (res?.account) modalRef.current.close();
  };

  const renderAccountUsers = () => {
    const users = account?.users.filter((d: Partial<IUser>) => d._id !== user._id);
    return (
      <ul className={styles.users}>
        {users?.map((d) => (
          <li key={`user-${d._id}`}>
            <div className={styles.user} onClick={() => downloadBadge(d._id)}>
              <button className='invisible'>
                <TbIdBadge2 size={22} />
              </button>
              <p>{d.profile.firstName} {d.profile.lastName}</p>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.account}>
      <Modal ref={modalRef} width={700}>
        <div className={styles.modalCollaborator}>
          <h1>Ajouter un collaborateur</h1>
          <CollaboratorForm
            ref={collaboratorFormRef}
            from='account'
            type={type || ''}
          />
          <button
            onClick={submitCollaborator}
          >
            {'Valider l\'ajout du collaborateur'}
          </button>
        </div>
      </Modal>
      {(account && user && days.length > 0) && (
        <div>
          <div className={styles.header}>
            {account?.company && (
              <span className={styles.name}>
                <h1>{account.company.name}</h1>
                {account.company.code && <p>{account.company.code}</p>}
              </span>
            )}
            {isUpdateUserLoading && <div className={styles.loader} ><Loader small/></div>}
            <button onClick={() => downloadBadge(user._id)} disabled={addUserToAccountLoading}>
              <span>Télécharger mon badge</span>
              <span><Icon icon='badge' /></span>
            </button>
          </div>
          <div className={styles.content}>
            {/* <QRCOde value={account?._id || ''} /> */}
            <div className={styles.form}>
              <div className={styles.headerBlock}>
                <h2>Mes informations</h2>
              </div>
              <section>
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputText
                      label="Prénom"
                      name='profile.firstName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                  </div>
                  <div className={styles.containerField}>
                    <InputText
                      label="Nom"
                      name='profile.lastName'
                      control={control}
                      rules={{
                        required: 'Ce champs est obligatoire',
                      }}
                    />
                  </div>
                </div>
                {(type === 'magasin' || (user.isAnimator) || !type) && (
                <div className={styles.row}>
                  <div className={styles.containerField}>
                    <InputText
                      label="Fonction"
                      name='userFunction'
                      control={control}
                      maxlength={20}
                      rules={{
                        required: 'Ce champs est obligatoire',
                        validate: (value: string) => {
                          if (value.length <= 20) return true;
                          return 'La fonction ne doit pas faire plus 20 caractères';
                        },
                      }}
                    />
                    {errors?.userFunction && <ErrorField message={errors?.userFunction?.message} />}
                  </div>
                  {!type && (
                    <div className={styles.containerField}>
                      <StyledSelect
                        label="Activité"
                        name='activity'
                        control={control}
                        options={activities}
                        rules={{
                          required: 'Ce champs est obligatoire',
                        }}
                      />
                      {errors?.activity && <ErrorField message={errors?.activity?.message} />}
                    </div>
                  )}
                </div>
                )}
              </section>
              <section>
                <PresencesForm
                  control={control}
                  from='account'
                  setValue={setValue}
                  days={days}
                  errors={errors.presences || []}
                />
              </section>
              <section className={styles.onlyOnDesktop}>
                <button className={`invisible ${styles.logout}`} onClick={signOut}>
                  <span>Me déconnecter</span>
                  <span><LuLogOut size={25} /></span>
                </button>
              </section>
            </div>
            <aside>
              {(type === 'magasin' || account?.role === 'Admin') && (
                <div>
                  <div className={styles.headerBlock}>
                    <h2>Mes formations</h2>
                  </div>
                  <UserFormations />
                </div>
              )}
              <div className={styles.badges}>
                <div className={styles.headerBlock}>
                  <h2>Les badges de mon équipe</h2>
                </div>
                <div>
                  <button className={styles.addUser} onClick={() => modalRef.current.open()}>
                    {addUserToAccountLoading && (
                      <span className='icon'>
                        <div className={styles.loader} ><Loader small isWhite /></div>
                      </span>
                    )}
                    {!addUserToAccountLoading && (
                      <span><Icon icon='badge' /></span>
                    )}
                    <span>
                      Ajouter un collaborateur
                    </span>
                  </button>
                  {renderAccountUsers()}
                </div>
              </div>
            </aside>
            <section className={styles.onlyOnTablet}>
              <button className={`invisible ${styles.logout}`} onClick={signOut}>
                <span>Me déconnecter</span>
                <span><LuLogOut size={25} /></span>
              </button>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

const UserAccount = () => {
  const { user } = useContext(AccountContext);
  return (
    <Account user={user} key={user._id} />
  );
};

export default UserAccount;
