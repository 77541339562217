/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IAccount, ArgsGetAccountList } from '~/types/accounts';
import { IUser } from '~/types/users';

const signIn = async (email: string, password: string): Promise<IAccount> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/login`, {
    email,
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

const register = async (data: Partial<IAccount>): Promise<IAccount> => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

const forgotPassword = async (email: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/forgot-password`, {
    email,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const resetPassword = async (password: string, token: string): Promise<unknown> => {
  const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/reset-password/${token}`, {
    password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return data;
};

const getAccount = async (): Promise<{ account: IAccount, token: string } | null> => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/accounts`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const getAccountsList = async (params?: ArgsGetAccountList) => {
  const { type = null, exhibition = null } = params || {};
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/accounts/list`,
    { params: { type, exhibition } },
  );
  return data;
};

// eslint-disable-next-line max-len
const addUserToAccount = async (userDataAccount: { accountId: string, userData: Partial<IUser> }): Promise<{ account: IAccount }> => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/accounts/add-user/${userDataAccount.accountId}`,
    userDataAccount.userData,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return data;
};

const updateAnimatorAccount = async (data: {
  region: string,
  userFunction: string,
  presences: Pick<IUser, 'presences'>
}): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/accounts/animator`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  signIn,
  register,
  forgotPassword,
  resetPassword,
  getAccount,
  getAccountsList,
  addUserToAccount,
  updateAnimatorAccount,
};
