import React, {
  CSSProperties,
  useRef, useCallback,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MdAddShoppingCart } from 'react-icons/md';
import { BsUpcScan } from 'react-icons/bs';
import {
  useGetOrder, useUpdateOrder, useGetOrdersList, useDeleteOrderProduct,
} from '~/hooks/orders';
import { IOption } from '~/types/options';
import Loader from '~/components/Loader';
import styles from './detail.module.scss';
import BackButton from '~/components/BackButton';
import ModalScan from '~/components/ModalScan';
import withModalConfirm from '~/lib/withModalConfirm';

type IOptionColor = IOption & { color: string };

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const DetailOrder = ({ confirm } : { confirm: any }) => {
  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    mutateAsync: updateOrder,
  } = useUpdateOrder();
  const { data: list = {} } = useGetOrdersList();

  const {
    mutate: deleteOrderProduct,
    isLoading: isDeleteOrderProductLoading,
    isSuccess: isDeleteOrderProductSuccess,
  } = useDeleteOrderProduct();

  const { data: order, isLoading } = useGetOrder(id || '', !!id && !isDeleteOrderProductSuccess);

  const {
    status = [],
  } = list;

  const submit = async (result: { userId: string, company: string | null } | null) => {
    let succeed = false;
    if (order.companyProvider._id === result?.company) {
      const res: any = await updateOrder({
        _id: order._id,
        status: 'valid',
      });
      succeed = !!res?.order;
    }
    return succeed;
  };

  const handleDelete = (productId: string) => {
    if (!id) return;
    confirm(
      'Êtes-vour certain de vouloir supprimer ce produit de la commande ?',
      () => {
        deleteOrderProduct({
          orderId: id,
          productId,
        });
      },
    );
  };

  const getOptions = useCallback((options: IOptionColor[], value: string | number)
  : IOptionColor | null => {
    const option = (options || []).find((opt: IOptionColor) => opt.value === value) || null;
    return option;
  }, []);

  return (
    <>
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider la commande'
        submit={submit}
        validate={(result) => {
          console.log(result, order.companyProvider._id);
          return order.companyProvider._id === result?.company;
        }}
      >
        {(success) => (
          <>
            {success ? (
              <div className={styles.orderValidated}>
                <h1>Commande validée !</h1>
                <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
              </div>
            ) : (
            <div className={styles.orderValidated}>
              <h1 className={styles.error}>{'La commande n\'a pas pu être validée !'}</h1>
              <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
            </div>
            )}
          </>
        )}
      </ModalScan >
      <div className={styles.detailProduct}>
        <div className={styles.content}>
          {isLoading && (
            <Loader />
          )}
          {order && (
            <>
              <>{console.log(order)}</>
              <div className={styles.nav}>
                <BackButton />
                <div className={styles.status} >
                  <p>
                    Statut
                    <span style={{ '--tonic': getOptions(status, order.status)?.color || '#007d92' } as customCSS}>
                      {getOptions(status, order.status)?.label}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.header}>
                <h1>{(order.companyProvider as any).name}</h1>
                <div className={styles.detail}>
                  <div className={styles.shoppingCart}>
                    <MdAddShoppingCart size={30} />
                    <h2>Panier</h2>
                  </div>
                  <span className={styles.total}>
                    <p>Total Panier&nbsp;:&nbsp;<span>{order.totalPrice}€</span></p>
                  </span>
                </div>
              </div>
              <div>
              <ul className={styles.list}>
                {order.products.map((d: any) => (
                  <li key={`product-${d.product._id}`}>
                    <div className={styles.product}>
                      <div>
                        <p className={styles.name}>{d.product.name}</p>
                        <p>{(order.companyProvider as any).name}</p>
                        <p className={styles.quantity}>
                          Quantité&nbsp;:&nbsp;<span>{d.quantity}</span>
                        </p>
                      </div>
                      <div>
                        <p>{d.totalPrice}€</p>
                      </div>
                      {order?.status === 'pending' && (
                        <div className={styles.actions}>
                          <button onClick={() => navigate(`/produits/${d.product._id}`)} className={'invisible'}>Modifier</button>
                          <button
                            disabled={isDeleteOrderProductLoading}
                            className={`invisible ${styles.delete}`}
                            onClick={() => handleDelete(d.product._id)}
                          >
                            Supprimer
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              </div>
            </>
          )}
        </div>
        {order?.status === 'pending' && (
          <button onClick={() => modalScanRef?.current?.open()}>
            <span className={styles.icon}>
              <BsUpcScan size={30} />
            </span>
            <span>Valider la commande</span>
          </button>
        )}
      </div>
    </>
  );
};

export default withModalConfirm(DetailOrder);
