import axios from 'axios';
import { ArgsGetOrders } from '~/types/orders';

/* eslint-disable max-len */
const convertDecimalTime = (decimalTime: number) => {
  const decimal = decimalTime - Math.floor(decimalTime);
  const minutes = Math.round(decimal * 60);
  const time = `${Math.floor(decimalTime)}:${minutes < 10 ? `0${minutes}` : minutes}`;
  return time;
};

// eslint-disable-next-line max-len
const downloadSlots = async (formationId: string, timeslotId?: string | null, exportBy?: string) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  const headers = new Headers({
    Authorization: token,
  });
  let url = `${process.env.REACT_APP_API_URL}/formations/export-timeslots/${formationId}`;

  const searchParams = new URLSearchParams();
  if (timeslotId) searchParams.set('timeslot', timeslotId);
  if (exportBy) searchParams.set('exportBy', exportBy);
  const searchParamsString = searchParams.toString();

  if (searchParamsString) url += `?${searchParamsString}`;

  const response = await fetch(url, {
    method: 'GET',
    headers,
  });

  if (response.status !== 200) {
    console.log(response);
    return;
  }

  const contentDisposition = response.headers.get('Content-Disposition');
  let fileName = 'formation.xlsx';
  if (contentDisposition) {
    fileName = (contentDisposition as any).split(';').find((n: any) => n.includes('filename='))
      .replace('filename=', '')
      .trim();
  }

  const blob = await response.blob();
  const urlBlob = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = urlBlob;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const downloadBadge = async (userId: string) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'application/pdf',
    },
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/badges/download/${userId}`, config);
    const blob = await response.blob();
    const contentDisposition: any = response.headers.get('Content-Disposition');
    let fileName = 'badge.pdf';
    if (contentDisposition) {
      fileName = contentDisposition.split(';')
        .find((n: string[]) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const exportOrders = async (params: Partial<ArgsGetOrders>) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  try {
    const { headers, data: blob }: any = await axios.get(
      `${process.env.REACT_APP_API_URL}/orders/export`,
      {
        headers: {
          Authorization: token,
        },
        params,
        responseType: 'blob',
      },
    );
    const contentDisposition: any = headers.get('Content-Disposition');
    let fileName = 'commandes-produits.xlsx';
    if (contentDisposition) {
      fileName = contentDisposition.split(';')
        .find((n: string[]) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
    }

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.log(e);
  }
};

const executePromisesSequentially = (promises: (() => void)[]) => (
  promises.reduce((previousPromise: Promise<any>, currentPromise: any) => previousPromise.then(() => currentPromise()), Promise.resolve())
);

export {
  convertDecimalTime,
  downloadSlots,
  executePromisesSequentially,
  exportOrders,
};
