/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { IUser } from '~/types/users';
import {
  updateUser, validateUserFormationPresence,
} from '../../api/users';

const useUpdateUser = (successMessage?: string) => {
  const notify = () => toast(successMessage || '');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<IUser>) => updateUser(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
      if (successMessage) notify();
    },
  });
};

const useValidateUserFormationPresence = () => {
  const queryClient = useQueryClient();
  return useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (data: { _id: string, timeslot: string, formation: string }) => validateUserFormationPresence(data),
    onSuccess: async (_: any, args: any) => {
      queryClient.invalidateQueries({ queryKey: ['formation', args.formation] });
    },
  });
};

export {
  useUpdateUser,
  useValidateUserFormationPresence,
};
