import React, { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IoFilter } from 'react-icons/io5';
import { useDebounceValue, useMediaQuery } from 'usehooks-ts';
import { useGetOrders } from '~/hooks/orders';
import { exportOrders } from '~/utils';
import Footer from '~/components/Footer';
import styles from './shopping-cart.module.scss';
import Orders from '~/components/Orders';
import FilterSearchParam from '~/components/FilterSearchParam';
import InputSearch from '~/components/InputSearch';
import ButtonExport from '~/components/ButtonExport';
import { IParsedOrder } from '~/types/orders';
import { useGetProductsList } from '~/hooks/products';

const ShoppingCart = () => {
  const isTablet = useMediaQuery('(max-width: 590px)');
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: dataOrders = {} } = useGetOrders({
    ...paramsObject,
    search: debouncedSearch,
  });

  const { orders = [] } = dataOrders;

  const { data: list = {} } = useGetProductsList();

  const {
    types = [],
    categories = [],
  } = list;
  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const total = useMemo(() => {
    const t = orders.reduce((acc: number, order: IParsedOrder) => acc + order.totalPrice, 0);
    return t.toFixed(2);
  }, [orders]);

  const providersCount = useMemo(() => {
    const companyProviders: Record<string, any> = {};
    orders.forEach((order: IParsedOrder) => {
      const companyProviderId: string = order?.companyProvider?._id || '';
      if (companyProviderId) companyProviders[companyProviderId] = order.companyProvider;
    });
    return Object.values(companyProviders).length;
  }, [orders]);

  const productsTotalDiscount = useMemo(() => {
    let t = 0;
    orders.forEach((order: IParsedOrder) => {
      order.products.forEach((d: any) => {
        t += ((d.product.discount || 0)) * d.quantity;
      });
    });
    return t.toFixed(2);
  }, [orders]);

  return (
    <div className={styles.shoppingCart}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.titleAndActions}>
            <h1>Mon panier</h1>
            <div className={styles.actions}>
              <ButtonExport type='export' onClick={() => exportOrders({
                ...paramsObject,
                search: debouncedSearch,
              })} />
            </div>
          </div>
          <div className={styles.detail}>
            {!isTablet && (
              <span>
                <p>{providersCount}&nbsp;fournisseur{providersCount > 1 ? 's' : ''}</p>
              </span>
            )}
            <span>
              <p>Total Panier&nbsp;:&nbsp;<span>{total}€</span></p>
            </span>
            <span className={styles.save}>
              <p>Total économisé&nbsp;:&nbsp;<span>{productsTotalDiscount}€</span></p>
            </span>
            <div className={styles.actions}>
              <ButtonExport type='export' onClick={() => exportOrders({
                ...paramsObject,
                search: debouncedSearch,
              })} />
            </div>
          </div>
        </div>
        <div className={styles.filters}>
          {(!isTablet || isFiltersOpened) && (
            <div className={styles.selectFilters} >
              <div className={styles.field}>
                <FilterSearchParam
                  label='Rayon'
                  name='typeProduct'
                  options={types}
                />
              </div>
              <div className={styles.field}>
                <FilterSearchParam
                  label='Catégories'
                  name='categoryProduct'
                  options={categories}
                  feminine
                />
              </div>
            </div>
          )}
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
          {isTablet && (
            <div className={styles.containerButtonFilter}>
                <button className='invisible' onClick={() => setIsFiltersOpened((state) => !state)}>
                  <span>Filtrer</span>
                  <span className={styles.icon}><IoFilter size={26} /></span>
                </button>
            </div>
          )}
        </div>
        <Orders orders={orders} />
      </div>
      <Footer />
    </div>
  );
};

export default ShoppingCart;
