/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICompany } from '~/types/companies';
import styles from './anm.module.scss';
import { useAccount } from '~/hooks/account';
import Banner from '../../components/exhibitions/Banner/index';
import Card from '../../components/exhibitions/Card/index';
import Items from '~/components/exhibitions/Items';
import Footer from '~/components/Footer';
import conformiteReglementaire from '../../assets/images/exhibitions/conformite-reglementaire.png';
import maitriseDesCouts from '../../assets/images/exhibitions/maitrise-des-couts.png';
import exploitationPointVente from '../../assets/images/exhibitions/exploitation-du-point-de-vente.png';
import innovationEnAction from '../../assets/images/exhibitions/inovation-en-action.png';
import loupe from '../../assets/images/exhibitions/loupe.png';

const Anm = () => {
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } = account || {};
  const companyType = companyAccount ? (companyAccount as ICompany)?.type : null;

  return (
    <div className={styles.anm}>
      <Banner />
      <div className={styles.content}>
        <div className={styles.introduction}>
          <div className={styles.firstContainer}>
            <div className={styles.text}>
              <h1>Le salon des ANM 2024</h1>
              <p>
                <span>
                  Les Achats Non Marchands auront le plaisir
                  de vous accueillir dans le Grand Palais.{' '}
                </span>
                <span>
                  Tout en abordant les sujets d'actualité par le biais <em>de conférences et de
                  formations, une sélection de fournisseurs sera présente</em> pour échanger et pour
                  répondre à vos interrogations autour de 4 thématiques : conformité réglementaire,
                  exploitation du point de vente, maîtrise des coûts et innovation !
                </span>
                <br />
                <span>
                  <em>Ce sera également l'occasion pour vous de rencontrer les équipes ANM !</em>
                </span>
              </p>
            </div>
            <div className={styles.plan}>
              {/* <p>Plan du Grand Palais</p> */}
              <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_GP_FILE_ID}`} target='_blank' rel='noreferrer'><img src={loupe} alt="loupe pour ouvrir la carte du Grand Palais sur une autre page"/></a>
            </div>
          </div>
          <div className={styles.containerButtons}>
            {((companyType !== 'magasin') && (companyType !== 'fournisseur'))
            && <NavLink to='/formations?type=anm'>
                <button>Découvrez le planning <br></br>des conférences et formations ANM</button>
              </NavLink>
            }
            <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_LIST_FOURNISSEURS}`} target='_blank' rel='noreferrer'>
              <button>Voir la liste des fournisseurs</button>
            </a>
          </div>
        </div>
        <div className={styles.presentation}>
          <h1>1 hall dédié aux ANM :<br></br> découvrez les thématiques</h1>
            <h2>Le grand Palais</h2>
            <a href={`${process.env.REACT_APP_API_URL}/files/download/${process.env.REACT_APP_PLAN_GP_FILE_ID}`} download>Téléchargez le plan en pdf</a>
          <div className={styles.cards}>
            <Card img={conformiteReglementaire} className={styles.topCard}>
              <h3>
                Enjeux réglementaires et énergétiques
              </h3>
              <p>Obtenez des conseils sur la réglementation et son application.</p>
              <ul>
                <li>GTB</li>
                <li>Photovoltaïque</li>
                <li>Bornes IRVE</li>
                <li>Démarche zéro gaspi</li>
                <li>Emballages</li>
                <li>CEE</li>
              </ul>
            </Card>
            <Card img={maitriseDesCouts} className={styles.topCard}>
              <h3>Maîtrise des coûts</h3>
              <p>
                Optimisez vos frais généraux.
              </p>
              <ul>
                <li>Anti-gaspi & collecte des déchets</li>
                <li>Audit énergétique</li>
                <li>Bornes IRVE</li>
                <li>Photovoltaïque</li>
                <li>Station</li>
                <li>Gestion du temps</li>
                <li>Froid commercial</li>
                <li>Intérim</li>
                <li>Animation locale</li>
                <li>Agencement (extérieur, intérieur)</li>
              </ul>
            </Card>
            <Card img={exploitationPointVente} className={styles.bottomCard}>
              <h3>Exploitation du <br></br>point de vente</h3>
              <p>
                Découvrez des solutions pratiques pour améliorer l'efficacité de votre magasin.
              </p>
              <ul>
                <li>Logistique</li>
                <li>Emballages</li>
                <li>Métiers</li>
                <li>Reconditionné</li>
                <li>Sacherie</li>
                <li>Signalétique</li>
                <li>Froid commercial</li>
                <li>Drive</li>
              </ul>
            </Card>
            <Card img={innovationEnAction} className={styles.bottomCard}>
              <h3>Innovation <br></br>en action</h3>
              <p>
                Explorez le futur du commerce avec les dernières technologies et tendances.
              </p>
              <ul>
                <li>Intelligence artificielle</li>
                <li>Mobilité douce</li>
                <li>Sécurité monétaire</li>
                <li>Enseigne & signalétique</li>
                <li>Emballages</li>
                <li>RSE</li>
                <li>Métiers</li>
                <li>Nettoyage</li>
                <li>Innovations diverses</li>
              </ul>
            </Card>
          </div>
        </div>
      </div>
      <Items switchedItem/>
      <Footer />
    </div>
  );
};

export default Anm;
