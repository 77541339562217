import React, { Attributes, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

interface ConfirmProps {
  message?: string | null,
  action?:(() => void) | null,
  cancel?: (() => void) | null,
}

const Modal = ({ children } : { children: React.ReactNode }) => {
  const modalRoot = document.getElementById('modal');
  const [pendingFunction, setPendingFunction] = useState<ConfirmProps>({
    action: null,
    cancel: null,
    message: null,
  });

  function childrenWithProps() {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          confirm: (
            message: string | null,
            action?: (() => void) | null,
            cancel?: (() => void) | null,
          ) => {
            setPendingFunction({ message, action, cancel });
          },
        } as Attributes);
      }
      return child;
    });
  }

  function confirmAction(bool: Boolean) {
    if (bool && pendingFunction.action) pendingFunction.action();
    else if (!bool && pendingFunction.cancel) pendingFunction.cancel();
    setPendingFunction({
      action: null,
      message: null,
    });
  }

  return (
    <>
      {(modalRoot && pendingFunction.action && pendingFunction.message) && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} />
              <div className={styles.modal}>
                <p>{pendingFunction.message}</p>
                <div className={styles['container-btn']}>
                  <button className="secondary" onClick={() => confirmAction(false)}>
                    <span>Annuler</span>
                  </button>
                  <button onClick={() => confirmAction(true)}>
                    <span>Confirmer</span>
                  </button>
                </div>
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
      {childrenWithProps()}
    </>
  );
};

const withModalConfirm = (Component: React.ComponentType<any>) => function wrap({ ...props }) {
  return (
    <Modal>
      <Component {...props} />
    </Modal>
  );
};

export default withModalConfirm;
