import React, {
  useRef, useContext, useEffect, useState,
  useMemo,
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  IoChevronDown, IoChevronUp, IoClose,
} from 'react-icons/io5';
import { useMediaQuery } from 'usehooks-ts';
import { LuLogOut } from 'react-icons/lu';
import {
  MdAddShoppingCart,
  MdOutlineNotifications,
} from 'react-icons/md';
import { useAccount, useSignOut } from '../../hooks/account';

import { useGetOrders } from '~/hooks/orders';
import Icon from '~/components/Icon';
import logoUInline from '../../assets/images/logo-u-inline2.svg';
import { AccountContext } from '~/accountContext';
import styles from './navbar.module.scss';
import { IUser } from '~/types/users';
import { ICompany } from '~/types/companies';
import { IParsedOrder } from '~/types/orders';

interface Props {
  setIsNotificationsOpen: (current: any) => void;
  isVisibleCircle: boolean
}

const Navbar = ({ setIsNotificationsOpen, isVisibleCircle }: Props) => {
  const isTablet = useMediaQuery('(max-width: 1120px)');
  const navigate = useNavigate();
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } = account || {};
  const companyType = companyAccount ? (companyAccount as ICompany).type : null;
  const { data: dataOrders = {} } = useGetOrders({}, companyType === 'magasin');

  const { orders = [] } = dataOrders;

  const productsTotal = useMemo(() => {
    let t = 0;
    orders.forEach((order: IParsedOrder) => {
      order.products.forEach((product: any) => {
        t += product.quantity;
      });
    });
    return t;
  }, [orders]);

  const {
    setUsers, setUser, users, user,
  } = useContext(AccountContext);
  const timer = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [disabledTime, setDisabledTimer] = useState(false);
  const signOut = useSignOut();

  useEffect(() => {
    setUsers(account?.users || []);

    let currentUser = account?.users?.[0] || null;
    if (user) {
      currentUser = account?.users.find((d: Partial<IUser>) => d._id === user._id) || null;
    }

    setUser(currentUser);
  }, [account?.users]);

  useEffect(() => {
    if (!open || disabledTime || isTablet) return () => {};
    timer.current = setTimeout(() => {
      setOpen(false);
    }, 1200);
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [open, disabledTime, isTablet]);

  const handleSelectUser = (selectedUser: IUser) => {
    setUser(selectedUser);
    setOpen(false);
  };

  const handleToggleNotificationsState = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsNotificationsOpen((current: any) => !current);
  };

  return (
    <div className={isMenuOpen ? `${styles.navbar} ${styles.open}` : styles.navbar}>
      <div>
        <div className={styles.logo} onClick={() => {
          navigate('/salon');
          setIsMenuOpen(false);
        }}>
          <img src={logoUInline} alt="U enseigne" />
        </div>
        <div className={styles.actions}>
          <button className={`invisible ${styles.burger}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {!isMenuOpen ? (
              <Icon icon='burger' />
            ) : (
              <IoClose size={40} />
            )}
          </button>
          {(!process.env.REACT_APP_LOCK_PRODUCTS && companyType === 'magasin') && (
            <div className={`${styles.shoppingCart} ${styles.desktop}`}>
              <NavLink onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) => (isActive ? styles.active : '')} to='paniers' >
                <MdAddShoppingCart size={30} />
                {productsTotal > 0 && <span className={styles.productsTotal}>{productsTotal}</span>}
              </NavLink>
            </div>
          )}
          <div
            className={isVisibleCircle ? `${styles.notifications} ${styles.active}` : styles.notifications}
            onClick={handleToggleNotificationsState}
          >
            <MdOutlineNotifications size={30} />
          </div>
          <div className={styles.profile}>
            <div className={styles.currentProfile} onClick={() => {
              navigate('account');
              setIsMenuOpen(false);
            }}>
              <div className={styles.picture}>
                {user?.profile && (
                  <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                )}
              </div>
            </div>
            {(open && !isTablet) && (
              <div className={styles.dropdown}
                onMouseEnter={() => setDisabledTimer(true)}
                onMouseLeave={() => setDisabledTimer(false)}
              >
                <ul>
                  {users.map((d: IUser) => (
                    <li key={`user-${d._id}`}>
                      <div className={styles.user} onClick={() => handleSelectUser(d)}>
                        <div className={styles.picture}>
                          {d?.profile && (
                            <p>{d.profile.firstName[0]}{d.profile.lastName[0]}</p>
                          )}
                        </div>
                        <p>{d.profile.firstName} {d.profile.lastName}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <button className='invisible' onClick={() => setOpen(true)}>
            {open ? <IoChevronUp size={40} /> : <IoChevronDown size={40} />}
          </button>
        </div>
      </div>
      <nav>
        <ul>
          {isTablet && (
            <li className={styles.dropdownUsersTablet}>
              <div className={styles.currentProfile}>
                {user?.profile && (
                  <div
                    onClick={() => {
                      navigate('account');
                      setIsMenuOpen(false);
                    }}
                  >
                    <div className={styles.picture}>
                      <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                    </div>
                    <p>{user.profile.firstName} {user.profile.lastName}</p>
                  </div>
                )}
                <button className='invisible' onClick={() => setOpen((state) => !state)}>
                  {open ? <IoChevronUp size={40} /> : <IoChevronDown size={40} />}
                </button>
              </div>
              {open && (
                <ul>
                  {users.map((d: IUser) => (
                    <li key={`user-${d._id}`}>
                      <div className={styles.user} onClick={() => handleSelectUser(d)}>
                        <div className={styles.picture}>
                          {d?.profile && (
                            <p>{d.profile.firstName[0]}{d.profile.lastName[0]}</p>
                          )}
                        </div>
                        <p>{d.profile.firstName} {d.profile.lastName}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )}
          <li>
            <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='salons' >Mes salons</NavLink>
          </li>
          {user?.isAnimator && (
             <li>
              <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='animateur/formations' >Mes événements</NavLink>
            </li>
          )}
          {(!process.env.REACT_APP_LOCK_PRODUCTS && companyType === 'fournisseur') && (
            <>
              <li>
                <NavLink
                  onClick={() => setIsMenuOpen(false)}
                  className={({ isActive }) => (isActive ? styles.active : '')} to='produits'
                >
                  Mes offres
                </NavLink>
              </li>
              <li>
                <NavLink
                  onClick={() => setIsMenuOpen(false)}
                  className={({ isActive }) => (isActive ? styles.active : '')} to='paniers'
                >
                  Paniers magasins
                </NavLink>
              </li>
            </>
          )}
          {companyType === 'magasin' && (
            <>
              <li>
                <NavLink
                  onClick={() => setIsMenuOpen(false)}
                  className={({ isActive }) => (isActive ? `${styles.active} ${styles.products}` : styles.products)}
                  to='produits'
                >
                  Les offres spéciales
                  </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) => (isActive ? styles.active : '')}
                to='fournisseurs' >Les fournisseurs</NavLink>
              </li>
            </>
          )}
          {(companyType !== 'fournisseur' && !process.env.REACT_APP_LOCK_CONFERENCES) && (
            <li>
              <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) => (isActive ? styles.active : '')}
              to='formations'
              >
                Les formations & conférences
              </NavLink>
            </li>
          )}
          {(companyType !== 'fournisseur' && process.env.REACT_APP_LOCK_CONFERENCES) && (
            <li>
              <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) => (isActive ? styles.active : '')}
              to='formations'
              >
                Les formations
              </NavLink>
            </li>
          )}
          {(companyType === 'fournisseur' && !process.env.REACT_APP_LOCK_CONFERENCES) && (
            <li>
              <NavLink
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) => (isActive ? styles.active : '')} to='conferences'
              >
                Les conférences
              </NavLink>
            </li>
          )}
          <li className={styles.mobile}>
            <NavLink onClick={() => setIsMenuOpen(false)} className={({ isActive }) => (isActive ? styles.active : '')} to='account' >Mon compte</NavLink>
          </li>
          <li className={styles.mobile}>
            <a data-close-notification='false' className={styles.linkNotifications} onClick={() => setIsNotificationsOpen((state: boolean) => !state)}>
              <div
                className={isVisibleCircle ? `${styles.notifications} ${styles.active}` : styles.notifications}
                onClick={handleToggleNotificationsState}
              >
                <MdOutlineNotifications size={28} />
              </div>
              <span>Mes notifications</span>
            </a>
          </li>
          {(!process.env.REACT_APP_LOCK_PRODUCTS && companyType === 'magasin') && (
            <li className={styles.mobile}>
              <NavLink onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) => (isActive ? styles.active : '')} to='paniers' >
                Mes paniers&nbsp;&nbsp;
                {productsTotal > 0 && (
                  <span className={styles.productsTotal}>{productsTotal}</span>
                )}
              </NavLink>
            </li>
          )}
          <li className={`${styles.mobile} ${styles.logout}`}>
            <a onClick={() => signOut()}>
              <LuLogOut size={20} />
              Se déconnecter
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
