import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import {
  EditorState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useController, Control } from 'react-hook-form';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.scss';
import styles from './text-editor.module.scss';

interface TextEditorProps {
  label?: string,
  name: string,
  control: Control<any>,
  rules?: {},
  defaultValue?: any,
  heightAuto?: boolean,
}

const getEditorStateFromHTMLString = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

const TextEditor = ({
  label,
  name,
  control,
  rules = {},
  defaultValue = '',
  heightAuto = false,
} : TextEditorProps) => {
  const { field: { onChange } } = useController({
    name, control, defaultValue, rules,
  });

  // const createEditorStateWithValue = (value: any) => EditorState.createWithContent(
  //   typeof value === 'string'
  //     ? stateFromHTML(value)
  //     : convertFromRaw({
  //       blocks: value.blocks || [],
  //       entityMap: value.entityMap || {},
  //     } || {}),
  // );
  const [editorState, setEditorState] = useState(getEditorStateFromHTMLString(defaultValue));

  // useEffect(() => {
  //   console.log('here');
  //   console.log(getEditorStateFromHTMLString(defaultValue));
  //   setEditorState(getEditorStateFromHTMLString(defaultValue));
  // }, [defaultValue]);

  function handleChange(state: any) {
    const raw = convertToRaw(editorState.getCurrentContent());

    //* Set all link target to _blank
    const udpatedEntituMap: any = {};
    Object.values(raw.entityMap).forEach((entity: any, index) => {
      if (entity.type === 'LINK' && entity?.data) {
        udpatedEntituMap[index] = {
          ...entity,
          data: {
            ...entity.data,
            target: '_blank',
          },
        };
      } else udpatedEntituMap[index] = entity;
    });

    const html = draftToHtml({
      ...raw,
      entityMap: udpatedEntituMap,
    });

    const regex = /(style=".+?")/gm;
    // The substituted value will be contained in the result variable
    const cleanHTML = html.replace(regex, '');
    onChange(cleanHTML);
    setEditorState(state);
  }

  return (
    <div className={styles.texteditor}>
      {label && <label>{label}</label>}
      <Editor
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
        editorState={editorState}
        localization={{
          locale: 'fr',
        }}
        toolbarClassName={styles.toolbar}
        editorClassName={heightAuto ? `${styles.editor} ${styles.heightAuto}` : styles.editor}
        wrapperClassName={styles.wrapper}
        onEditorStateChange={(state: any) => {
          handleChange(state);
        }}
      />
    </div>
  );
};

export default TextEditor;
