/* eslint-disable max-len */
import React, {
  ForwardRefRenderFunction,
  useRef, useContext, forwardRef, useImperativeHandle,
  useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { IUser } from '~/types/users';
import {
  useAccount, useAccountsList, useUpdateAnimatorAccount,
} from '~/hooks/account';
import { AccountContext } from '~/accountContext';
import Modal from '~/lib/Modal';
import PresencesForm from '../form/RegisterForm/PresencesForm';
import {
  InputText,
  StyledSelect,
  ErrorField,
} from '~/lib/HooksFormFields';
import styles from './modal-animator.module.scss';
import Loader from '../Loader';

export type ModalAnimatorHandle = {
  open: () => void,
  close: () => void,
};

type ModalAnimatorProps = {};

const ModalTimeslots: ForwardRefRenderFunction<
ModalAnimatorHandle, ModalAnimatorProps> = (_, ref) => {
  const modalRef = useRef<any>(null);
  const { data: dataAccount } = useAccount();
  const account = dataAccount?.account;
  const { user } = useContext(AccountContext);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userFunction: user.userFunction || '',
      region: account?.region || '',
      presences: user.presences,
    },
  });
  const errorsForm: any = errors;

  const {
    isLoading: isAnimatorAccountLoading,
    isSuccess,
    mutate: updateAnimatorAccount,
  } = useUpdateAnimatorAccount();

  const {
    data: list,
  } = useAccountsList();
  const {
    days = [],
    regions = [],
  } = list;

  const submit = (data: {
    region: string,
    userFunction: string,
    presences: Pick<IUser, 'presences'>
  }) => {
    updateAnimatorAccount(data);
  };

  //* Close modal when user and account have
  //* required data
  useEffect(() => {
    if (!account || !user) return;

    if (
      isSuccess
      && user.presences.length === 2
      && user.userFunction
      && account.region
    ) {
      modalRef.current.close();
    }
  }, [isSuccess, account, user]);

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }), []);

  return (
    <Modal width={920} ref={modalRef} keepOpened >
      <div className={isAnimatorAccountLoading ? `${styles.modal} ${styles.loading}` : styles.modal}>
        {isAnimatorAccountLoading && (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
        <h2>Renseigner vos informations</h2>
        <div className={styles.row}>
          <div>
            <div className={styles.containerField}>
              <StyledSelect
                name='region'
                label='Région'
                control={control}
                options={regions}
                rules={{
                  required: 'Ce champs est obligatoire',
                }}
              />
            </div>
            {errors?.region?.message && (
              <ErrorField message={errors?.region?.message} />
            )}
          </div>
          <div>
            <div className={styles.containerField}>
              <InputText
                label="Fonction"
                name='userFunction'
                control={control}
                maxlength={20}
                rules={{
                  required: 'Ce champs est obligatoire',
                  validate: (value: string) => {
                    if (value.length <= 20) return true;
                    return 'La fonction ne doit pas faire plus 20 caractères';
                  },
                }}
              />
            </div>
            {errors?.userFunction && <ErrorField message={errors?.userFunction?.message} />}
          </div>
        </div>
        <PresencesForm
          control={control}
          setValue={setValue}
          from='animatorModal'
          days={days}
          errors={errorsForm.presences || []}
        />
        <div className={styles.containerButton}>
            <button disabled={isAnimatorAccountLoading} onClick={handleSubmit(submit)}>Valider</button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
