/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ICompany } from '~/types/companies';

// eslint-disable-next-line max-len
const createCompanyFournisseur = async (data: { name: string }): Promise<{ company: ICompany }> => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/companies/fournisseur/creatable`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  createCompanyFournisseur,
};
