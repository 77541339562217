import React, { ReactNode } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { IoClose } from 'react-icons/io5';
import { useArchiveNotification, useGetNotifications } from '~/hooks/notifications';
import styles from './notifications.module.scss';

interface NotificationsProps {
  setIsNotificationsOpen: (current: any) => void;
}

const Notifications = ({ setIsNotificationsOpen } : NotificationsProps) => {
  const { data } = useGetNotifications();
  const { mutate: archiveNotification } = useArchiveNotification();

  const formatDate: (date: string) => ReactNode = (date) => format(new Date(date), "'Le' d LLLL à HH:mm", { locale: fr });

  return (
    <div className={styles.notifications} data-close-notification='false' >
      <div className={styles.close} onClick={() => setIsNotificationsOpen(false)}>
        <IoClose size={35} />
      </div>
      <h1>Notifications</h1>
      {data?.length > 0 && data?.map((notification:
      { _id: string, text: string, date: string, isArchived: boolean }) => (
      <div className={styles.notification} key={notification._id}
        onClick={() => archiveNotification(notification._id)}>
        <span>{formatDate(notification.date)}
        {notification.isArchived === false && <div></div> }</span>
        <p>{notification.text}</p>
        <hr className={styles.hr}/>
      </div>
      ))}
    </div>
  );
};

export default Notifications;
