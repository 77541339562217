import React from 'react';
import iconExport from './images/export.svg';
import iconImport from './images/import.svg';
import styles from './button.module.scss';

const icons: any = {
  export: iconExport,
  import: iconImport,
};

const ButtonExport = ({ type, onClick } : { type: string, onClick: () => void }) => (
  <button className={styles.button} onClick={onClick}>
    <img src={icons[type]} alt={type} />
  </button>
);

export default ButtonExport;
