import React from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import styles from './back-button.module.scss';

const BackButton = ({ path } : { path?: string }) => {
  const navigate = useNavigate();
  return (
    <a className={styles.backward} onClick={() => (path ? navigate(path) : navigate(-1))}>
      <RiArrowGoBackFill />
      Retour
    </a>
  );
};

export default BackButton;
