import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useAccount } from '~/hooks/account';
import { AccountContext } from '~/accountContext';
import { downloadBadge } from '~/utils';
import styles from './exhibitions.module.scss';
import Footer from '../../components/Footer/index';
import Card from '~/components/exhibitions/Card';
import Banner from '../../components/exhibitions/Banner/index';
import Offers from '../../components/exhibitions/Offers';
import salonUBazar from '../../assets/images/exhibitions/salon-u-bazar.png';
import salonUAnm from '../../assets/images/exhibitions/salon-u-anm.png';
import salonUOuestPgc from '../../assets/images/exhibitions/salon-u-ouest-pgc.png';
import pictoBadge from '../../assets/images/exhibitions/picto-badge.png';
import pictoFormations from '../../assets/images/exhibitions/picto-formations.png';

const Exhibitions = () => {
  const { user } = useContext(AccountContext);
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } : any = account || {};

  return (
    <div className={styles.exhibitions}>
      <Banner />
      <div className={styles.content}>
        <h1>Participez au Salon U 2024</h1>
        <h2>les 19 et 20 juin à Nantes</h2>
        <div className={styles.cards}>
          <Card img={salonUBazar} navLink='/bazar' button="En savoir plus" plan={process.env.REACT_APP_PLAN_GP_XXL_FILE_ID} className={styles.card}>
            <h3>Salon U <em>Bazar</em></h3>
          </Card>
          <Card img={salonUAnm} navLink='/anm' button="En savoir plus" plan={process.env.REACT_APP_PLAN_GP_FILE_ID} className={styles.card}>
            <h3>Salon U <em>ANM</em></h3>
          </Card>
          {(
            companyAccount?.address?.region === 'ouest'
            || companyAccount?.type === 'fournisseur'
            || user.isCollaborator
            || account?.role === 'Admin'
          ) && <Card img={salonUOuestPgc} navLink='/pgc' button="En savoir plus" plan={process.env.REACT_APP_PLAN_HALL_2_3_4_FILE_ID} className={styles.card}>
            <h3>Salon U <em>Ouest <br></br>PGC, Frais LS <br></br>& Métiers</em></h3>
          </Card>}
        </div>
      </div>
      {!user.isCollaborator && (
        <Offers />
      )}
      {/* <Offers secondButton/> */}
      <div className={styles.lastBloc} style={user.isCollaborator ? { marginTop: 0 } : {}}>
        <div className={styles.badge}>
          <img src={pictoBadge} alt="picto badge" />
          <h3>{'N\'oubliez pas d\'imprimer votre badge !'}</h3>
          <p>Les badges dématérialisés ne seront pas acceptés sur le salon.</p>
          <button onClick={() => downloadBadge(user._id)}>{'J\'imprime mon badge'}</button>
        </div>
        {companyAccount?.type === 'magasin' && (
          <div className={styles.formations}>
            <img src={pictoFormations} alt="picto formations" />
            <h3>Formations & <br></br>  conférences</h3>
            <p>Choisissez la date <br></br> et l’heure qui vous convient.</p>
            <NavLink to='/formations'>
              <button>
                {'Je m\'inscris'}
              </button>
            </NavLink>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Exhibitions;
