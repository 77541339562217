/* eslint-disable max-len */
import React, {
  useState, useContext, useMemo,
  useEffect,
  // useCallback,
} from 'react';
import { BsCheck } from 'react-icons/bs';
import { MdAddShoppingCart } from 'react-icons/md';
import { AccountContext } from '~/accountContext';
import { useAccount } from '~/hooks/account';
import { useHandleOrder } from '~/hooks/orders';
import { IProduct } from '~/types/products';
import styles from './input-product-cart.module.scss';

const InputProductToCart = ({
  product,
  onChange,
  visible = false,
} : {
  product: IProduct,
  visible?: boolean,
  onChange: (args: {
    account: string,
    productId: string,
    quantity: number,
    user: string,
  }) => void,
}) => {
  const { data } = useAccount();
  const account = data?.account;
  const { user } = useContext(AccountContext);
  const [value, setValue] = useState(product.countInOrder || 0);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const {
    isLoading,
  } = useHandleOrder();

  useEffect(() => {
    setValue(product.countInOrder || 0);
  }, [product.countInOrder]);

  const maxValue = useMemo(() => {
    let val;
    if (product.availableQuantity && product.globalCountInOrder) {
      val = (product.countInOrder || 0) + (product.availableQuantity - product.globalCountInOrder);
    } else if (product.availableQuantity && !product.globalCountInOrder) {
      val = product.availableQuantity;
    }
    return val;
  }, [product]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!account) return;
    const quantity = parseInt(e.target.value, 10);
    setValue(quantity);
    setHasChanged(true);
  };

  const handleQuantity = (e: React.MouseEvent, action: string) => {
    e.stopPropagation();
    e.preventDefault();
    if (!account) return;
    let quantity = value;
    if ((value >= 1 && action === 'sub')) quantity -= 1;
    else if (action === 'add') quantity += 1;

    if (!maxValue || (typeof maxValue === 'number' && quantity <= maxValue)) {
      setValue(quantity);
      setHasChanged(true);
    }
  };

  const handleChangeValidation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!account) return;
    if (!isInputOpen && !visible) {
      setIsInputOpen(true);
      return;
    }
    if (hasChanged) {
      onChange({
        account: account?._id,
        productId: product._id,
        quantity: value,
        user: user._id,
      });
    }
    setHasChanged(false);
    setIsInputOpen(false);
  };

  return (
    <div className={visible ? `${styles.container} ${styles.white}` : styles.container}>
      <div style={(isInputOpen || visible) ? {} : { visibility: 'hidden' }}>
        <button
          onClick={(e) => handleQuantity(e, 'sub')}
        >
          -
        </button>
        <input
          className={styles.input}
          disabled={isLoading}
          type='number'
          value={value}
          onChange={handleChange}
          min={0}
        />
        <button
          disabled={!!(typeof maxValue === 'number' && value === maxValue)}
          onClick={(e) => handleQuantity(e, 'add')}
        >
          +
        </button>
      </div>
      <button
        className={(isInputOpen || visible) ? `${styles.validation} ${styles.active}` : styles.validation}
        onClick={handleChangeValidation}
      >
        {hasChanged
          ? <span style={{ position: 'relative', top: -2, left: -1 }}><BsCheck size={35} /></span>
          : <MdAddShoppingCart size={25} />
        }
      </button>
    </div>
  );
};

export default InputProductToCart;
