import React, { useEffect } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import styles from './qr-reader.module.scss';

const QRReader = () => {
  useEffect(() => {

  }, []);

  return (
    <div className={styles['qr-reader']}>
      <div className={styles.content}>
        <div className={styles.reader}>
          <Scanner
            onResult={(text, result) => console.log(text, result)}
            onError={(error) => console.log(error?.message)}
          />
        </div>
      </div>
    </div>
  );
};

export default QRReader;
