/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { IUser } from '~/types/users';

// eslint-disable-next-line max-len
const updateUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const validateUserFormationPresence = async (data: { _id: string, timeslot: string, formation: string }): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, timeslot, formation } = data;

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}/formations/validate-presence`,
    { timeslot, formation },
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  updateUser,
  validateUserFormationPresence,
};
