import React from 'react';
import styles from './waiting-page.module.scss';
import logoU from '../../assets/images/logo-u.png';
import salonUEnseigne from '../../assets/images/signin-logo.png';
import logoUWhite from '../../assets/images/logo-u-white.png';

const WaitingPage = () => (
  <div className={styles.waitingPage}>
    <div className={styles.banner}>
      <div className={styles.logo}>
        <img src={logoU} alt="logo U Enseigne" />
      </div>
      <div className={styles.title}>
        <h1>Bienvenue au</h1>
        <img src={salonUEnseigne} alt="Salon U Enseigne 2024" />
      </div>
    </div>
    <div className={styles.mainContent}>
      <h1>Inscrivez-vous prochainement</h1>
      <button>Soyez prêt(e)s !</button>
      <div>
      <p>Le site internet du Salon U Enseigne</p>
      <p>sera accessible à partir du <em>2 mai 2024.</em></p>
      </div>
    </div>
    <footer>
      <img src={logoUWhite} alt="logo U Enseigne" />
      <span>
        © Copyright U Enseigne 2024 - Design graphique : Xylème Com - Powered by Bakasable.
      </span>
    </footer>
  </div>
);

export default WaitingPage;
