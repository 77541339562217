import React, {
  useCallback,
} from 'react';
import {
  useWatch,
} from 'react-hook-form';
import { IOption } from '~/types/options';
import {
  ErrorField,
  ListTags,
} from '~/lib/HooksFormFields';
import styles from './presences-form.module.scss';
import monGesteAntiGaspi from '../../../../assets/images/mon-geste-anti-gaspi.png';

const PresencesForm = (
  {
    control,
    setValue,
    days,
    from = '',
    errors,
  } : {
    control: any,
    from?: string,
    setValue: any,
    days: IOption[],
    errors: any
  },
) => {
  const presences = useWatch({ control, name: 'presences' }) || [];

  const getErrors = useCallback((key: 'present' | 'lunch') => {
    const errorsArr = errors.map((error: any) => (error?.[key]));
    const error = errorsArr.find((d: any) => d);
    const msg = error?.message || '';
    return msg ? <ErrorField message={msg} /> : null;
  }, [errors]);

  return (
    <div className={`${styles.presences} ${styles[from]}`}>
      <div>
        <p>
          Serez-vous présent au salon:<sup>*</sup>
        </p>
        <div className={styles.containerField}>
          {days.map((day: IOption, index: number) => (
            <ListTags
              key={`presences.${index}.present`}
              name={`presences.${index}.present`}
              label={day.label}
              control={control}
              inline
              handleClick={(value) => {
                if (!value) setValue(`presences.${index}.lunch`, false);
              }}
              tags={[
                { label: 'Oui', value: true },
                { label: 'Non', value: false },
              ]}
              rules={{
                validate: (value: any) => {
                  if (typeof value === 'boolean') return true;
                  return 'Veuillez renseigner les présences';
                },
              }}
            />
          ))}
        </div>
        {getErrors('present')}
      </div>
      <div className={(!presences[0]?.present && !presences[1]?.present) ? styles.disabled
        : styles.visible}>
        <p>
          Souhaitez-vous déjeuner sur place ?<sup>*</sup>
        </p>
        <div className={styles.containerField} >
          {days.map((day: IOption, index: number) => (
            <div key={`presences.${index}.lunch`} className={!presences[index]?.present ? styles.disabled : ''}>
              <ListTags
                name={`presences.${index}.lunch`}
                label={day.label}
                control={control}
                inline
                tags={[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]}
                rules={{
                  validate: (value: any) => {
                    if (typeof value === 'boolean') return true;
                    return 'Veuillez renseigner votre présence aux déjeuners';
                  },
                }}
              />
            </div>
          ))}
        </div>
        {getErrors('lunch')}
      </div>
      {from === 'register' && (
        <div className={styles.logo}>
          <img src={monGesteAntiGaspi} alt="mon geste anti gaspi"/>
          <hr className={styles.hr}/>
          <div className={styles.div}>
          <p>La nouveauté cette année&nbsp;:</p>
          <span>Un repas anticipé, des déchets évités&nbsp;!</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PresencesForm;
