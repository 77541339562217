import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '~/hooks/products';
import Loader from '~/components/Loader';
import Footer from '~/components/Footer';
import styles from './detail.module.scss';
import BackButton from '~/components/BackButton';

const DetailProduct = () => {
  const { id } = useParams();
  const { data: product, isLoading } = useGetProduct(id || '');

  return (
    <div className={styles.detailProduct}>
      <div className={styles.content}>
        {isLoading && (
          <Loader />
        )}
        {product && (
          <>
            <BackButton />
            <div className={styles.header}>
              <h1>
                {product.name}
                <span>{product.ref}</span>
              </h1>
            </div>
            <div className={styles.detail}>
              <section>
                <h4>Infos fournisseur</h4>
                <div>
                  <p>Fournisseur&nbsp;:<span>&nbsp;{product?.company?.name}</span></p>
                  <p>Libellé de la marque&nbsp;:<span>&nbsp;{product.brand}</span></p>
                  <p>Chef de produit&nbsp;:<span>&nbsp;{product.contact}</span></p>
                </div>
              </section>
              <section>
                <h4>Détail commande</h4>
                <div>
                  <p>Rayon&nbsp;:<span>&nbsp;{product.type}</span></p>
                  <p>EAN produit&nbsp;:<span>&nbsp;{product.ean}</span></p>
                  <p>PCB&nbsp;:<span>&nbsp;{product.cdtCession}</span></p>
                  <p>Livraison&nbsp;:<span>&nbsp;{product.delivery}</span></p>
                </div>
              </section>
              <section>
                <h4>Prix et taxes</h4>
                <div className={styles.pricings}>
                  <p>Prix achat net permanent<span>&nbsp;:&nbsp;{product.pricePA}€</span></p>
                  <p className={styles.discount}>
                    Remise&nbsp;:&nbsp;<span>{(product.discount || 0).toFixed(2)}€</span>
                  </p>
                  <p>
                    Prix achat net salon&nbsp;:&nbsp;<span>{product.price}€</span>
                  </p>
                  <div className={styles.recommendedPrice}>
                    <p>
                      PV conseillé&nbsp;:<span>&nbsp;{product.recommendedPrice}€</span>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DetailProduct;
