import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

interface PositionalModalHandle {
  open: () => void,
  close: () => void,
}

interface PositionalModalProps {
  children: React.ReactNode,
  parent: any,
  containerStyles?: any,
  domNode?: string,
  defaultOpen?: boolean,
  handleClose?:() => void
}

const PositionalModal = forwardRef<PositionalModalHandle, PositionalModalProps>(({
  children,
  parent,
  containerStyles = {},
  domNode = 'modal',
  defaultOpen = false,
  handleClose,
}, ref) => {
  const modalRoot = document.getElementById(domNode);
  const modalRef = useRef<any>();
  const [open, setOpen] = useState(defaultOpen);
  const [position, setPosition] = useState({ x: -1000, y: -1000 });

  function setPositionModal() {
    const target = parent.current;
    if (!target || !modalRef.current) return;
    const boundParent = target.getBoundingClientRect();
    const boundModal = modalRef.current.getBoundingClientRect();
    let x = boundParent.x + boundParent.width;

    if ((x + boundModal.width) > (window.innerWidth - 20)) {
      x = (window.innerWidth - boundModal.width) - 20;
    }

    let { y } = boundParent;
    if (y < 20) {
      y = 20;
    } else if (y + boundModal.height > (window.innerHeight - 20)) {
      y = (window.innerHeight - boundModal.height) - 20;
    }

    setPosition({
      x: boundParent.x === 0 ? -1000 : x,
      y: boundParent.y === 0 ? -1000 : y,
    });
  }

  useImperativeHandle(ref, () => ({
    toggle: () => setOpen((state) => {
      if (handleClose && !state) handleClose();
      return !state;
    }),
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
      if (handleClose) handleClose();
    },
    setPositionModal: () => setPositionModal(),
  }));

  useEffect(() => {
    if (!ref) console.error('PositionalModal components need require a ref');
  }, [ref]);

  useEffect(() => {
    if (!modalRoot) {
      console.error(`Can't find the dom element (#${domNode}) where this modal should be mount \nYou should add a div with id : "${domNode}" to public/index.html
     `);
    }
  }, [modalRoot, domNode]);

  // HANDLE CLOSE MODAL WHEN CLICK OUTSITE
  // EXCEPT IT TARGET ELEMENT HAS ATTRIBUTE
  // data-close-modal='false'
  useEffect(() => {
    const root = document.getElementById('root');
    if (!root) return () => {};
    const closeModal = (e: any) => {
      if (open && !e.target.closest("[data-close-modal='false']")) {
        if (handleClose) handleClose();
      }
    };
    root.addEventListener('click', closeModal);
    root.addEventListener('wheel', closeModal);
    return () => {
      root.removeEventListener('wheel', closeModal);
      root.removeEventListener('click', closeModal);
    };
  }, [open]);

  useEffect(() => {
    if (!open) return;
    setPositionModal();
  }, [parent, open]);

  function handleBlur(e: any) {
    const focusElement = e.relatedTarget?.closest("[data-close-modal='false']");
    if (!focusElement) {
      if (handleClose) handleClose();
    }
  }

  return (
    <>
      {modalRoot
        && <>
          {createPortal(
            <>
              {(ref && open) && (
                <div
                  ref={modalRef}
                  style={{ ...containerStyles, top: `${position.y}px`, left: `${position.x}px` }}
                  className={styles.modal}
                  onBlur={(e) => handleBlur(e)}
                >
                  {children}
                </div>
              )}
            </>,
            modalRoot,
          )}
        </>
      }
    </>
  );
});

export default PositionalModal;
