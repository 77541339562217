import React, {
  CSSProperties,
  useMemo, useEffect, useState, useCallback,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import styles from './orders-table.module.scss';
import { IOption } from '~/types/options';
import { IParsedOrder } from '~/types/orders';
import { useGetOrdersList } from '~/hooks/orders';

//* Orders fournisseurs

type IOptionColor = IOption & { color: string };

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const columns = [{
  header: 'Nom du magasin',
  accessorKey: 'companyBuyer.name',
}, {
  header: 'Code associé',
  accessorKey: 'companyBuyer.code',
}, {
  header: "Nombre d'offres au panier",
  accessorKey: 'productsCount',
  cell: ({ row } : { row: Row<IParsedOrder> }) => {
    const value = row.original.products.length;
    return <span>{value}</span>;
  },
}, {
  header: 'Total panier',
  accessorKey: 'totalPrice',
  cell: ({ getValue } : { getValue: any }) => {
    const value = getValue();
    return `${value}€`;
  },
}, {
  header: 'Statut',
  accessorKey: 'status',
  cell: (row: any) => {
    const { getValue, status } = row;
    const value = getValue();
    const option = status.find((opt: IOptionColor) => opt.value === value);
    return (
      <span style={{ '--tonic': option?.color || '#007d92' } as customCSS}>
        {option.label}
      </span>
    );
  },
}];

const OrdersTable = (
  {
    orders,
    handleSorting,
    pathToDetail,
    status = [],
  } : {
    orders: IParsedOrder[],
    handleSorting: any,
    pathToDetail?: string,
    status: IOptionColor[],
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  const data = useMemo(() => orders, [orders]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const onRowClick = (row: Row<IParsedOrder>) => {
    navigate(pathToDetail ? `${pathToDetail}/${row.original._id}` : `/paniers/${row.original._id}`);
  };

  return (
    <div className={styles.ordersTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={header.column.id !== 'productsCount' ? header.column.getToggleSortingHandler() : () => {}}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </span>
                  {header.column.id !== 'productsCount' && (
                    <div className={styles.sortIcons}>
                      <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                      <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    {
                      ...cell.getContext(),
                      status: cell.column.id === 'status' ? status : [],
                    },
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ResponsivOrdersTable = (
  {
    orders,
    handleSorting,
    pathToDetail,
  } : {
    orders: IParsedOrder[],
    handleSorting: any,
    pathToDetail?: string,
  },
) => {
  const isTablet = useMediaQuery('(max-width: 1180px)');
  const { data: list = [] } = useGetOrdersList();
  const {
    status = [],
  } = list;

  const getOptions = useCallback((options: IOptionColor[], value: string | number)
  : IOptionColor | null => {
    const option = (options || []).find((opt: IOptionColor) => opt.value === value) || null;
    return option;
  }, []);

  return (
    <>
      {isTablet ? (
        <ul className={styles.listTablet}>
          {orders.map((order: IParsedOrder) => (
            <li key={`order-${order._id}`}>
              <NavLink to={`/paniers/${order._id}`} className={styles.order}>
                <div>
                  <p className={styles.name}>{(order.companyBuyer as any)?.name}</p>
                  <p className={styles.productsCount}>{'Nombre d\'offre au panier'}<span>{order.products.length}</span></p>
                  <p className={styles.totalPrice}>Total panier <span>{order.totalPrice}€</span></p>
                </div>
                <div className={styles.status}>
                  <span style={{ '--tonic': getOptions(status, order.status)?.color || '#007d92' } as customCSS}>
                    {getOptions(status, order.status)?.label}
                  </span>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        <OrdersTable
          orders={orders}
          handleSorting={handleSorting}
          pathToDetail={pathToDetail}
          status={status}
        />
      )}
    </>

  );
};

export default ResponsivOrdersTable;
