/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const getNotifications = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/notifications`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.notifications;
};

const archiveNotification = async (_id: string): Promise<{ notification: any }> => {
  const token = localStorage.getItem('token');

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/notifications/archive/${_id}`,
    null,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  getNotifications,
  archiveNotification,
};
