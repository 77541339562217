/* eslint-disable max-len */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '~/hooks/products';
import { useHandleOrder } from '~/hooks/orders';
import { DataHandleOrders } from '~/types/orders';
import imgBazarProduct from '~/assets/images/bazar-products.png';
import Loader from '~/components/Loader';
import Footer from '~/components/Footer';
import styles from './detail.module.scss';
import InputProductToCart from '~/components/table/magasins/ProductsTable/InputProductToCart';
import BackButton from '~/components/BackButton';

const DetailProduct = () => {
  const { id } = useParams();
  const {
    mutate: handleOrder,
  } = useHandleOrder('__productName__ a été ajouté à votre panier');
  const { data: product, isLoading } = useGetProduct(id || '');

  const handleChangeProductQuantity = ({
    account, productId, quantity, user,
  } : DataHandleOrders) => {
    handleOrder({
      account,
      productId,
      quantity,
      user,
    });
  };

  return (
    <div className={styles.detailProduct}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <img src={imgBazarProduct} alt="Produits Bazar" />
          <div>
            <p>
              Selectionnez ci-dessous les offres qui vous intéressent
              et ajoutez-les à votre panier.
            </p>
            <p>
              Présentez-vous au Salon U Bazar pour valider vos offres.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {isLoading && (
          <Loader />
        )}
        {product && (
          <>
            <BackButton />
            <div className={styles.header}>
              <h1>
                {product.name}
              </h1>
              <InputProductToCart
                product={product}
                onChange={handleChangeProductQuantity}
                visible
              />
            </div>
            <div className={styles.detail}>
              <section>
                <h4>Prix</h4>
                <div>
                  <div className={styles.discount}>
                    <p>
                      Remise spécial salon cession&nbsp;:&nbsp;{(product.discount || 0).toFixed(2)}€
                    </p>
                  </div>
                  <p>Prix de cession permanent&nbsp;:&nbsp;{product.pricePA}€</p>
                  <div className={styles.price}>
                    <p>
                      Prix de cession salon<br/>
                      <span>{product.price}€</span>
                    </p>
                  </div>
                  {product.franco && (
                    <p className={styles.franco}>
                      Franco&nbsp;:&nbsp;<span>{product.franco}</span>
                    </p>
                  )}
                </div>
              </section>
              <section>
                <h4>Informations</h4>
                <div>
                  <p>Fournisseur&nbsp;:<span>&nbsp;{product?.company?.name}</span></p>
                  <p>Libellé de la marque&nbsp;:<span>&nbsp;{product.brand}</span></p>
                  <p>Cdt cession&nbsp;:<span>&nbsp;{product.cdtCession}</span></p>
                  <p>Catégorie&nbsp;:<span>&nbsp;{product.category}</span></p>
                  <p>Produit&nbsp;:<span>&nbsp;{product.ref}</span></p>
                  <p>EAN produit&nbsp;:<span>&nbsp;{product.ean}</span></p>
                  <p>Rayon&nbsp;:<span>&nbsp;{product.type}</span></p>
                  <p>Livraison&nbsp;:<span>&nbsp;{product.delivery}</span></p>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DetailProduct;
