import React from 'react';
import { Control, useController } from 'react-hook-form';
import styles from './input-duration.module.scss';

const InputDuration = ({
  label,
  control,
  defaultValue,
  min = 0,
  name = 'duration',
} : {
  label: string,
  control: Control,
  defaultValue: number,
  min?: number,
  name?: string,
}) => {
  const { field: { onChange, value } } = useController({
    name,
    control,
    defaultValue,
    rules: {
      required: 'Ce champs est obligatoire',
    },
  });

  return (
    <>
      <div className={styles.inputDuration}>
        <label>{label}</label>
        <ul>
          {min <= 15 && (
            <li className={value === 15 ? styles.active : ''} onClick={() => onChange(15)}>
              <div>15 min</div>
            </li>
          )}
          {min <= 20 && (
            <li className={value === 20 ? styles.active : ''} onClick={() => onChange(20)}>
              <div>20 min</div>
            </li>
          )}
          {min <= 30 && (
            <li className={value === 30 ? styles.active : ''} onClick={() => onChange(30)}>
              <div>30 min</div>
            </li>
          )}
          {min <= 45 && (
            <li className={value === 45 ? styles.active : ''} onClick={() => onChange(45)}>
              <div>45 min</div>
            </li>
          )}
          {min <= 60 && (
            <li className={value === 60 ? styles.active : ''} onClick={() => onChange(60)}>
              <div>60 min</div>
            </li>
          )}
          {min <= 90 && (
            <li className={value === 90 ? styles.active : ''} onClick={() => onChange(90)}>
              <div>90 min</div>
            </li>
          )}
          {min <= 105 && (
            <li className={value === 105 ? styles.active : ''} onClick={() => onChange(105)}>
              <div>105 min</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default InputDuration;
