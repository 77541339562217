import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { downloadBadge } from '~/utils';
import logoUWhite from '../../assets/images/logo-u-white.png';
import styles from './footer.module.scss';
import { AccountContext } from '~/accountContext';

const Footer = () => {
  const { user } = useContext(AccountContext);
  return (
    <footer className={styles.footer}>
        <div className={styles.questions}>
          <img src={logoUWhite} alt="logo u" />
          <div>
            <p className={styles.question}>Une question ?</p>
            <p>Merci de contacter le service relation magasin</p>
            <p><a href={'mailto:srm.cnb@systeme-u.fr'}>srm.cnb@systeme-u.fr</a></p>
          </div>
          <div className={styles.informations}>
            <NavLink to='/infos-pratiques'>
              Informations Pratiques
            </NavLink>
            <NavLink to='/account'>
              Mon compte
            </NavLink>
            <a onClick={() => downloadBadge(user._id)}>
              Télécharger mon badge
            </a>
          </div>
        </div>
        <div className={styles.copyright}>
          <div>
            <span>
            © Copyright U Enseigne 2024 - Design graphique : Xylème Com - Powered by Bakasable.
            Crédits photos : ©arturaliev, ©Rogatnev, ©finepoints, ©Beeler, ©He2,
            ©HstrongART - stock.adobe.com
            </span><br />
            <span>{'L\'abus d\'alcool est dangereux pour la santé. A consommer avec modération.'}</span>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
