import React, { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery, useDebounceValue } from 'usehooks-ts';
import { IoFilter } from 'react-icons/io5';
import { useGetProductsMagasins, useGetProductsList } from '~/hooks/products';
import productsImage from '~/assets/images/offres.png';
import ProductsTable from '~/components/table/magasins/ProductsTable';
import Pagination from '~/components/Pagination';
import InputSearch from '~/components/InputSearch';
import FilterSearchParam from '~/components/FilterSearchParam';
import Footer from '~/components/Footer';
import styles from './list-products.module.scss';

const ListProducts = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  type PaginationHandle = React.ElementRef<typeof Pagination>;
  const paginationRef = useRef<PaginationHandle>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const [isFiltersOpened, setIsFiltersOpened] = useState(false);

  const { data: list } = useGetProductsList();
  const {
    providers = [],
    types = [],
    categories = [],
  } = list || {};

  const { data: dataProducts = {} } = useGetProductsMagasins({
    ...paramsObject,
    search: debouncedSearch,
  });
  const { products = [], meta = {} } = dataProducts;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleChangePage = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
  };

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  return (
    <div className={styles.products}>
      <img src={productsImage} alt='Les offres salon' />
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{meta?.total} Offre{meta?.total > 1 && 's'}</h1>
          <div className={styles.filters}>
            {(!isMobile || isFiltersOpened) && (
              <div className={styles.selectFilters}>
                <div className={styles.field}>
                  <FilterSearchParam
                    label='Fournisseurs'
                    name='provider'
                    options={providers}
                    isSearchable
                  />
                </div>
                <div className={styles.field}>
                  <FilterSearchParam
                    label='Rayon'
                    name='type'
                    options={types}
                  />
                </div>
                <div className={styles.field}>
                  <FilterSearchParam
                    label='Catégories'
                    name='category'
                    options={categories}
                  />
                </div>
              </div>
            )}
            <span className={styles.search}>
              <InputSearch
                defaultValue={search}
                handleSearch={handleSearch}
              />
            </span>
            {isMobile && (
              <div className={styles.containerButtonFilter}>
                  <button className='invisible' onClick={() => setIsFiltersOpened((state) => !state)}>
                    <span>Filtrer</span>
                    <span className={styles.icon}><IoFilter size={26} /></span>
                  </button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.containerList}>
          <div className={styles.list}>
            <ProductsTable
              products={products}
              handleSorting={handleSorting}
            />
          </div>
          <div className={styles.footer}>
            {meta?.total > 0 && <Pagination
              ref={paginationRef}
              pages={meta?.pages || 0}
              defaultPage={parseInt(searchParams.get('page') || '1', 10)}
              handleChange={handleChangePage}
            />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ListProducts;
