import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';
import { useGetOrders, useGetOrdersList } from '~/hooks/orders';
import { exportOrders } from '~/utils';
import styles from './orders.module.scss';
import FilterSearchParam from '~/components/FilterSearchParam';
import InputSearch from '~/components/InputSearch';
import ButtonExport from '~/components/ButtonExport';
import ResponsivOrdersTable from '~/components/table/fournisseurs/OrdersTable';
import Pagination from '~/components/Pagination';
import Footer from '../../../components/Footer';

const OrdersList = () => {
  type PaginationHandle = React.ElementRef<typeof Pagination>;
  const paginationRef = useRef<PaginationHandle>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', ...paramsObject } = Object.fromEntries(searchParams);
  const [debouncedSearch] = useDebounceValue(search, 500);
  const { data: dataOrders = {} } = useGetOrders({
    ...paramsObject,
    search: debouncedSearch,
  });

  const { orders = [], meta = {} } = dataOrders;

  const { data: list = {} } = useGetOrdersList();
  const { status = [] } = list;

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 3) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleChangePage = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
  };

  return (
    <div className={styles.orders}>
    <div className={styles.content}>
      <div className={styles.header}>
        <h1>{orders.length} Panier{orders.length > 1 ? 's' : ''} magasins</h1>
        <div className={styles.actions}>
          <ButtonExport type='export' onClick={() => exportOrders({
            ...paramsObject,
            search: debouncedSearch,
          })} />
        </div>
      </div>
      <div className={styles.filters}>
        <div className={styles.selectFilters} >
            <div className={styles.field}>
              <FilterSearchParam
                label='Statut'
                name='status'
                options={status}
              />
            </div>
          </div>
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
      </div>
      <div className={styles.containerList}>
        <div className={styles.list}>
          <ResponsivOrdersTable
            orders={orders}
            handleSorting={handleSorting}
          />
        </div>
        <div className={styles.footer}>
          {meta.total > 0 && <Pagination
            ref={paginationRef}
            pages={meta?.pages || 0}
            defaultPage={parseInt(searchParams.get('page') || '1', 10)}
            handleChange={handleChangePage}
          />}
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default OrdersList;
