import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import getErrorAuthMessage from '../../utils/auth-helpers';
import ResetPwdForm from '../../components/form/ResetPwdForm';
import logoU from '../../assets/images/logo-u.png';
import imgSignin from '../../assets/images/signin-logo.png';
import styles from './reset-password.module.scss';
import { useResetPassword } from '~/hooks/account';

const ResetPassword = ({ createPassword = false }: { createPassword?: Boolean }) => {
  const { token } = useParams();
  const {
    mutate: resetPassword, isSuccess, error,
  } = useResetPassword();
  const errorApi: any = error;

  const submitReset = async (data: { password: string }) => {
    if (!token) return;
    try {
      try {
        await resetPassword({
          password: data.password,
          token,
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const errorMessage = useMemo(() => {
    if (!error) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.resetPassword}>
      <div className={styles.logo}>
        <img src={logoU} alt= "Logo U enseigne" />
      </div>
      <div className={styles.container}>
          <div>
            <h1>Bienvenue au</h1>
            <img src={imgSignin} alt= "Bienvenue au salon U" />
          </div>
          <div className={styles.form}>
            {createPassword ? (
              <h1>Création de mot de passe</h1>
            ) : (
              <h1>Mot de passe oublié</h1>
            )}
            <ResetPwdForm
              submit={submitReset}
              signinLink={'/login'}
              // eslint-disable-next-line no-nested-ternary
              succeedMessage={isSuccess ? (
                createPassword ? 'Votre mot de passe a été créé.' : 'Votre mot de passe a été réinitialisé.'
              ) : ''}
              errorMessage={errorMessage}
            />
          </div>
      </div>
    </div>
  );
};

export default ResetPassword;
