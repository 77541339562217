import React, {
  CSSProperties,
  useRef, useCallback,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useParams } from 'react-router-dom';
import { MdAddShoppingCart } from 'react-icons/md';
import { BsUpcScan } from 'react-icons/bs';
import { useGetOrder, useUpdateOrder, useGetOrdersList } from '~/hooks/orders';
import { IOption } from '~/types/options';
import Loader from '~/components/Loader';
import styles from './detail.module.scss';
import BackButton from '~/components/BackButton';
import ModalScan from '~/components/ModalScan';
import ProductsTable from '~/components/Orders/ProductsTable';
import { useGetFormationsList } from '~/hooks/formations';

type IOptionColor = IOption & { color: string };

interface customCSS extends CSSProperties {
  '--tonic': string;
}

const DetailOrder = () => {
  const isTablet = useMediaQuery('(max-width: 1024px)');

  type ModalScanHandler = React.ElementRef<typeof ModalScan>;
  const modalScanRef = useRef<ModalScanHandler>(null);
  const { id } = useParams();
  const { data: order, isLoading } = useGetOrder(id || '');
  const {
    mutateAsync: updateOrder,
  } = useUpdateOrder();
  const { data: list = {} } = useGetOrdersList();
  const { data: listFormation = {} } = useGetFormationsList();

  const {
    status = [],
    regions = [],
  } = {
    ...list,
    ...listFormation,
  };

  const submit = async (result: { userId: string, company: string | null } | null) => {
    let succeed = false;
    if (order.companyBuyer._id === result?.company) {
      const res: any = await updateOrder({
        _id: order._id,
        status: 'valid',
      });
      succeed = !!res?.order;
    }
    return succeed;
  };

  const getOptions = useCallback((options: IOptionColor[], value: string | number)
  : IOptionColor | null => {
    const option = (options || []).find((opt: IOptionColor) => opt.value === value) || null;
    return option;
  }, []);

  const getLabel = useCallback((options: IOption[], value: string | number) => {
    const label = (options || []).find((opt: IOption) => opt.value === value)?.label || '';
    return label;
  }, []);

  return (
    <>
      <ModalScan
        ref={modalScanRef}
        textSubmit='Valider la commande'
        submit={submit}
        validate={(result) => order.companyBuyer._id === result?.company}
      >
        {(success) => (
          <>
            {success ? (
              <div className={styles.orderValidated}>
                <h1>Commande validée !</h1>
                <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
              </div>
            ) : (
            <div className={styles.orderValidated}>
              <h1 className={styles.error}>{'La commande n\'a pas pu être validée !'}</h1>
              <button onClick={() => modalScanRef.current?.close()}>Retour à ma commande</button>
            </div>
            )}
          </>
        )}
      </ModalScan >
      <div className={styles.detailProduct}>
        <div className={styles.content}>
          {isLoading && (
            <Loader />
          )}
          {order && (
            <>
              <div className={styles.nav}>
                <BackButton />
              </div>
              <div className={styles.header}>
                <h1>
                  {(order.companyBuyer as any)?.address?.region && (
                    <span>
                      {getLabel(regions, (order.companyBuyer as any)?.address?.region)} - </span>
                  )}
                  {(order.companyBuyer as any).name}
                </h1>
                <p>{(order.companyBuyer as any).code}</p>
                {(!isTablet && order?.status === 'pending') && (
                  <button onClick={() => modalScanRef?.current?.open()}>
                    <span className={styles.icon}>
                      <BsUpcScan size={30} />
                    </span>
                    <span>Valider la commande</span>
                  </button>
                )}
              </div>
              <div className={styles.detail}>
                <div className={styles.status} >
                  <p>
                    Statut
                    <span style={{ '--tonic': getOptions(status, order.status)?.color || '#007d92' } as customCSS}>
                      {getOptions(status, order.status)?.label}
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.contentShoppingCart}>
                <div className={styles.detailShoppingCart} >
                  <div className={styles.shoppingCart}>
                    <MdAddShoppingCart size={30} />
                    <h2>
                      Panier
                      <span>
                       &nbsp;{order.products.length} offre{order.products.length > 1 ? 's' : ''}
                      </span>
                    </h2>
                  </div>
                  <span className={styles.total}>
                    <p>Total Panier&nbsp;:&nbsp;<span>{order.totalPrice}€</span></p>
                  </span>
                </div>
                {isTablet ? (
                  <ul className={styles.list}>
                    {order.products.map((d: any) => (
                      <li key={`product-${d.product._id}`}>
                        <div className={styles.product}>
                          <div>
                            <p className={styles.name}>{d.product.name}</p>
                            <p>{(order.companyProvider as any).name}</p>
                            <p className={styles.quantity}>
                              Quantité&nbsp;:&nbsp;<span>{d.quantity}</span>
                            </p>
                          </div>
                          <div>
                            <p>{d.totalPrice}€</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ProductsTable
                    products={order.products}
                    orderStatus={order.status}
                    orderId={order._id}
                    isProvider
                  />
                )}
              </div>
            </>
          )}
        </div>
        {(isTablet && order?.status === 'pending') && (
          <button onClick={() => modalScanRef?.current?.open()}>
            <span className={styles.icon}>
              <BsUpcScan size={30} />
            </span>
            <span>Valider la commande</span>
          </button>
        )}
      </div>
    </>
  );
};

export default DetailOrder;
