/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Footer from '~/components/Footer';
import providers from './providers.json';
import FilterSearchParam from '~/components/FilterSearchParam';
import ProvidersTable from '~/components/table/ProvidersTable';
import styles from './providers.module.scss';

const Providers = () => {
  const [searchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);

  const salonOptions = [
    { label: 'ANM', value: 'ANM' },
    { label: 'Bazar', value: 'Bazar' },
    { label: 'Salon OUEST', value: 'Salon OUEST' },
  ];

  const thematicOptions = [
    { label: 'Exploitation du point de vente', value: 'Exploitation du point de vente' },
    { label: 'Innovation en action', value: 'Innovation en action' },
    { label: 'Maîtrise des coûts', value: 'Maîtrise des coûts' },
    { label: 'Saisonnier', value: 'Saisonnier' },
    { label: 'Bazar à service - culture', value: 'Bazar à service - culture' },
    { label: 'Equipement de la maison', value: 'Equipement de la maison' },
    { label: 'Services', value: 'Services' },
    { label: 'Frais Métiers', value: 'Frais Métiers' },
    { label: 'Frais LS', value: 'Frais LS' },
    { label: 'PGC', value: 'PGC' },
  ];

  const filteredProviders = useMemo(() => {
    const filtered = providers.filter((d: any) => {
      if (!paramsObject.exhibition && !paramsObject.thematic) return true;
      if (paramsObject.exhibition && paramsObject.thematic) return d.salon === paramsObject.exhibition && d.thematic === paramsObject.thematic;
      if (paramsObject.exhibition && !paramsObject.thematic) return d.salon === paramsObject.exhibition;
      return d.thematic === paramsObject.thematic;
    });
    return filtered;
  }, [providers, paramsObject]);

  return (
    <div className={styles.providers}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{filteredProviders.length} Fournisseur{filteredProviders.length > 1 ? 's' : ''}</h1>
          <div className={styles.filters}>
            <div className={styles.field}>
              <FilterSearchParam
                label='Salon'
                name='exhibition'
                options={salonOptions}
              />
            </div>
            <div className={styles.field}>
              <FilterSearchParam
                label='Thématique'
                name='thematic'
                options={thematicOptions}
                feminine
              />
            </div>
          </div>
        </div>
        <div className={styles.containerList}>
          <div className={styles.list}>
            <ProvidersTable providers={filteredProviders} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Providers;
