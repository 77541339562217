/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './pgc.module.scss';
import Banner from '../../components/exhibitions/Banner/index';
import Items from '../../components/exhibitions/Items/index';
import Footer from '../../components/Footer/index';
import Card from '../../components/exhibitions/Card/index';
import produitsMer from '../../assets/images/exhibitions/produits-mer.png';
import boulangerie from '../../assets/images/exhibitions/boulangerie.png';
import boucherieVolaille from '../../assets/images/exhibitions/boucherie-volaille.png';
import charcuterieFromage from '../../assets/images/exhibitions/charcuterie-fromage.png';
import fruitsLegumes from '../../assets/images/exhibitions/fruits-legumes.png';
import fleursPlantes from '../../assets/images/exhibitions/fleurs-et-plantes.png';
import epicerie from '../../assets/images/exhibitions/epicerie.png';
import fraisLs from '../../assets/images/exhibitions/frais-ls.png';
import produitsSaveurs from '../../assets/images/exhibitions/produits-u-saveurs-réception.png';
import liquides from '../../assets/images/exhibitions/liquides.png';
import entretienParfumerieHygiene from '../../assets/images/exhibitions/entretien-parfumerie-hygiene.png';
import meilleurLocal from '../../assets/images/exhibitions/meilleur-du-local.png';
import poleProjetMagasin from '../../assets/images/exhibitions/pole-projet-magasin.png';

const Pgc = () => (
  <div className={styles.pgc}>
    <Banner />
    <div className={styles.content}>
      <h1>3 halls dédiés au PGC, Frais LS & Métiers : <br></br>découvrez les thématiques</h1>
      <div className={styles.blocsContainer}>
        <div className={styles.bloc2}>
          <div className={styles.planContainer}>
            <h2>Hall 2</h2>
            <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_HALL_2_FILE_ID}`} target='_blank' rel='noreferrer'>Voir sur le plan</a>
          </div>
          <div className={styles.cardsHall2}>
            <Card img={produitsMer} className={styles.card2}>
              <h3>Produits de La Mer</h3>
              <p>Innovation et performance : présentation nouveau concept PLM</p>
            </Card>
            <Card img={boulangerie} className={styles.card2}>
              <h3>Boulangerie <br></br>Viennoiserie Pâtisserie</h3>
              <p>Performance commerce : théâtralisation des nouvelles offres</p>
            </Card>
            <Card img={boucherieVolaille} className={styles.card2}>
              <h3>Boucherie Volaille</h3>
              <p>Filières U et théâtralisation de nos concepts</p>
            </Card>
            <Card img={charcuterieFromage} className={styles.card2}>
              <h3>Charcuterie Traiteur Traditionnel / Fromage coupe</h3>
              <p>Offres, outils et animations au service du commerce</p>
            </Card>
            <Card img={fruitsLegumes} className={styles.card2}>
              <h3>Fruits & Légumes</h3>
              <p>Un rayon rationalisé vecteur d'image prix</p>
            </Card>
            <Card img={fleursPlantes} className={styles.card2}>
              <h3>Fleurs & Plantes</h3>
              <p>Les leviers pour développer</p>
            </Card>
          </div>
        </div>
        <div>
          <div className={styles.planContainer}>
            <h2>Hall 3</h2>
            <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_HALL_3_FILE_ID}`} target='_blank' rel='noreferrer'>Voir sur le plan</a>
          </div>
          <div className={styles.cardsHall3}>
            <Card img={epicerie} className={styles.card3}>
                <h3>Épicerie</h3>
                <p>Théâtralisation produits festifs fin d'année (chocolats & corbeilles)</p>
                <p>Présence fournisseurs chocolats, corbeilles et produits festifs</p>
            </Card>
            <Card img={fraisLs} className={styles.card3}>
              <h3>Frais LS</h3>
              <p>Offres festives fin d'année</p>
              <p>Innovations</p>
              <p>Filières soutenues par nos fournisseurs régionaux</p>
            </Card>
            <Card img={produitsSaveurs} className={styles.card3}>
              <h3>Produits U</h3>
              <p>Offre U Saveurs Réception fin d'année 2024</p>
              <p>Actualité produits U, dernières innovations & nos engagements</p>
            </Card>
          </div>
        </div>
        <div>
          <div className={styles.planContainer}>
            <h2>Hall 4</h2>
            <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_HALL_4_FILE_ID}`} target='_blank' rel='noreferrer'>Voir sur le plan</a>
          </div>
          <div className={styles.cardsHall4}>
            <Card img={liquides} className={styles.card4}>
              <h3>Liquides</h3>
              <p>Concept Vins : dernière création</p>
              <p>Concept Spiritueux : nouveau mobilier, arches modulables</p>
              <p>Concept Sans Alcool : offre étendue</p>
              <p>Foire aux Vins : présentation offre retenue</p>
              <p>Foire à la Bière : nouvelle théâtralisation</p>
              <p>Présence fournisseurs Vins, Bières et Spiritueux</p>
            </Card>
            <Card img={entretienParfumerieHygiene} className={styles.card4}>
              <h3>Entretien <br></br>Parfumerie & Hygiène</h3>
              <p>Prospection salon : objectif volume & rentabilité</p>
              <p>Présentation des innovations fournisseurs : objectif chiffre d'affaires</p>
            </Card>
            <Card img={meilleurLocal} className={styles.card4}>
              <h3>Le Meilleur du Local</h3>
              <p>Solutions de développement des ventes de produits locaux</p>
              <p>Présence fournisseurs emblématiques de nos démarches</p>
            </Card>
            <Card img={poleProjetMagasin} className={styles.card4}>
              <h3>Pôle Projet Magasin</h3>
              <p>Expertise et accompagnement dans {'votre projet (Chargés de projets'}</p>
              <p>Concept/Visuel, Dessin,</p>
              <p>{'Communication... )'}</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <Items />
    <Footer />
  </div>
);

export default Pgc;
