import React, {
  ForwardRefRenderFunction,
  useState, useRef, forwardRef, useImperativeHandle, useCallback, useContext, useEffect,
} from 'react';
import {
  useQueryClient,
} from '@tanstack/react-query';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { BsCheck } from 'react-icons/bs';
import { useAccountsList } from '~/hooks/account';
import { useUpdateUser } from '~/hooks/users';
import Modal from '~/lib/Modal';
import { convertDecimalTime } from '~/utils';
import { IOption } from '~/types/options';
import { IFormation, IFormationTimeSlot } from '~/types/formations';
import styles from './modal-timeslots.module.scss';
import { IUser } from '~/types/users';
import { AccountContext } from '~/accountContext';

export type ModalSubscribeFormation = {
  getTimeslots: () => string | null;
  open: () => void,
  close: () => void,
};

type ModalTimeslotsProps = {
  formation?: IFormation,
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalSubscribeFormation, ModalTimeslotsProps> = ({
  formation,
}, ref) => {
  const queryClient = useQueryClient();
  const mutationUpdateUser = useUpdateUser();
  const { user: currrentUser, users } = useContext(AccountContext);
  const modalRef = useRef<any>(null);
  const [timeslot, setTimeslot] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [step, setStep] = useState('step1');

  const {
    data: list,
  } = useAccountsList();
  const {
    days = [],
  } = list;

  // * Return timeslots for current formation depending on user
  const getTimeslotsUser = (_id: string) => {
    const user = users.find((d: IUser) => d._id === _id);
    const userFormationAllSlots = (user.formationSlots || []).map((d: any) => d._id);
    const formationSlotsId = formation?.timeslots.map((d: IFormationTimeSlot) => d._id) || [];
    const timeslotsUser = userFormationAllSlots
      .filter((idSlot: string) => formationSlotsId.includes(idSlot));
    return timeslotsUser;
  };

  useEffect(() => {
    const timeslotsUser = getTimeslotsUser(currrentUser._id);
    setTimeslot(timeslotsUser[0] || null);
    setSelectedUsers([currrentUser._id]);
  }, [currrentUser]);

  const getTimeslotsPerDay = useCallback((day: string) => (
    (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === day)
  ), [formation?.timeslots]);

  useImperativeHandle(ref, () => ({
    getTimeslots: () => timeslot,
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }), []);

  const getDay = useCallback((value: string) => days
    .find((day: IOption) => day.value === value)?.label || '', [days]);

  const handleSelectUsers = (userId: string) => {
    let updatedSelectedUsers: string[] = selectedUsers;
    // if (updatedSelectedUsers.includes(userId)) {
    //   updatedSelectedUsers = updatedSelectedUsers.filter((d: string) => d !== userId);
    // } else {
    //   updatedSelectedUsers = [...updatedSelectedUsers, userId];
    // }
    if (updatedSelectedUsers.includes(userId)) updatedSelectedUsers = [];
    else updatedSelectedUsers = [userId];
    const timeslotsUser = getTimeslotsUser(userId);
    if (timeslotsUser[0]) setTimeslot(timeslotsUser[0]);
    setSelectedUsers(updatedSelectedUsers);
  };

  const isTimeslotDisabled = useCallback((tmslot: IFormationTimeSlot) => {
    if (selectedUsers.length === 0) return false;
    const user = users.find((d: IUser) => d._id === selectedUsers[0]);
    const timeslotStart = tmslot.value;
    const timeslotEnd = tmslot.value + (tmslot.duration / 60);

    if (tmslot.availableSeats === 0) return true;

    const isDisabled = user.formationSlots.some((d: any) => {
      const start = d.value;
      const end = d.value + (d.duration / 60);

      return (d.day === tmslot.day
        && ((timeslotStart <= start && start <= timeslotEnd)
        || (timeslotStart <= end && end <= timeslotEnd)
        || (start <= timeslotStart && timeslotEnd <= end))
      );
    });
    return isDisabled;
  }, [selectedUsers, currrentUser]);

  const renderDay = (day: string) => (
    <>
      {getTimeslotsPerDay(day).length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay(day)}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay(day).map((d: IFormationTimeSlot) => (
              <button
                disabled={isTimeslotDisabled(d)}
                className={timeslot === d._id ? styles.selected : ''}
                key={`timeslots-${d._id}`}
                onClick={() => {
                  setTimeslot((state) => (state === d._id ? null : d._id));
                }}
              >
                {d.label}&nbsp;-&nbsp;
                {convertDecimalTime(d.value + (d.duration / 60))}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );

  const submitFormationSlot = async () => {
    if (!formation || !timeslot) return;

    const usersWithMutation = selectedUsers.map((_id: string) => {
      const found = users.find((d: IUser) => d._id === _id);
      let formationSlots = [];
      if (found) formationSlots = found.formationSlots.map((d: IFormationTimeSlot) => d._id);
      formationSlots.push(timeslot);
      return mutationUpdateUser.mutateAsync({
        _id,
        formationSlots,
      });
    });
    try {
      await Promise.all(usersWithMutation);
      modalRef.current.close();
      setTimeslot(null);
      setStep('step1');
      setSelectedUsers([currrentUser._id]);
      queryClient.invalidateQueries({ queryKey: ['formation', formation._id] });
    } catch (error) {
      console.log('An error has occured: ', error);
    }
  };

  return (
    <Modal width={420} ref={modalRef} handleClose={() => {
      setTimeslot(null);
      setStep('step1');
      setSelectedUsers([currrentUser._id]);
    }}>
      <div className={styles.modal}>
        <h3>{formation?.name}</h3>
        {step === 'step1' && (
          <>
            <div className={styles.stepHeader}>
              <label>Réserver cette formation pour&nbsp;:</label>
            </div>
            <div>
              <ul className={styles.users}>
                {users.map((d: IUser) => (
                  <li key={`user-${d._id}`}>
                    <div className={styles.user}>
                      <div className={styles.checkbox} onClick={() => handleSelectUsers(d._id)}>
                        {selectedUsers.includes(d._id) && <BsCheck size={30} />}
                      </div>
                      <p>{d.profile.firstName} {d.profile.lastName}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <button
              className={selectedUsers.length === 0 ? 'disabled' : ''}
              disabled={selectedUsers.length === 0}
              onClick={() => setStep('step2')}
            >Suivant</button>
          </>
        )}
        {step === 'step2' && (
          <>
            <div className={styles.stepHeader}>
              <span className={styles.icon} onClick={() => {
                setStep('step1');
                setTimeslot(null);
                setSelectedUsers([]);
              }}>
                <RiArrowGoBackFill />
              </span>
              <label>Choisir un créneau</label>
            </div>
            {renderDay('day1')}
            {renderDay('day2')}
            <div className={styles.containerActions}>
              <button onClick={() => {
                submitFormationSlot();
              }}>
                Valider ma réservation
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
