import React, {
  useState, useRef,
  MouseEvent, DragEvent, ChangeEvent,
} from 'react';
import { useController, Control } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';
import styles from './drop-input-file.module.scss';

const DropInputFile = ({
  name,
  label,
  control,
} : {
  name: string,
  label: string,
  control: Control,
}) => {
  const {
    field: {
      value,
      onChange,
    },
  } = useController({
    name,
    control,
  });
  const token = localStorage.getItem('token')?.replace('JWT ', '');
  let defaultPreviewUrl = '';
  if (value?.path) {
    defaultPreviewUrl = `${process.env.REACT_APP_API_URL}/files/public/${value.path}?token=${token}`;
  }

  const [dragActive, setDragActive] = React.useState(false);
  const [preview, setPreview] = useState(defaultPreviewUrl);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFiles = (files: any) => {
    const file = files[0];
    if (file) {
      onChange(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  // handle drag events
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPreview('');
    onChange(null);
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <div className={styles.dropInputFile}>
      <div className={`${styles.container}${preview ? ` ${styles.withPreview}` : ''}`}
        onDragEnter={handleDrag}
        onClick={onButtonClick}
        onSubmit={(e) => e.preventDefault()}
      >
        <input ref={inputRef} type="file" multiple={false} onChange={handleChange} />
        <label htmlFor="input-file-upload" className={dragActive ? styles.dragActive : '' }>
          <div>
            <p>{label}</p>
            {/* <button onClick={onButtonClick}>Upload a file</button> */}
          </div>
        </label>
        { dragActive && (
          <div className={styles.dragFile}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
        {preview && (
          <>
          <img src={preview} />
          <div className={styles.delete} onClick={handleDelete}>
            <MdDelete size={24} />
          </div>
          </>
        )}
      </div>
      <div className={styles.actions}>
        <button type='button' onClick={onButtonClick}>Changer la photo</button>
      </div>
    </div>
  );
};

export default DropInputFile;
