import React, {
  useMemo, useState,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import styles from './providers-table.module.scss';

const columns = [{
  header: 'Nom',
  accessorKey: 'name',
}, {
  header: 'Salon',
  accessorKey: 'salon',
}, {
  header: 'Thématique',
  accessorKey: 'thematic',
}, {
  header: 'Catégorie',
  accessorKey: 'folder',
}];

const ProvidersTable = (
  {
    providers,
  } : {
    providers: any[],
    pathToDetail?: string,
  },
) => {
  const isTablet = useMediaQuery('(max-width: 900px)');
  const isMobile = useMediaQuery('(max-width: 650px)');

  let tableColumns = columns;
  if (isTablet) {
    tableColumns = tableColumns.slice(0, 3);
  }

  if (isMobile) {
    tableColumns = tableColumns.slice(0, 2);
  }

  const [sorting, setSorting] = useState<any>([]);

  const data = useMemo(() => providers, [providers]);
  const table = useReactTable({
    data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <div className={styles.providersTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  <div className={styles.sortIcons}>
                    <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                    <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  <p>
                    {flexRender(
                      cell.column.columnDef.cell,
                      {
                        ...cell.getContext(),
                      },
                    )}
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProvidersTable;
