import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import RegisterForm from '../../components/form/RegisterForm';
import logoU from '../../assets/images/logo-u.png';
import logoU2 from '../../assets/images/signin-logo.png';
import imgDateSalon from '../../assets/images/date-salon.png';
import styles from './register.module.scss';

const Register = () => {
  const { type } = useParams();
  if (!type || !['collaborateur', 'magasin', 'fournisseur'].includes(type)) {
    return <Navigate to='/login' />;
  }

  return (
    <div className={styles.register}>
      <div className={styles.infos}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={logoU} alt='U Enseigne' />
          </div>
          <div>
            <img src={imgDateSalon} alt='U Enseigne' />
          </div>
        </div>
        <div className={styles.content}>
          <img src={logoU2} />
          <h2>
            Je m’inscris au Salon U Enseigne 2024
          </h2>
          <p>Pour vous inscrire merci de compléter le formulaire suivant.</p>
          <h2>
            Infos pratiques
          </h2>
          <section>
            <p>
              <span>Les horaires&nbsp;:</span><br />
              - Mercredi 19 juin: 8h - 19h<br />
              - Jeudi 20 juin: 8h - 17h
            </p>
          </section>
          <section>
            <p>
              <span>Les transports&nbsp;:</span><br />
              Parc des Expositions la Beaujoire à Nantes<br />
              Arrêt de tram : ligne 1 - Beaujoire<br />
              Bus : 75 et C6
            </p>
          </section>
          <section>
            <p>
              <span>Le plan du salon&nbsp;:</span><br />
              <a href={`${process.env.REACT_APP_API_URL}/files/download/${process.env.REACT_APP_PLAN_FILE_ID}`} download>A télécharger</a>
            </p>
          </section>
        </div>
      </div>
      <div className={styles.form}>
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;
