import React, { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import { useSignIn } from '../../hooks/account';
import getErrorAuthMessage from '../../utils/auth-helpers';
import SignInForm from '../../components/form/SignInForm';
import logoU from '../../assets/images/logo-u.png';
import imgSignin from '../../assets/images/signin-logo.png';

import styles from './signin.module.scss';

export interface CredentialsRequest {
  email: string
  password: string
}

const SignIn = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data, mutate: signIn, isSuccess, error,
  } = useSignIn();
  const errorApi: any = error;

  const handleSubmit = async (userInfo: FieldValues) => {
    try {
      await signIn(userInfo as CredentialsRequest);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('token', data.token);
      queryClient.setQueryData(['account'], data);
      navigate('/home');
    }
  }, [isSuccess]);

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error || errorApi?.response?.data);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.signin}>
      <div className={styles.logo}>
        <img src={logoU} alt= "Logo U enseigne" />
      </div>
      <div className={styles.container}>
          <div>
            <h1>Bienvenue au</h1>
            <img src={imgSignin} alt= "Bienvenue au salon U" />
          </div>
          <div className={styles.form}>
            <h1>Connexion</h1>
            <SignInForm
              submit={handleSubmit}
              errorMessage={errorMessage}
            />
            <div className={styles.create}>
              <p>{'Vous n\'avez pas de compte'}</p>
              <NavLink to='/register-types'>
                <button>
                  Créer un compte
                </button>
              </NavLink>
            </div>
          </div>
      </div>
    </div>
  );
};

export default SignIn;
