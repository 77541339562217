import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { IoClose } from 'react-icons/io5';
import styles from './modal.module.scss';

interface ModalHandler {
  open: () => void,
  close: () => void,
}

interface ModalProps {
  width?: Number,
  children: React.ReactNode,
  handleClose?:() => void,
  keepOpened?: boolean,
}

const Modal = forwardRef<ModalHandler, ModalProps>(({
  width = 310,
  children,
  handleClose,
  keepOpened = false,
}, ref) => {
  const modalRoot = document.getElementById('modal');
  const [isOpened, setIsOpened] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpened(true),
    close: () => {
      setIsOpened(false);
    },
  }));

  return (
    <>
      {(modalRoot && isOpened) && (
        <>
          {createPortal(
            <>
              <div className={styles['overlay-modal']} onClick={() => {
                if (keepOpened) return;
                setIsOpened(false);
                if (typeof handleClose === 'function') handleClose();
              }}/>
              <div className={styles.modal} style={{ maxWidth: `${width}px` }}>
                {!keepOpened && <div className={styles.close} onClick={() => {
                  setIsOpened(false);
                  if (typeof handleClose === 'function') handleClose();
                }}>
                  <IoClose size={24} />
                </div>}
                <div className={styles.content}>
                  {children}
                </div>
              </div>
            </>,
            modalRoot,
          )}
        </>
      )}
    </>
  );
});

export default Modal;
