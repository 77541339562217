import { useCallback, useMemo } from 'react';
import {
  useQueryClient,
  useMutation,
  useQuery,
  // UseMutateFunction,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  register,
  signIn,
  getAccount,
  forgotPassword,
  getAccountsList,
  resetPassword,
  addUserToAccount,
  updateAnimatorAccount,
} from '../../api/account';
import { IUser } from '~/types/users';
import { IAccount, ArgsGetAccountList } from '~/types/accounts';

const useSignIn = () => useMutation<any, unknown, {
  email: string, password: string
}, unknown>(({
  email,
  password,
}) => signIn(email, password));

const useCreateAccount = () => useMutation<any, unknown, Partial<IAccount>, unknown>(
  (data) => register(data),
);

const useForgotPassword = () => useMutation<any, unknown, {
  email: string
}, unknown>(({
  email,
}) => forgotPassword(email));

const useResetPassword = () => useMutation<any, unknown, {
  password: string,
  token: string
}, unknown>(({
  password,
  token,
}) => resetPassword(password, token));

const useSignOut = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSignOut = useCallback(() => {
    queryClient.setQueryData(['account'], null);
    localStorage.removeItem('token');
    queryClient.removeQueries();
    navigate('/login');
  }, [navigate, queryClient]);

  return onSignOut;
};

const useAccount = () => useQuery(
  ['account'],
  async (): Promise<{ account: IAccount } | null> => getAccount(),
);

const useAccountsList = (params?: ArgsGetAccountList) => {
  const query = useQuery({
    queryKey: ['account-list', params],
    queryFn: () => getAccountsList(params),
  });

  const transformedData = useMemo(() => query.data?.list || {}, [query.data]);

  return {
    ...query,
    data: transformedData,
  };
};

const useAddUserToAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (data: { accountId: string, userData: Partial<IUser> }) => addUserToAccount(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
    },
  });
};

const useUpdateAnimatorAccount = (successMessage?: string) => {
  const notify = () => toast(successMessage || '');
  const queryClient = useQueryClient();
  return useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (data: {
      region: string,
      userFunction: string,
      presences: Pick<IUser, 'presences'>
    }) => updateAnimatorAccount(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['account'] });
      if (successMessage) notify();
    },
  });
};

export {
  useSignIn,
  useCreateAccount,
  useForgotPassword,
  useResetPassword,
  useSignOut,
  useAccount,
  useAccountsList,
  useAddUserToAccount,
  useUpdateAnimatorAccount,
};
