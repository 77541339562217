import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './card.module.scss';

const Card = (
  {
    className,
    img,
    children,
    navLink,
    button,
    plan,
  }
  : { className?: string,
    img: string,
    children: JSX.Element | JSX.Element[],
    navLink?: String,
    button?: string,
    plan?: string,
  },
) => (
  <div className={className}>
    <img
      src={img}
      alt={''}
      className={styles.salonImage}
    />
    <div className={styles.salonContent}>
      {children}
      {button && (
        <NavLink to={`${navLink}`}><button>{button}</button></NavLink>
      )}
      {plan && (
        <p>
          <a href={`${process.env.REACT_APP_API_URL}/files/download/${plan}`} target='_blank' rel='noreferrer'>Télécharger le plan</a>
        </p>
      )}
    </div>
  </div>
);

export default Card;
