import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import { AccountContext, AccountProvider } from '~/accountContext';
import { useGetNotifications } from '~/hooks/notifications';
import { useAccount } from '~/hooks/account';
import ModalAnimator from '../ModalAnimator';
import styles from './layout.module.scss';
import Notifications from '../Notifications/index';

const AuthApp = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const { data: dataAccount } = useAccount();
  const account = dataAccount?.account;
  const { user } = useContext(AccountContext);
  const { data } = useGetNotifications();
  type ModalAnimatorHandler = React.ElementRef<typeof ModalAnimator>;
  const modalAnimatorRef = useRef<ModalAnimatorHandler>(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  useEffect(() => {
    if (!modalAnimatorRef.current || !user || !account) return;
    if (
      user.isAnimator
      && (
        user.presences.length !== 2
        || !user.userFunction
        || !account.region
      )
    ) modalAnimatorRef.current.open();
  }, [account, user, modalAnimatorRef.current]);

  useEffect(() => {
    const closeNotifications = (e: any) => {
      const shouldClose = !e.target.closest("[data-close-notification='false']");
      if (isNotificationsOpen && shouldClose) {
        setIsNotificationsOpen(false);
      }
    };

    window.addEventListener('click', closeNotifications);
    return () => {
      window.removeEventListener('click', closeNotifications);
    };
  }, [isNotificationsOpen]);

  const isVisibleCircle = useMemo(
    () => data?.some((notification: { isArchived: boolean }) => notification.isArchived === false),
    [data],
  );

  return (
    <div className={styles.layout}>
      <Navbar
      setIsNotificationsOpen={setIsNotificationsOpen}
      isVisibleCircle={isVisibleCircle}/>
      {isNotificationsOpen && <Notifications setIsNotificationsOpen={setIsNotificationsOpen} />}
      {user && (
        <>
          <ModalAnimator ref={modalAnimatorRef} />
          <div className={styles.pageContent} key={pathname}>{children}</div>
        </>
      )}
    </div>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => (
  <AccountProvider>
    <AuthApp>
      {children}
    </AuthApp>
  </AccountProvider>
);

export default Layout;
