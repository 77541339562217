const getErrorAuthMessage = (originalMessage: string): string => {
  let errorMessage = 'Vous n\'êtes pas autoriser à accéder à ce contenu';
  if (originalMessage === 'Request failed with status code 401' || originalMessage === 'Unauthorized') {
    errorMessage = 'Adresse e-mail et/ou mot de passe incorrects.';
  } else if (originalMessage === 'That email address is already in use.') {
    errorMessage = 'Un compte est déjà associé à cette adresse e-mail.';
  } else if (originalMessage === 'Your request could not be processed as entered. Please try again.') {
    errorMessage = 'Aucun compte n\'est déjà associé à cette adresse e-mail.';
  } else if (originalMessage === 'Password reset token is invalid or has expired.') {
    errorMessage = 'Le mot de passe a déjà été modifié.';
  } else if (originalMessage === 'Can\'t find account for this email') {
    errorMessage = 'Aucun compte ne correspond à cette adresse email.';
  }
  return errorMessage;
};

export default getErrorAuthMessage;
