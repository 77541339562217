import React, { useMemo, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCreateAccount, useAccountsList } from '../../../hooks/account';
import { useCreateCompanyFournisseur } from '~/hooks/companies';
import {
  InputText,
  StyledSelect,
  CreatableSelect,
  Checkbox,
  InputEmail,
  ErrorField,
} from '~/lib/HooksFormFields';
import { IUser } from '~/types/users';
import getErrorAuthMessage from '~/utils/auth-helpers';
import styles from './register-form.module.scss';
import PresencesForm from './PresencesForm';
import EventsForm from './EventsForm';
import CollaboratorsForm from './CollaboratorsForm';

type ICollaborators = Partial<IUser>[];

const RegisterForm = () => {
  const { type } = useParams();
  type CollaboratorsFormHandle = React.ElementRef<typeof CollaboratorsForm>;
  type EventsFormHandle = React.ElementRef<typeof EventsForm>;
  const collaboratorsForm = useRef<CollaboratorsFormHandle>(null);
  const eventsFormRef = useRef<EventsFormHandle>(null);

  const createCompanyFournisseur = useCreateCompanyFournisseur();
  const { isLoading: createCompanyLoading } = createCompanyFournisseur;

  const {
    mutate: createAccount, error, isSuccess, isLoading,
  } = useCreateAccount();

  const errorApi: any = error;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    // mode: 'onChange',
  });

  const watchExhibition = watch('exhibition');

  const {
    data: list,
  } = useAccountsList({
    type,
    exhibition: watchExhibition,
  });
  const {
    exhibitions = [],
    companies = [],
    activities = [],
    days = [],
    formations = [],
    regions = [],
    signs = [],
  } = list;

  const handleCreateCompanyFournisseur = async (value: string) => {
    if (value) {
      const data = await createCompanyFournisseur.mutateAsync({ name: value });
      if (data?.company._id) {
        setValue('company', data?.company._id);
      }
    }
  };

  const submit = async (data: FieldValues) => {
    const {
      email,
      exhibition,
      optin,
      company = null,
      sign,
      region,
      presences,
      ...mainUser
    } = data;

    let formationSlots: string[] = [];
    if (type === 'magasin' && eventsFormRef?.current) {
      formationSlots = eventsFormRef.current.getEvents() || [];
    }

    const formatedData: any = {
      email,
      // optin,
      company,
      exhibitions: [exhibition],
      sign,
      region,
      users: [{
        mainUser: true,
        ...mainUser,
        isCollaborator: type === 'collaborateur',
        presences: data.presences,
        formationSlots,
      }],
    };

    if (type !== 'collaborateur' && collaboratorsForm?.current) {
      let collaborators: ICollaborators = collaboratorsForm.current.getCollaborators() || [];
      collaborators = collaborators.map((collaborator: any) => ({
        ...collaborator,
        presences: collaborator.presences,
      }));
      formatedData.users = [
        ...formatedData.users,
        ...collaborators,
      ];
    }

    await createAccount(formatedData);
  };

  const errorApiMessage = useMemo(() => {
    if (!error) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error || errorApi?.response?.data);
    return msg;
  }, [errorApi]);

  const optionsCompanies = useMemo(
    () => companies.filter((d: any) => d.type === type),
    [type, companies],
  );

  return (
    <div className={styles.registerForm}>
      <h2>Je remplis mes informations personnelles</h2>
      {type === 'magasin' && (
        <>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <StyledSelect
                label="Région"
                name='region'
                control={control}
                options={regions}
                rules={{
                  required: 'Ce champs est obligatoire',
                }}
              />
              {errors?.region && <ErrorField message={errors?.region?.message} />}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.containerField}>
              <StyledSelect
                label="Enseigne"
                name='sign'
                control={control}
                options={signs}
                rules={{
                  required: 'Ce champs est obligatoire',
                }}
              />
              {errors?.sign && <ErrorField message={errors?.sign?.message} />}
            </div>
          </div>
        </>
      )}
      {type !== 'magasin' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              label="Salon"
              name='exhibition'
              control={control}
              options={exhibitions}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.exhibition && <ErrorField message={errors?.exhibition?.message} />}
          </div>
        </div>
      )}
      {type !== 'collaborateur' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            {type === 'fournisseur' && (
              <>
                <span>Société<sup>*</sup></span>
                <p style={{ marginBottom: '10px' }}>
                  Saisissez les premières lettres de votre société puis sélectionnez dans la liste.
                </p>
                <CreatableSelect
                  // label="Société"
                  name='company'
                  disabled={createCompanyLoading}
                  control={control}
                  options={optionsCompanies}
                  isClearable
                  createLabel='Créer la société: '
                  onCreateOption={(value: string) => handleCreateCompanyFournisseur(value)}
                  rules={{
                    required: 'Ce champs est obligatoire',
                  }}
                />
              </>
            )}
            {type === 'magasin' && (
              <>
                <span>Magasin<sup>*</sup></span>
                <p>
                  Saisissez les premières lettres du magasin puis
                  sélectionnez-le dans la liste.
                </p>
                <p className={styles.paragraphMailto}>
                (ex. : Hyper U AIZENAY). <a href="mailto:srm.cnb@systeme-u.fr">Je ne trouve pas mon magasin.</a>
                </p>
                <StyledSelect
                  // label="Magasin"
                  name='company'
                  control={control}
                  options={optionsCompanies}
                  isSearchable
                  rules={{
                    required: 'Ce champs est obligatoire',
                  }}
                />
              </>
            )}
            {errors?.company && <ErrorField message={errors?.company?.message} />}
          </div>
        </div>
      )}
      {type === 'collaborateur' && (
        <div className={styles.row}>
          <div className={styles.containerField}>
            <StyledSelect
              label="Activité"
              name='activity'
              control={control}
              options={activities}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.activity && <ErrorField message={errors?.activity?.message} />}
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            label="Prénom"
            name='profile.firstName'
            control={control}
            rules={{
              required: 'Ce champs est obligatoire',
            }}
          />
        </div>
        <div className={styles.containerField}>
          <InputText
            label="Nom"
            name='profile.lastName'
            control={control}
            rules={{
              required: 'Ce champs est obligatoire',
            }}
          />
        </div>
      </div>
      <div className={styles.row}>
        {type !== 'fournisseur' && (
          <div className={styles.containerField}>
            <InputText
              label="Fonction"
              name='userFunction'
              control={control}
              maxlength={20}
              rules={{
                required: 'Ce champs est obligatoire',
                validate: (value: string) => {
                  if (value.length <= 20) return true;
                  return 'La fonction ne doit pas faire plus 20 caractères';
                },
              }}
            />
            {errors?.userFunction && <ErrorField message={errors?.userFunction?.message} />}
          </div>
        )}
        <div className={styles.containerField}>
          <InputEmail
            label="E-mail"
            name='email'
            placeholder='adresse@mail.fr'
            control={control}
            required
            requiredMessage='Ce champs est obligatoire'
          />
          {errors?.email && <ErrorField message={errors?.email?.message} />}
        </div>
      </div>
      {days.length > 0 && (
        <PresencesForm
          control={control}
          setValue={setValue}
          from='register'
          days={days}
          errors={errors.presences || []}
        />
      )}
      {type === 'magasin' && formations.length > 0 && (
        <EventsForm ref={eventsFormRef} />
      )}
      {(type !== 'collaborateur') && (
        <div className={styles.collaborators}>
          <CollaboratorsForm ref={collaboratorsForm} />
        </div>
      )}
      <div className={styles.optin}>
        <div className={styles.checkbox}>
          <Checkbox
            name='optin'
            control={control}
            defaultValue={false}
            rules={{
              required: true,
            }}
          />
        </div>
        <p>
          <strong>
            J’accepte de recevoir du courriel de la part de l’organisateur
            dans le cadre de mon inscription.
          </strong>
          <br />
          Conformément à l’article 27 de la loi “Informatique et Libertés” du 6 janvier 1978,
          les champs d’information que vous remplissez sur le site sont nécessaires
          à l’enregistrement et au traitement de vos demandes.
          Nous ne les transmettrons pas à des tiers.
          Voir notre politique de gestion des données personnelles.
        </p>
      </div>
      <p><sup>*</sup>Mentions obligatoires</p>
      {errorApiMessage && <ErrorField message={errorApiMessage} />}
      <div className={styles.containerBtn}>
        <button disabled={!isValid || isLoading} onClick={handleSubmit(submit)}>
          {'Je m\'inscris'}
        </button>
      </div>
      {isSuccess && <p className={styles.success}>
        Votre compte a été créé !<br />
        Un lien permettant de créer votre mot de passe vous a été envoyé.
      </p>}
      <div className={styles.mention}>
        <p>
          A la suite de votre inscription, vous pourrez télécharger votre badge d’entrée.<br />
          <span>Vous rencontrez un problème lors de l’inscription ? Nous contacter à l’adresse <a href="mailto:srm.cnb@systeme-u.fr">srm.cnb@systeme-u.fr</a></span>
        </p>
      </div>
    </div>
  );
};

export default RegisterForm;
