import React, { useMemo } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useController, Control } from 'react-hook-form';
import styles from './checkbox.module.scss';

interface CheckboxProps {
  name: string,
  control: Control<any>,
  label?: string | null,
  defaultValue?: boolean,
  inline?: boolean,
  rules?: any | null,
  style?: 'secondary',
  disabled?: boolean,
}

const Checkbox = ({
  name,
  control,
  label,
  inline = true,
  defaultValue = false,
  rules,
  style,
  disabled = false,
}: CheckboxProps) => {
  const { field: { value, onChange } } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const classname = useMemo(() => {
    let className = styles.checkbox;
    if (inline) className += ` ${styles.inline}`;
    if (style) className += ` ${styles[style]}`;
    if (!value) className += ` ${styles.notchecked}`;
    if (disabled) className += ` ${styles.disabled}`;
    return className;
  }, [style, value]);

  return (
    <div className={classname}>
      <div className={styles.container}>
        <div className={styles.square} onClick={() => {
          if (!disabled) onChange(!value);
        }}>
          {value && <BsCheck size={30} />}
        </div>
      </div>
      {label && <label onClick={() => {
        if (!disabled) onChange(!value);
      }}>{label}</label>}
    </div>
  );
};

export default Checkbox;
