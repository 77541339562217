import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICompany } from '~/types/companies';
import { useAccount } from '~/hooks/account';
import { useGetProducts } from '~/hooks/products';
import ProductsTable from '~/components/table/fournisseurs/ProductsTable';
import Pagination from '~/components/Pagination';
import Footer from '~/components/Footer';
import styles from './prodivers-products.module.scss';

const ProductsProvider = () => {
  type PaginationHandle = React.ElementRef<typeof Pagination>;
  const paginationRef = useRef<PaginationHandle>(null);
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } = account || {};
  const companyID = companyAccount ? (companyAccount as ICompany)._id : undefined;
  const [searchParams, setSearchParams] = useSearchParams();
  const { ...paramsObject } = Object.fromEntries(searchParams);
  const { data: dataProducts = {} } = useGetProducts({
    ...paramsObject,
    company: companyID,
  });
  const { products = [], meta = {} } = dataProducts;

  const handleSorting = (sort: any) => {
    if (!sort) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', sort.id.replace('_', '.'));
    params.set('order', sort.desc ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const handleChangePage = (page: number) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', page.toString());
    setSearchParams(params);
  };

  return (
    <div className={styles.productsProvider}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>{meta?.total} Offre{meta?.total > 1 && 's'}</h1>
        </div>
        <div className={styles.containerList}>
          <div className={styles.list}>
            <ProductsTable
              products={products}
              handleSorting={handleSorting}
            />
          </div>
          <div className={styles.footer}>
            {meta?.total > 0 && <Pagination
              ref={paginationRef}
              pages={meta?.pages || 0}
              defaultPage={parseInt(searchParams.get('page') || '1', 10)}
              handleChange={handleChangePage}
            />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsProvider;
