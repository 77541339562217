/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import {
  createCompanyFournisseur,
} from '../../api/companies';

const useCreateCompanyFournisseur = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { name: string }) => createCompanyFournisseur(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['account-list'] });
    },
  });
};

export {
  useCreateCompanyFournisseur,
};
