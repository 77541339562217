/* eslint-disable max-len */
import React, {
  useRef, useMemo, useEffect, useState,
  useCallback,
} from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  Row,
} from '@tanstack/react-table';
import { DataHandleOrders } from '~/types/orders';
import { useHandleOrder } from '~/hooks/orders';
import { executePromisesSequentially } from '~/utils';
import InputProductToCart from './InputProductToCart';
import styles from './products-table.module.scss';
import { IProduct } from '~/types/products';

//* Products magasins

const columns = [{
  header: 'Nom de l\'offre',
  accessorKey: 'name',
}, {
  header: 'EAN produit',
  accessorKey: 'ean',
}, {
  header: 'Fournisseur',
  accessorKey: 'company.name',
}, {
  header: 'Prix de cession salon',
  accessorKey: 'price',
  cell: ({ getValue }: any) => {
    const price = getValue() || 0;
    return <>{price}€</>;
  },
}, {
  header: 'Remise',
  accessorKey: 'discount',
  cell: (row: any) => {
    const discount = row.getValue() || '';
    return <span className={styles.discount}>{(discount || 0).toFixed(2)}€</span>;
  },
}, {
  header: 'Panier',
  accessorKey: 'cart',
  cell: (d: any) => {
    const product: IProduct = d.row.original;
    return (
      <span key={product._id}>
        <InputProductToCart
          product={product}
          onChange={d.handleChangeProductQuantity}
        />
      </span>
    );
  },
}];

const ProductsTable = (
  {
    products,
    handleSorting,
    pathToDetail,
    handleChangeProductQuantity,
  } : {
    products: IProduct[],
    handleSorting: any,
    pathToDetail?: string,
    handleChangeProductQuantity: any,
  },
) => {
  const [sorting, setSorting] = useState<any>([]);
  const navigate = useNavigate();

  const data = useMemo(() => products, [products]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useEffect(() => {
    handleSorting(sorting[0]);
  }, [sorting]);

  const onRowClick = (row: Row<IProduct>) => {
    navigate(pathToDetail ? `${pathToDetail}/${row.original._id}` : `/produits/${row.original._id}`);
  };

  return (
    <div className={styles.productsTable}>
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={styles[header.column.id]}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </span>
                  <div className={styles.sortIcons}>
                    <span className={header.column.getIsSorted() === 'asc' ? styles.active : ''}><IoChevronUpOutline /></span>
                    <span className={header.column.getIsSorted() === 'desc' ? styles.active : ''}><IoChevronDownOutline /></span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} onClick={() => onRowClick(row)}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles[cell.column.id]}>
                  {cell.column.id === 'cart' ? (
                    <>
                      {flexRender(
                        cell.column.columnDef.cell,
                        {
                          ...cell.getContext(),
                          handleChangeProductQuantity,
                        },
                      )}
                    </>
                  ) : (
                    <p>
                      {flexRender(
                        cell.column.columnDef.cell,
                        {
                          ...cell.getContext(),
                        },
                      )}
                    </p>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ResponsivProductsTable = (
  {
    products,
    handleSorting,
    pathToDetail,
  } : {
    products: IProduct[],
    handleSorting: any,
    pathToDetail?: string,
  },
) => {
  const isTablet = useMediaQuery('(max-width: 1180px)');
  const timer = useRef<any>(null);

  const [submittedProducts, setSubmittedProducts] = useState<any>({});
  const {
    mutateAsync: handleOrder,
  } = useHandleOrder('__productName__ a été ajouté à votre panier');

  const submit = async () => {
    if (Object.values(submittedProducts).length === 0) return;
    const handleOrders: (() => void)[] = Object.values(submittedProducts)
      .map((payload: any) => () => handleOrder(payload));

    // const responses =
    try {
      await executePromisesSequentially(handleOrders);
      setSubmittedProducts({});
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    timer.current = setTimeout(() => submit(), 300);
    return () => {
      clearTimeout(timer.current);
    };
  }, [submittedProducts]);

  const handleChangeProductQuantity = useCallback(({
    account, productId, quantity, user,
  } : DataHandleOrders) => {
    const updatedSubmittedProduct = JSON.parse(JSON.stringify(submittedProducts));
    updatedSubmittedProduct[productId] = {
      account,
      productId,
      quantity,
      user,
    };
    setSubmittedProducts(updatedSubmittedProduct);
  }, [submittedProducts]);

  return (
    <>
      {isTablet ? (
        <ul className={styles.listTablet}>
          {products.map((product: IProduct) => (
            <li key={`product-${product._id}`}>
              <NavLink to={`/produits/${product._id}`} className={styles.product}>
                <p className={styles.name}>{product.name}</p>
                <p>{(product?.company as any)?.name}</p>
                <div className={styles.shoppingCart}>
                  <p className={styles.price}>
                    Prix cession salon <span>{product.price}€</span>
                  </p>
                  <InputProductToCart
                    product={product}
                    onChange={handleChangeProductQuantity}
                  />
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        <ProductsTable
          products={products}
          handleSorting={handleSorting}
          pathToDetail={pathToDetail}
          handleChangeProductQuantity={handleChangeProductQuantity}
        />
      )}
    </>

  );
};

export default ResponsivProductsTable;
