import React, { createContext, useState } from 'react';
import { IUser } from './types/users';

export const AccountContext = createContext<any>(null);

export const AccountProvider = ({ children } : { children : any }) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [user, setUser] = useState<IUser>();

  return (
    <AccountContext.Provider value={{
      users,
      user,
      setUsers,
      setUser,
    }}>
      {children}
    </AccountContext.Provider>
  );
};
