import React, {
  useRef, useMemo, useContext,
} from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { NavLink, useParams } from 'react-router-dom';
import {
  useGetFormation,
  useUpdateFormation,
} from '~/hooks/formations';
import { ErrorField } from '~/lib/HooksFormFields';
import FormationForm from '~/components/form/FormationForm';
import styles from './edit-formation.module.scss';
import { AccountContext } from '~/accountContext';

const EditFormation = () => {
  const { user: currrentUser } = useContext(AccountContext);
  const { id } = useParams();
  const formRef = useRef<any>();
  // Get
  const {
    data: formation,
    error: getError,
    isLoading: isGetLoading,
  } = useGetFormation(id || '', !!id);
  // Update
  const {
    mutate: updateFormation,
    error: putError,
    isLoading: isPutLoading,
  } = useUpdateFormation('Les modifications ont été prise en compte');
  const isLoading: any = (id && isGetLoading) || isPutLoading;
  const errorApi: any = getError || putError;

  const handleSubmit = async () => {
    if (!id) return;
    const data = await formRef.current.submit();

    // Convert to FormData
    const formdata = new FormData();
    Object.entries(data).forEach(([key, value] : any) => {
      if (Array.isArray(value) && value.length > 0) {
        value.forEach((k: any) => {
          formdata.append(`${key}[]`, ['number', 'string'].includes(typeof k) ? k : JSON.stringify(k));
        });
      } else if (Array.isArray(value) && value.length === 0) {
        formdata.append(`${key}[]`, '');
      } else if ( // if object
        key !== 'picture'
        && typeof value === 'object'
        && !Array.isArray(value)
        && value !== null
      ) {
        formdata.append(key, JSON.stringify(value));
      } else if (key === 'picture' && value && !value._id) {
        formdata.append(key, value);
      } else if (value || value === null) {
        formdata.append(key, value);
      }
    });

    formdata.append('editBy', currrentUser._id);
    updateFormation(
      { _id: id, updatedData: formdata },
    );
    // if (!id) createFormation(formdata);
    // else {
    //   formdata.append('editBy', currrentUser._id);
    //   updateFormation(
    //     { _id: id, updatedData: formdata },
    //   );
    // }
  };

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = errorApi?.response?.data?.error || errorApi?.response?.data || 'Une erreur est survenue';
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.editFormation}>
      <div className={styles.content}>
        <div className={styles.header}>
          <NavLink className={styles.backward} to='/animateur/formations'>
            <RiArrowGoBackFill />
            Retour
          </NavLink>
          <div className={styles.containerActions}>
            <button disabled={isLoading} onClick={handleSubmit}>
              Sauvegarder
            </button>
            {errorMessage && (
              <div className={styles.error}>
                <ErrorField message={errorMessage} />
              </div>
            )}
          </div>
        </div>
        {(!id || (id && formation)) && (
          <FormationForm ref={formRef} formation={formation} />
        )}
        <div className={styles.containerActions}>
          <button
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Sauvegarder
          </button>
          {errorMessage && (
            <div className={styles.error}>
              <ErrorField message={errorMessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditFormation;
