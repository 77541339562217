import React, {
  ForwardRefRenderFunction,
  useRef, useState, useImperativeHandle, forwardRef,
} from 'react';
import { toast } from 'react-toastify';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';
import { useAccountsList } from '~/hooks/account';
import { IUser } from '~/types/users';

import styles from './collaborators-form.module.scss';
import CollaboratorForm from './CollaboratorForm';

export type CollaboratorsFormHandle = {
  getCollaborators: () => ICollaborators;
};

type CollaboratorsFormProps = {};

type ICollaborators = Partial<IUser>[];

const CollaboratorsForm: ForwardRefRenderFunction<
CollaboratorsFormHandle, CollaboratorsFormProps> = (_, ref) => {
  type CollaboratorFormHandler = React.ElementRef<typeof CollaboratorForm>;
  const collaboratorFormRef = useRef<CollaboratorFormHandler>(null);
  const { type } = useParams();
  const [collaborators, setCollaborators] = useState<ICollaborators>([]);
  const [isOpened, setIsOpened] = useState(false);

  const {
    data: list,
  } = useAccountsList();
  const {
    days = [],
  } = list;

  useImperativeHandle(ref, () => ({
    getCollaborators: () => collaborators,
  }), [collaborators]);

  const submit = async () => {
    const newCollaborator = await collaboratorFormRef.current?.getCollaborator();
    if (newCollaborator) {
      setCollaborators((state) => [...state, newCollaborator]);
      toast('Un collaborateur a été ajouté à votre compte');
    }
  };

  const handleDeleteCollaborator = (index: number) => {
    const updatedCollaborators = JSON.parse(JSON.stringify(collaborators));
    updatedCollaborators.splice(index, 1);
    setCollaborators(updatedCollaborators);
  };

  return (
    <div className={styles.collaborators}>
      <h2 onClick={() => setIsOpened((state) => !state)}>
        <span>Je souhaite inscrire un collaborateur</span>
        <button className={`invisible ${styles.icon}`} >
          { isOpened ? <FaChevronUp size={22} /> : <FaChevronDown size={22} />}
        </button>
      </h2>
      {isOpened && (
        <>
          {collaborators.length > 0 && (
            <div className={styles.listCollaborators}>
              {collaborators.map((collaborator: Partial<IUser>, index: number) => (
                <div className={styles.collaborator} key={`collaborator-${index}`}>
                  <div className={styles.content}>
                    <p>
                      {collaborator.profile?.firstName} {collaborator.profile?.lastName}
                      {collaborator.userFunction && (
                        <span> | {collaborator.userFunction}</span>
                      )}
                    </p>
                    <div className={styles.presence}>
                      {(collaborator.presences || []).map((d: any, i: number) => (
                        <p key={`day-${days[i].value}`}>
                          {days[i].label}&nbsp;:
                          {d.present ? ' sera présent' : ' ne sera pas présent'}
                          {d.lunch ? ' et déjeunera' : ' et ne déjeunera pas'}
                        </p>
                      ))}
                    </div>
                  </div>
                  <button onClick={() => handleDeleteCollaborator(index)} className={`invisible ${styles.delete}`}>
                    <MdDeleteOutline size={30} />
                  </button>
                </div>
              ))}
            </div>
          )}
          {type && (
            <>
              <CollaboratorForm
                ref={collaboratorFormRef}
                type={type}
              />
              <button
                onClick={submit}
              >
                {'Valider l\'ajout du collaborateur'}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default forwardRef(CollaboratorsForm);
