import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url: string, auth = true, enabled = true) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<Boolean | null>(null);
  const [error, setError] = useState<any>(null);

  const fetchFunction = async (src?: any) => {
    const source = src || axios.CancelToken.source();

    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (auth) {
      const token = localStorage.getItem('token');
      headers.Authorization = token;
    }

    try {
      const response = await axios.get(url, {
        headers,
        cancelToken: source.token,
      });
      setLoading(false);
      if (response.data) setData(response.data);
    } catch (err: any) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    if (!enabled) return () => {};
    setLoading(true);
    setData(null);
    setError(null);

    const headers: any = {
      'Content-Type': 'application/json',
    };

    if (auth) {
      const token = localStorage.getItem('token');
      headers.Authorization = token;
    }
    const source = axios.CancelToken.source();
    fetchFunction(source);
    return () => {
      source.cancel();
    };
  }, [enabled, url]);

  return {
    data, isLoading: loading, error, get: fetchFunction,
  };
};

export default useFetch;
