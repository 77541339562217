import React, { useContext } from 'react';
import { AccountContext } from '~/accountContext';
import { useAccount } from '~/hooks/account';
import style from './banner.module.scss';
import dateSalon from '../../../assets/images/date-salon.png';
import salonBazar from '../../../assets/images/exhibitions/salon-bazar.png';
import salonOuest from '../../../assets/images/exhibitions/salon-ouest.png';

const Banner = () => {
  const { user } = useContext(AccountContext);
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } : any = account || {};

  return (
    <div className={style.banner}>
      <img src={salonBazar} alt="Salon Bazar 2024" className={style.disappearingImage} />
      {(
        companyAccount?.address?.region === 'ouest'
        || companyAccount?.type === 'fournisseur'
        || user.isCollaborator
      ) && (
        <img src={salonOuest} alt="Salon Ouest 2024" className={style.disappearingImage}/>
      )}
      <img src={dateSalon} alt="dates du salon" />
    </div>
  );
};

export default Banner;
