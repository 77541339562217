/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { getNotifications, archiveNotification } from '../../api/notifications';

function useGetNotifications() {
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => getNotifications(),
  });
}

const useArchiveNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (_id: string) => archiveNotification(_id),
    onSuccess: async ({ notification }) => {
      const notifications: any[] = queryClient.getQueryData(['notifications']) || [];
      const updatedNotifications: any[] = notifications.map((d: any) => {
        if (d._id === notification._id) return notification;
        return d;
      });
      queryClient.setQueryData(['notifications'], updatedNotifications);

      // queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export {
  useGetNotifications,
  useArchiveNotification,
};
