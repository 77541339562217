import React, {
  useEffect, useMemo, useState, forwardRef, useImperativeHandle,
  MouseEvent,
  Ref,
  // useCallback,
} from 'react';
import {
  useForm, useFieldArray, useController, Control,
} from 'react-hook-form';
import styles from './input-timeslots.module.scss';
import { IFormationTimeSlot } from '~/types/formations';
import { convertDecimalTime } from '~/utils';
import { IOption } from '~/types/options';

interface InputTimeslotHandler {
  appendSlot: () => void,
  resetSlot: () => void,
  getTimeslot: () => void,
}

type TimeslotProp = IFormationTimeSlot;

type DefaultTimeslotsProp = Omit<TimeslotProp, '_id'>;

const SelectSlot = ({
  name,
  control,
  duration,
  day,
  defaultStart,
  timeslots = [],
  isDisabled = false,
  handleDelete,
} : {
  name: string,
  control: Control,
  duration: number,
  day: string,
  defaultStart: DefaultTimeslotsProp,
  timeslots: any[],
  isDisabled?: boolean,
  handleDelete: () => void,
}) => {
  const { field: { onChange } } = useController({
    name,
    control,
  });
  const [open, setOpen] = useState(false);
  const [selectTimeslot, setSelectionTimeslot] = useState(defaultStart);

  useEffect(() => {
    const fnClose = (e: any) => {
      const preventClose = e.target.getAttribute('data-close') === 'false';
      if (!preventClose) setOpen(false);
    };
    if (open) document.addEventListener('mousedown', fnClose);
    return () => {
      document.removeEventListener('mousedown', fnClose);
    };
  }, [open]);

  const handleSelectTimeslot = (d: TimeslotProp) => {
    if (isDisabled) return;
    setSelectionTimeslot({ ...d, duration, day });
    onChange({ ...d, duration, day });
    setOpen(false);
  };

  const endSlot = useMemo(() => (
    convertDecimalTime(selectTimeslot.value + (selectTimeslot.duration / 60))
  ), [selectTimeslot]);

  return (
    <div className={`${styles.selectSlot}${isDisabled ? ` ${styles.disabled}` : ''}`}>
      <div className={styles.timeslot}>
        <p onClick={(e: MouseEvent<HTMLParagraphElement>) => {
          e.preventDefault();
          setOpen(true);
        }}>{selectTimeslot.label}</p>
        <p>{endSlot}</p>
      </div>
      {open && (
        <div className={styles.dropdown} >
          <div className={styles.options}>
            {timeslots.map((timeslot) => (
              <p data-close='false' key={`time-${timeslot.value}`} onClick={() => handleSelectTimeslot(timeslot)}>{timeslot.label}</p>
            ))}
          </div>
          <div className={styles.delete}>
            <p
              data-close='false'
              onClick={() => {
                handleDelete();
                setOpen(false);
              }}
            >
              Supprimer le créneau
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const InputTimeslot = (
  {
    day,
    name,
    duration = 15,
    defaultTimeslots = [],
  } : {
    day: string,
    name: string,
    duration?: number,
    defaultTimeslots: DefaultTimeslotsProp[]
  },
  ref: Ref<InputTimeslotHandler>,
) => {
  const { control, getValues } = useForm({
    defaultValues: {
      [name]: defaultTimeslots,
    },
  });
  const {
    fields, append, remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name, // unique name for your Field Array
  });

  const options = useMemo(() => {
    const limit = day === 'day1' ? 19 : 17;
    const step = 1 / (60 / 15);
    // const step = 1 / (60 / duration);
    const opts = [8];
    let i = 0;
    while (opts[opts.length - 1] < limit) {
      opts[i + 1] = opts[i] + step;
      i += 1;
    }
    const timeslots: any[] = [];
    opts.forEach((decimalTime) => {
      const time = convertDecimalTime(decimalTime);
      timeslots.push({
        label: time,
        value: decimalTime,
      });
    });
    return timeslots;
  }, []);

  useImperativeHandle(ref, () => ({
    resetSlot: () => {
      remove();
    },
    appendSlot: () => {
      const fieldsValue = getValues(name);
      const lastField = fieldsValue.reduce((prevValue: any, currentValue) => {
        if (prevValue) {
          const end = currentValue.value + (currentValue.duration / 60);
          const prevEnd = prevValue.value + (prevValue.duration / 60);
          if (prevEnd <= end) return currentValue;
          return prevValue;
        }
        return currentValue;
      }, null);

      const nextOption = options
        .filter((d: IOption) => d.value >= (lastField.value + (lastField.duration / 60)))[0];

      if (!nextOption) return;

      append({
        label: nextOption.label,
        value: nextOption.value,
        duration,
        day,
      });
    },
    getTimeslot: () => {
      const values = getValues(name);
      const formatedValues: Omit<TimeslotProp, '_id'>[] = values.map((d: Omit<TimeslotProp, '_id'>) =>
        // const end = d.value + (d.duration / 60);
        // eslint-disable-next-line implicit-arrow-linebreak
        ({
          ...d,
          // label: `${d.label} - ${convertDecimalTime(end)}`,
        }));
      return formatedValues;
    },
  }));

  return (
    <>
      {fields.length > 0 && (
        <div className={styles.timeslots}>
          {fields.map((field, index) => (
            <div key={field.id}>
              <SelectSlot
                name={`${name}.${index}`}
                day={day}
                duration={duration}
                control={control}
                isDisabled={field.availableSeats !== field.seats}
                defaultStart={field}
                // defaultStart={options[index] ? {
                //   ...options[index],
                //   duration,
                //   day,
                // } : null}
                timeslots={options}
                handleDelete={() => remove(index)}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default forwardRef(InputTimeslot);
