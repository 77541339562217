import React, {
  useRef, forwardRef, useImperativeHandle,
  Ref,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useController, useForm } from 'react-hook-form';
import { IoClose } from 'react-icons/io5';
import { IFormation, IFormationTimeSlot } from '~/types/formations';
import {
  InputText,
  InputNumber,
  Checkbox,
  StyledSelect,
  DropInputFile,
  TextEditor,
  ErrorField,
} from '~/lib/HooksFormFields';
import { useGetFormationsList } from '~/hooks/formations';
import InputDuration from './InputDuration';
import InputTimeslot from './InputTimeslot';
import Modal from '~/lib/Modal';
import styles from './formation-form.module.scss';
import { IOption } from '~/types/options';
import { IUser } from '~/types/users';

interface FormationFormHandler {
  submit: () => void,
}

const defaultDuration = 60;
const individualMeetings = ['rdv-emu-u-location', 'rdv-rh-pdv', 'rdv-u-techno', 'rdv-pilotage-bazar'];

const FormationForm = (
  { formation } : { formation?: IFormation | null },
  ref: Ref<FormationFormHandler>,
) => {
  const modalRef = useRef<any>();
  const { data: list } = useGetFormationsList();
  const {
    days = [],
    regions = [],
    formationTypes = [],
    formationCategories = [],
    formationLocations = [],
    speakers: listSpeakers,
  } = list || {};
  const inputTimeslotDay1Ref = useRef<any>(null);
  const inputTimeslotDay2Ref = useRef<any>(null);
  const {
    control: controlCheckboxesDays,
    watch: watchCheckbox,
  } = useForm({
    defaultValues: {
      'checkbox-day1': (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === 'day1').length >= 1,
      'checkbox-day2': (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === 'day2').length >= 1,
    },
  });

  const watchDay1 = watchCheckbox('checkbox-day1');
  const watchDay2 = watchCheckbox('checkbox-day2');

  const {
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...formation,
    },
  });
  const { field: { value: speakers, onChange: onChangeSpeakers } } = useController({
    name: 'speakers',
    control,
  });

  const watchDuration = watch('duration');
  const watchType = watch('type');

  useEffect(() => {
    if (days.length > 2) {
      console.error('FormationForm can\'t handle more that 2 days');
    }
  }, [days]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
      } else {
        return null;
      }

      let timeslots: any = [];
      const timeslotsDay1 = inputTimeslotDay1Ref.current?.getTimeslot();
      const timeslotsDay2 = inputTimeslotDay2Ref.current?.getTimeslot();

      if (watchDay1) {
        timeslots = [...timeslots, ...timeslotsDay1];
      }
      if (watchDay2) {
        timeslots = [...timeslots, ...timeslotsDay2];
      }
      if (data?.type && !individualMeetings.includes(data?.type)) {
        data.region = '';
      }

      return {
        ...data,
        speakers: (data?.speakers || []).map((speaker: IUser) => speaker._id),
        timeslots,
      };
    },
  }), [watchDay1, watchDay2]);

  const handleSelectSpeakers = (speaker: IUser, action = 'add') => {
    if (action === 'add' && (watchType && individualMeetings.includes(watchType)) && (speakers || []).length >= 1) return;
    let arrSpeakers = speakers || [];
    if (action === 'add') {
      arrSpeakers = [...arrSpeakers, speaker];
    } else if (action === 'delete') {
      arrSpeakers = arrSpeakers.filter((d: IUser) => d._id !== speaker._id);
    }
    onChangeSpeakers(arrSpeakers);
    modalRef.current.close();
  };

  useEffect(() => {
    if (watchType && individualMeetings.includes(watchType)) {
      setValue('seats', '1');
      onChangeSpeakers((speakers && speakers.length > 1) ? [speakers[0]] : []);
    }
  }, [watchType]);

  const listNotSelectedSpeakers = useMemo(() => {
    const selectedSpeakerIds = speakers?.map((d: IUser) => d._id);
    return (listSpeakers || [])
      .filter((d: IUser) => !selectedSpeakerIds?.includes(d._id))
      .sort((a: IUser, b: IUser) => `${a.profile.lastName} ${a.profile.firstName}`.localeCompare(`${b.profile.lastName} ${b.profile.firstName}`));
  }, [listSpeakers, speakers]);

  const getDefaultTimeslots = useCallback(
    (day: string) => (formation?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === day),
    [formation?.timeslots],
  );

  const isCheckboxDayDisabled = (timeslots: IFormationTimeSlot[]) => {
    const isDisabled = timeslots.some((d) => d.seats !== d.availableSeats);
    return isDisabled;
  };

  return (
    <div className={styles.container}>
      <form>
        <div className={styles.row}>
          <div className={`${styles.containerField} ${styles.name}`}>
            <InputText
              name='name'
              placeholder='Nom de la formation'
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.name?.message && <ErrorField message={errors?.name?.message} />}
          </div>
        </div>
        <div className={`${styles.row} ${styles.selectFields}`}>
          <div className={styles.containerField}>
            <StyledSelect
              name='type'
              label='Type de formation'
              control={control}
              options={formationTypes}
              handleChange={(option) => {
                if (option?.value === 'conference') {
                  setValue('location', formationLocations[7].value);
                }
              }}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.type?.message && <ErrorField message={errors?.type?.message} />}
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='category'
              label='Catégorie de la formation'
              control={control}
              options={formationCategories}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.category?.message && <ErrorField message={errors?.category?.message} />}
          </div>
          <div className={styles.containerField}>
            <StyledSelect
              name='location'
              label='Lieu de la formation'
              control={control}
              options={formationLocations}
              isDisabled={watchType === 'conference'}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.location?.message && <ErrorField message={errors?.location?.message} />}
          </div>
          {(regions && watchType && individualMeetings.includes(watchType)) && (
            <div className={styles.containerField}>
              <StyledSelect
                name='region'
                label='Région'
                control={control}
                options={regions}
              />
            </div>
          )}
        </div>
        <div className={styles.row}>
          <Modal ref={modalRef}>
            <div className={styles.selectSpeakers}>
              <h1>Animateurs</h1>
              <div className={styles.listSpeakers}>
                {listNotSelectedSpeakers.map((speaker: IUser, index: number) => (
                  <div
                    key={`speaker-${speaker._id}-${index}`}
                    className={styles.speaker}
                    onClick={() => handleSelectSpeakers(speaker)}
                  >
                    <p className={styles.name}>
                      {speaker.profile.firstName} {speaker.profile.lastName}
                    </p>
                    <p className={styles.function}>{speaker.userFunction}</p>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <div>
            <label>Animateurs</label>
          </div>
          <ul className={styles.speakers}>
            {(speakers || []).map((speaker: any) => (
              <li
                key={`speaker-${speaker._id}`}
              >
                <div className={styles.speaker}>
                  <div className={styles.close} onClick={() => handleSelectSpeakers(speaker, 'delete')}>
                    <IoClose size={24} />
                  </div>
                  <div className={styles.picture}>
                    <p>{speaker.profile.firstName[0]}.{speaker.profile.lastName[0]}</p>
                  </div>
                  <div>
                    {(watchType && !individualMeetings.includes(watchType) && speaker.region) && (
                      <p>{speaker.region}</p>
                    )}
                    <p className={styles.name}>
                      {speaker.profile.firstName} {speaker.profile.lastName}
                    </p>
                    <p className={styles.function}>{speaker.userFunction}</p>
                  </div>
                </div>
              </li>
            ))}

            {(
              (!watchType || (watchType && !individualMeetings.includes(watchType)))
              || (watchType && individualMeetings.includes(watchType)
                && (speakers || []).length === 0)
            ) && (
              <li>
                <div className={styles.addSpeaker}>
                  <button
                    type='button'
                    className={`invisible ${styles.addSpeakerButton}`}
                    onClick={() => modalRef.current.open()}
                  >
                    <span>+ Ajouter un animateur</span>
                  </button>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <TextEditor
              name='description'
              label='Description*'
              defaultValue={formation?.description}
              control={control}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.description?.message && <ErrorField message={errors?.description?.message} />}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.containerField}>
            <InputDuration
              label='Combien de temps durent les créneaux de formation ?'
              defaultValue={defaultDuration}
              control={control}
            />
          </div>
        </div>
        <div className={styles.row} style={
          (watchType && individualMeetings.includes(watchType))
            ? { display: 'none' }
            : {}
        }>
          <div className={`${styles.containerField} ${styles.seats}`}>
            <InputNumber
              name='seats'
              label='Quel est le nombre de places disponibles par créneau ?'
              control={control}
              inline
              disabled={!!(watchType && individualMeetings.includes(watchType))}
              rules={{
                required: 'Ce champs est obligatoire',
              }}
            />
            {errors?.seats?.message && (
              <ErrorField message={errors?.seats?.message} />
            )}
          </div>
        </div>
        <div className={styles.timeslots}>
          <label>Définir les créneaux</label>
          {days.map((day: IOption) => (
            <div key={`day-${day.value}`} className={styles.day}>
              <div className={styles.dayHeader}>
                <div className={styles.checkbox}>
                  <Checkbox
                    label={day.label}
                    name={`checkbox-${day.value}`}
                    control={controlCheckboxesDays}
                    style='secondary'
                    disabled={isCheckboxDayDisabled(getDefaultTimeslots(day.value as string))}
                  />
                </div>
                <button
                  type='button'
                  className='invisible'
                  disabled={
                    (day.value === 'day1' && !watchDay1) || (day.value === 'day2' && !watchDay2)
                  }
                  onClick={() => (day.value === 'day1' ? inputTimeslotDay1Ref : inputTimeslotDay2Ref).current.appendSlot()}
                >
                  + Ajouter un intervalle
                </button>
              </div>
              <div
                className={styles.containerField}
                style={(day.value === 'day1' && !watchDay1) || (day.value === 'day2' && !watchDay2)
                  ? { display: 'none' }
                  : {}
                }
              >
                <InputTimeslot
                  name={`timeslots-${day.value}`}
                  day={day.value as string}
                  ref={day.value === 'day1' ? inputTimeslotDay1Ref : inputTimeslotDay2Ref}
                  duration={watchDuration || defaultDuration}
                  defaultTimeslots={getDefaultTimeslots(day.value as string)}
                />
              </div>
            </div>
          ))}
        </div>
      </form>
      <div className={styles.inputPicture}>
        <DropInputFile
          name='picture'
          label='+ Ajouter une image d’illustration'
          control={control}
        />
      </div>
    </div>
  );
};

export default forwardRef(FormationForm);
