import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Controller, Control } from 'react-hook-form';
import { IOption } from '../../../../types/options';
import styles from '../styled-select.module.scss';
import {
  styleBase,
  styleBold,
} from '../style';

interface SelectProps {
  name: string,
  control: Control<any>,
  options: IOption[],
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  label?: string | null,
  className?: string,
  isClearable?: boolean,
  disabled?: boolean,
  defaultValue?: any,
  handleChange?: (val: any) => void,
  onCreateOption?: (val: any) => void,
  createLabel?: string,
  prop?: any,
}

const CreatableSelectStyled = ({
  name,
  control,
  rules,
  options,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  className,
  isClearable = false,
  disabled = false,
  defaultValue,
  handleChange,
  onCreateOption,
  createLabel,
  ...props
}: SelectProps) => {
  function getClassName() {
    let style;
    if (className === 'bold') style = styleBold;
    else style = styleBase;
    return style;
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>
            {label}
            {rules && (<sup>*</sup>)}
          </label>}
          <CreatableSelect
            {...field}
            {...props}
            onCreateOption={onCreateOption}
            formatCreateLabel={(inputText) => (createLabel ? (
              <><span className={styles.createLabel}>{createLabel}</span>{inputText}</>
            ) : inputText)}
            value={field.value
              ? options.find((option: IOption) => option.value === field.value)
              : null}
            options={options}
            onChange={(opt: IOption) => {
              field.onChange(props.isMulti ? opt : opt?.value);
              if (handleChange) handleChange(opt);
            }}
            isDisabled={disabled}
            defaultValue={defaultValue}
            isClearable={isClearable}
            className={`${styles.select}`}
            placeholder={placeholder}
            styles={getClassName()}
          />
        </div>
      )}
    />
  );
};

export default CreatableSelectStyled;
