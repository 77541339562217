import React, {
  ForwardRefRenderFunction,
  useRef, useState, useImperativeHandle, forwardRef, useCallback, useMemo,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IOption } from '~/types/options';
import { useAccountsList } from '~/hooks/account';
import { convertDecimalTime } from '~/utils';
import ModalTimeslots from '~/components/ModalTimeslots';
import FilterSearchParam from '~/components/FilterSearchParam';

import styles from './events-form.module.scss';
import { IFormationTimeSlot } from '~/types/formations';

export type EventsFormHandle = {
  getEvents: () => string[];
};

type EventsFormProps = {};
type IEvent = any;
// type IEvents = Partial<IEvent>[];

const itemsCount = 3;

const EventsForm: ForwardRefRenderFunction<
EventsFormHandle, EventsFormProps> = (_, ref) => {
  const [searchParams] = useSearchParams();
  const modalTimeslotsRef = useRef<any>(null);
  const [events, setEvents] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedTimeslots, setSelectedTimeslots] = useState<IFormationTimeSlot[]>([]);
  const day = searchParams.get('day');
  const [countVisibleEvents, setCountVisibleEvents] = useState(itemsCount);

  const {
    data: list,
  } = useAccountsList();
  const {
    formationTypes = [],
    formations = [],
    days = [],
  } = list;

  useImperativeHandle(ref, () => ({
    getEvents: () => events,
  }), [events]);

  useEffect(() => {
    setCountVisibleEvents(itemsCount);
  }, [day]);

  const handleSelectTimeslot = (
    timeslotId: string | null,
    eventId: string,
    timeslot: IFormationTimeSlot,
  ) => {
    let updatedEvents = events;

    // add event
    if (timeslotId && eventId && !updatedEvents.includes(timeslotId)) {
      updatedEvents = [...updatedEvents, timeslotId];
      setSelectedTimeslots((state) => [...state, timeslot]);
    }

    // delete event
    if (timeslotId === null && eventId) {
      const exludeTimeslots = (formations.find((d: IEvent) => d._id === eventId)?.timeslots || [])
        .map((d: IEvent) => d._id);
      updatedEvents = updatedEvents.filter((d: string) => !exludeTimeslots.includes(d));
      setSelectedTimeslots((state) => state.filter((d) => d._id !== timeslotId));
    }

    setEvents(updatedEvents);
  };

  const handleSelectEvent = (event: IEvent) => {
    setSelectedEvent(event);
    modalTimeslotsRef.current.open();
  };

  const getFormationType = useCallback((value: string) => formationTypes
    .find((formationType: IOption) => formationType.value === value)?.label || '', [formationTypes]);

  const getSelectedTimeslotPerEvent = useCallback((eventTimeslots: any[]) => {
    const found = eventTimeslots.find((d) => events.includes(d._id));
    if (!found) return null;
    const timeslotDay = days.find((d: IOption) => d.value === found.day).label;
    return (
      <p className={styles.day}>
        {timeslotDay} {found.label}&nbsp;-&nbsp;
        {convertDecimalTime(found.value + (found.duration / 60))}
      </p>
    );
  }, [events]);

  const defaultTimeslotPerEvent = useMemo(() => {
    const eventTimeslot = (selectedEvent?.timeslots || [])
      .map((d: IEvent) => d._id);
    const found = events.find((d: string) => eventTimeslot.includes(d));
    return found || null;
  }, [selectedEvent, events]);

  const sortedAndFilteredEvents = useMemo(
    () => (
      formations
        .filter((d: any) => (
          d.availableSeats && (!day || d.timeslots.some((tms: any) => tms.day === day))
        ))
        .sort((eventA: IEvent, eventB: IEvent) => (
          eventA.name.localeCompare(eventB.name)
        ))),
    [formations, day],
  );

  return (
    <div className={styles.events}>
      <h2 onClick={() => setIsOpened((state) => !state)}>
        <span>{'Je souhaite m\'inscrire à des formations / conférences'}</span>
        <button className={`invisible ${styles.icon}`} >
          { isOpened ? <FaChevronUp size={22} /> : <FaChevronDown size={22} />}
        </button>
      </h2>
      {isOpened && (
        <>
          <div>
            <div className={styles.field}>
              <FilterSearchParam
                label='Date'
                name='day'
                options={days}
                feminine
              />
            </div>
          </div>
          {sortedAndFilteredEvents.length > 0 && (
            <div className={styles.listEvents}>
              {sortedAndFilteredEvents.slice(0, countVisibleEvents).map((event: IEvent) => (
                <div
                  className={styles.event}
                  key={`event-${event._id}`}
                  onClick={() => handleSelectEvent(event)}
                >
                  <div className={styles.content}>
                    <div>
                      <div>
                        <p className={styles.type}>{getFormationType(event.type)}</p>
                        <p className={styles.name}>{event.name}</p>
                        {getSelectedTimeslotPerEvent(event.timeslots)}
                        <p className={styles.availableSeats}>
                          {event.availableSeats}
                          &nbsp;place{event.availableSeats > 1 ? 's' : ''}
                        </p>
                      </div>
                      <div className={styles.speakers}>
                        <p >
                          {event.speakers.map((speaker: { _id: string, profile: any }) => (
                            `${speaker.profile.firstName} ${speaker.profile.lastName}`
                          )).join(', ')}
                        </p>
                      </div>
                    </div>
                    {event.picture && (
                      <img src={`${process.env.REACT_APP_API_URL}/files/public/${event.picture.path}`} />
                    )}
                  </div>
                </div>
              ))}
              {sortedAndFilteredEvents.length
                > sortedAndFilteredEvents.slice(0, countVisibleEvents).length && (
              <p
                className={styles.seeMore}
                onClick={() => setCountVisibleEvents((state) => state + itemsCount)}
              >
                Voir plus
              </p>
              )}
            </div>
          )}
        </>
      )}
      {<ModalTimeslots
        ref={modalTimeslotsRef}
        event={selectedEvent}
        selectedTimeslots={selectedTimeslots}
        defaultTimeslot={defaultTimeslotPerEvent}
        handleSelectTimeslot={(
          timeslotId: string | null,
          eventId: string,
          timeslot: IFormationTimeSlot,
        ) => {
          handleSelectTimeslot(timeslotId, eventId, timeslot);
        }}
        handleClose={() => setSelectedEvent(null)}
      />}
    </div>
  );
};

export default forwardRef(EventsForm);
