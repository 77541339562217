/* eslint-disable max-len */
import React, {
  ForwardRefRenderFunction,
  useState, useRef, forwardRef, useImperativeHandle, useCallback, useEffect,
} from 'react';
import { useAccountsList } from '~/hooks/account';
import Modal from '~/lib/Modal';
import { convertDecimalTime } from '~/utils';
import { IOption } from '~/types/options';
import { IFormationTimeSlot } from '~/types/formations';
import styles from './modal-timeslots.module.scss';

export type ModalTimeslotsHandle = {
  getTimeslots: () => string | null;
};

type ModalTimeslotsProps = {
  event?: any,
  defaultTimeslot: string | null
  selectedTimeslots: IFormationTimeSlot[],
  handleSelectTimeslot: (
    timeslotId: string | null, eventId: string, timeslot: IFormationTimeSlot) => void,
  handleClose: () => void,
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalTimeslotsHandle, ModalTimeslotsProps> = ({
  event,
  defaultTimeslot,
  selectedTimeslots,
  handleSelectTimeslot,
  handleClose,
}, ref) => {
  const modalRef = useRef<any>(null);
  const [timeslot, setTimeslot] = useState<string | null>(null);

  useEffect(() => {
    if (defaultTimeslot && timeslot !== defaultTimeslot) setTimeslot(defaultTimeslot);
  }, [defaultTimeslot]);

  const {
    data: list,
  } = useAccountsList();
  const {
    days = [],
  } = list;

  useImperativeHandle(ref, () => ({
    getTimeslots: () => timeslot,
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }), []);

  const getTimeslotsPerDay = useCallback((day: string) => (
    (event?.timeslots || []).filter((d: IFormationTimeSlot) => d.day === day && d.availableSeats !== 0)
  ), [event?.timeslots]);

  const getDay = useCallback((value: string) => days
    .find((day: IOption) => day.value === value).label || '', [days]);

  const isTimeslotDisabled = useCallback((tmslot: IFormationTimeSlot) => {
    const timeslotStart = tmslot.value;
    const timeslotEnd = tmslot.value + (tmslot.duration / 60);
    const isDisabled = selectedTimeslots.some((d: any) => {
      const start = d.value;
      const end = d.value + (d.duration / 60);

      return (d.day === tmslot.day
        && ((timeslotStart <= start && start <= timeslotEnd)
        || (timeslotStart <= end && end <= timeslotEnd)
        || (start <= timeslotStart && timeslotEnd <= end))
      );
    });
    return isDisabled;
  }, [selectedTimeslots]);

  const renderDay = (day: string) => (
    <>
      {getTimeslotsPerDay(day).length > 0 && (
        <div className={styles.day}>
          <p className={styles.name}>{getDay(day)}</p>
          <div className={styles.timeslots}>
            {getTimeslotsPerDay(day).map((d: IFormationTimeSlot) => (
              <button
                disabled={isTimeslotDisabled(d)}
                className={timeslot === d._id ? styles.selected : ''}
                key={`timeslots-${d._id}`}
                onClick={() => {
                  setTimeslot((state) => (state === d._id ? null : d._id));
                }}
              >
                {d.label}&nbsp;-&nbsp;
                {convertDecimalTime(d.value + (d.duration / 60))}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal width={420} ref={modalRef} handleClose={() => {
      setTimeslot(null);
      handleClose();
    }}>
      <div className={styles.modal}>
        <h3>{event?.name}</h3>
        {renderDay('day1')}
        {renderDay('day2')}
        <div className={styles.containerActions}>
          <button onClick={() => {
            handleSelectTimeslot(
              timeslot || null,
              event._id,
              (event?.timeslots || []).find((d: IFormationTimeSlot) => d._id === timeslot),
            );
            modalRef.current.close();
            handleClose();
          }}>
            Valider ma participation
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
