import React from 'react';
import { IoSearch } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { InputText } from '../../lib/HooksFormFields';
import styles from './input-search.module.scss';

const InputSearch = (
  { defaultValue = '', handleSearch } : { defaultValue?: string, handleSearch : (str: string) => void },
) => {
  const { control } = useForm({
    defaultValues: { search: defaultValue },
  });
  return (
    <div className={styles.inputSearch}>
      <IoSearch size={20} />
      <InputText
        name='search'
        placeholder='Rechercher...'
        control={control as any}
        handleChange={(value) => handleSearch(value)}
      />
    </div>
  );
};

export default InputSearch;
