/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ArgsGetFormations, IFormation } from '~/types/formations';
import {
  getFormations,
  getFormationsList,
  getFormation,
  createFormation,
  updateFormation,
  deleteFormation,
} from '../../api/formations';

function useGetFormations(params: ArgsGetFormations) {
  return useQuery({
    queryKey: ['formations', params],
    queryFn: () => getFormations(params),
    keepPreviousData: true,
  });
}

const useGetFormation = (id: string, enabled: boolean = true) => useQuery({
  queryKey: ['formation', id],
  queryFn: async (): Promise<IFormation | null> => getFormation(id),
  keepPreviousData: true,
  enabled,
});

const useGetFormationsList = () => useQuery({
  queryKey: ['formations-list'],
  queryFn: () => getFormationsList(),
});

const useCreateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FormData) => createFormation(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['formations'] });
    },
  });
};

const useUpdateFormation = (successMessage?: string) => {
  const notify = () => toast(successMessage);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { _id: string, updatedData: FormData }) => updateFormation(data),
    onSuccess: async ({ formation }) => {
      queryClient.invalidateQueries({ queryKey: ['formations'] });
      queryClient.setQueryData(['formation', formation._id], formation);
      if (successMessage) notify();
    },
  });
};

const useDeleteFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteFormation(id),
    onSuccess: async (_: any, variables: any) => {
      console.log(variables);
      queryClient.invalidateQueries({ queryKey: ['formations'] });
    },
  });
};

export {
  useGetFormations,
  useGetFormation,
  useGetFormationsList,
  useCreateFormation,
  useUpdateFormation,
  useDeleteFormation,
};
