import React from 'react';
import { NavLink } from 'react-router-dom';
import logoU from '../../assets/images/logo-u.png';
import imgSignin from '../../assets/images/signin-logo.png';
import styles from './register-types.module.scss';

const RegisterTypes = () => (
  <div className={styles.registerTypes}>
    <div className={styles.logo}>
      <img src={logoU} alt= "Logo U enseigne" />
    </div>
    <div className={styles.container}>
        <div>
          <h1>Bienvenue au</h1>
          <img src={imgSignin} alt= "Bienvenue au salon U" />
        </div>
        <div className={styles.form}>
          <p>
            Je m’inscris ou je me connecte pour
            découvrir les informations sur le salon U Enseigne 2024
          </p>
          <h1>Je suis :</h1>
          <div className={styles.types}>
            <NavLink to='/register/magasin'>
              <button>
                Magasin
              </button>
            </NavLink>
            <NavLink to='/register/collaborateur'>
              <button>
                Collaborateur centrale
              </button>
            </NavLink>
            <NavLink to='/register/fournisseur'>
              <button>
                Fournisseur
              </button>
            </NavLink>
          </div>
          <NavLink className={styles.toSignIn} to="/">
            Vous avez déjà un compte
          </NavLink>
        </div>
    </div>
  </div>
);

export default RegisterTypes;
