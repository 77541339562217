import React from 'react';
import styles from './items.module.scss';
import emulation from '../../../assets/images/exhibitions/emulation.png';
import proxi from '../../../assets/images/exhibitions/proxi.png';
import formationOpv from '../../../assets/images/exhibitions/formation-opv.png';
import rhMagasin from '../../../assets/images/exhibitions/rh-magasin.png';
import forceU from '../../../assets/images/exhibitions/force-u.png';
import digitalMarketing from '../../../assets/images/exhibitions/digital-marketing.png';
import iris from '../../../assets/images/exhibitions/iris.png';

const Items = ({ switchedItem } : { switchedItem?: boolean }) => (
  <div className={styles.content}>
    <h1>{switchedItem ? 'Parcours client, formation et organisation PDV' : 'Formations & organisation point de vente'}</h1>
    <div className={styles.planContainer}>
      <h2>Mezzanine Grand Palais</h2>
      <a href={`${process.env.REACT_APP_API_URL}/files/preview/${process.env.REACT_APP_PLAN_GP_FILE_ID}`} target='_blank' rel='noreferrer'>Voir sur le plan</a>
    </div>
    <div className={styles.items}>
      <div className={styles.item}>
        <img src={digitalMarketing} alt="digital marketing com services supports" />
        <div className={styles.itemTexts}>
          <h3>Digital<br></br> Marketing com Services Supports</h3>
          <p>{'L\'avenir de la digitalisation de la promotion, du e-commerce et des outils de productivité Courses.'}</p>
        </div>
      </div>
      <div className={styles.item}>
        <img src={switchedItem ? iris : forceU} alt="force U" />
        <div className={styles.itemTexts}>
          <h3>{switchedItem ? 'Iris' : 'Force U'}</h3>
          {switchedItem && <p>Entretien <br></br>& cybersécurité <br></br>informatique.</p>}
          {!switchedItem && <p>Nouvelle offre de formation 2024</p>}
          {!switchedItem && <p>UF-PDV et ses modules complémentaires.</p>}
        </div>
      </div>
      <div className={styles.item}>
        <img src={formationOpv} alt="formation OPV" />
        <div className={styles.itemTexts}>
          <h3>Formation OPV <br></br> et Orga Points de Vente</h3>
          <p>Formation nouveaux collaborateurs (MobUlis, Ulis et Mona), en magasin ou à distance.
            </p>
        </div>
      </div>
      <div className={styles.item}>
        <img src={rhMagasin} alt="RH magasin" />
        <div className={styles.itemTexts}>
          <h3>RH Magasin</h3>
          <p>{'Gain performance : nouveaux outils d\'attractivité/fidélisation de vos collaborateurs + nouvelle offre Proximité -11 salariés.'}</p>
        </div>
      </div>
      <div className={styles.item}>
        <img src={emulation} alt="émulation" />
        <div className={styles.itemTexts}>
          <h3>Émulation</h3>
          <p>Pilotage du Géomarketing & Générosité via U-Perf</p>
          <p>{'Nouvelle rubrique \'POP CoursesU\'.'} </p>
        </div>
      </div>
      <div className={styles.item}>
        <img src={proxi} alt="proxi" />
        <div className={styles.itemTexts}>
          <h3>Proxi</h3>
          <p>Echanges sur Concept Utile et U Express.</p>
        </div>
      </div>
    </div>
  </div>
);

export default Items;
