/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import { ArgsGetProducts } from '~/types/products';
import {
  getProducts,
  getProduct,
  getProductsList,
  getProductsMagasins,
} from '~/api/products';

function useGetProducts(params: ArgsGetProducts) {
  const productsParams = { ...params };
  if (!productsParams.page) productsParams.page = 1;

  return useQuery({
    queryKey: ['products', productsParams],
    queryFn: () => getProducts(productsParams),
    keepPreviousData: true,
  });
}

function useGetProduct(_id: string) {
  return useQuery({
    queryKey: ['product', _id],
    queryFn: () => getProduct(_id),
    keepPreviousData: true,
  });
}

function useGetProductsMagasins(params: ArgsGetProducts) {
  const productsParams = { ...params };
  if (!productsParams.page) productsParams.page = 1;

  return useQuery({
    queryKey: ['products', productsParams],
    queryFn: () => getProductsMagasins(productsParams),
    keepPreviousData: true,
  });
}

function useGetProductsList() {
  return useQuery({
    queryKey: ['products-list'],
    queryFn: () => getProductsList(),
    keepPreviousData: true,
  });
}

export {
  useGetProducts,
  useGetProduct,
  useGetProductsMagasins,
  useGetProductsList,
};
