import React from 'react';
import styles from './usefulInformations.module.scss';
import Banner from '../../components/exhibitions/Banner/index';
import Footer from '../../components/Footer/index';
import gesteZeroGaspi from '../../assets/images/geste-zero-gaspi.png';
import taxi from '../../assets/images/taxi.png';
import tramway from '../../assets/images/tram.png';
import train from '../../assets/images/train.png';
import avion from '../../assets/images/avion.png';
import planGrandPalais from '../../assets/images/exhibitions/plan_grand_palais.jpg';

const UsefulInformations = () => (
  <div className={styles.usefulInformations}>
    <Banner />
    <div className={styles.content}>
      <h1>Informations pratiques</h1>
      <div className={styles.cards}>
        <div className={styles.card}>
          <h2>Les horaires</h2>
          <h3>Mercredi 19 juin</h3>
          <p>de 8h à 19h</p>
          <h3>Jeudi 20 juin</h3>
          <p>de 8h à 17h</p>
        </div>
        <div className={styles.card}>
          <h2>Le lieu</h2>
          <h3>Parc des expositions de La Beaujoire à Nantes</h3>
          <p>Route de St Joseph 44300 Nantes</p>
        </div>
        <div className={styles.card}>
          <h2>Restauration</h2>
          <h3>Hall Rivière</h3>
          <p>de 11h45 à 14h</p>
          <hr className={styles.hr}/>
          <div className={styles.zeroGaspi}>
            <img src={gesteZeroGaspi} alt="mon geste zéro gaspi" />
            <div className={styles.textZeroGaspi}>
              <h4>La nouveauté cette année :</h4>
              <p>Un repas anticipé, des déchets évités&nbsp;!</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.plan}>
        <div className={styles.spacePlan}>
          <img src={planGrandPalais} alt="Plan du Grand Palais" />
        </div>
        <a href={`${process.env.REACT_APP_API_URL}/files/download/${process.env.REACT_APP_PLAN_FILE_ID}`} download>
          <button>
            Télécharger le plan
          </button>
        </a>
      </div>
      <div className={styles.locomotion}>
        <h1>Comment vous y rendre ?</h1>
        <div className={styles.locomotionItems}>
          <div className={styles.locomotionItem}>
            <div className={styles.imgContainer}>
              <img src={taxi} alt="taxi" />
            </div>
            <h5>Taxi</h5>
            <p>
              02 40 69 22 22
              <br />
              ou
              <br />
              06 98 77 32 93
            </p>
          </div>
          <div className={styles.locomotionItem}>
            <div className={styles.imgContainer}>
              <img src={tramway} alt="tramway" />
            </div>
            <h5>Tramway</h5>
            <p>Ligne 1 - Arrêt Beaujoire</p>
            <h5>Bus</h5>
            <p>Lignes C6 et 75</p>
          </div>
          <div className={styles.locomotionItem}>
            <div className={styles.imgContainer}>
              <img src={train} alt="train" />
            </div>
            <h5>Train</h5>
            <p>36 35</p>
          </div>
          <div className={styles.locomotionItem}>
            <div className={styles.imgContainer}>
              <img src={avion} alt="avion" />
            </div>
            <h5>Avion</h5>
            <p>0892 56 88 00</p>
          </div>
        </div>
        </div>
      <a href={`${process.env.REACT_APP_API_URL}/files/download/${process.env.REACT_APP_PLAN_RESTAU_HOTELS}`} target='_blank' rel='noreferrer'>Découvrez les hôtels et restaurants aux alentours</a>
    </div>
    <Footer />
  </div>
);

export default UsefulInformations;
