import React, { useEffect } from 'react';
import {
  useNavigate,
  Routes, Route,
} from 'react-router-dom';
import { ICompany } from './types/companies';
import Layout from './components/Layout';
import Register from './pages/register';
import QRReader from './pages/qr-reader';
import Account from './pages/account';
import SignIn from './pages/signin';
import RegisterTypes from './pages/register-types';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import { useAccount } from './hooks/account';
import Exhibitions from './pages/exhibitions';
import Bazar from './pages/bazar';
import Formations from './pages/formations';
import Conferences from './pages/conferences';
import Providers from './pages/providers';
import AnimatorFormations from './pages/animator/formations';
import Products from './pages/products';
import ProductsList from './pages/products/list';
import ProductDetail from './pages/products/detail';
import ProductWaiting from './pages/products/waiting-page';
import ProductsProvider from './pages/providers-products';
import ProductsProviderList from './pages/providers-products/list';
import ProductsProviderDetail from './pages/providers-products/detail';

import ShoppingCart from './pages/shopping-cart';
import ShoppingCartList from './pages/shopping-cart/list';
import ShoppingCartDetail from './pages/shopping-cart/detail';

import Orders from './pages/orders';
import OrdersList from './pages/orders/list';
import OrdersDetail from './pages/orders/detail';

import Loader from './components/Loader';
import Anm from './pages/anm';
import Pgc from './pages/pgc';
import UsefulInformations from './pages/usefulInformations';
import WaitingPage from './pages/waiting-page';

const Redirect = () => {
  const navigate = useNavigate();
  const { data } = useAccount();
  const account = data?.account;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!account && !token) {
      navigate('/login');
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Loader />
    </div>
  );
};

const AppRoutes = () => {
  const { data } = useAccount();
  const account = data?.account;
  const { company: companyAccount = {} } = account || {};
  const companyType = companyAccount ? (companyAccount as ICompany).type : null;

  return (
    <>
      <Routes>
        {process.env.REACT_APP_COMING_SOON ? (
          <Route path="*" element={<WaitingPage />} />
        ) : (
          <>
            <Route path="/login" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/create-password/:token" element={<ResetPassword createPassword />} />
            <Route path="/register-types" element={<RegisterTypes />} />
            <Route path="/register/:type" element={<Register />} />
            {account && (
              <Route path='*' element={
                <Layout key='app'>
                  <Routes>
                    <Route path='*' element={<Exhibitions />} />
                    <Route path='/bazar' element={<Bazar />} />
                    <Route path='/anm' element={<Anm />} />
                    <Route path='/pgc' element={<Pgc />} />
                    <Route path='/infos-pratiques' element={<UsefulInformations />} />
                    <Route path='/formations/*' element={<Formations />} />
                    {!process.env.REACT_APP_LOCK_CONFERENCES && (
                      <Route path='/conferences/*' element={<Conferences />} />
                    )}
                    {companyType === 'magasin' && (
                      <>
                        <Route path='/produits' element={<Products />}>
                          {process.env.REACT_APP_LOCK_PRODUCTS
                            ? <Route index element={<ProductWaiting />} />
                            : <>
                              <Route index element={<ProductsList />} />
                              <Route path=':id' element={<ProductDetail />} />
                            </>
                          }
                        </Route>
                        <Route path='fournisseurs' element={<Providers />} />
                        {!process.env.REACT_APP_LOCK_PRODUCTS && (
                          <Route path='/paniers' element={<ShoppingCart />}>
                            <Route index element={<ShoppingCartList />} />
                            <Route path=':id' element={<ShoppingCartDetail />} />
                          </Route>
                        )}
                      </>
                    )}
                    {(!process.env.REACT_APP_LOCK_PRODUCTS && companyType === 'fournisseur') && (
                      <>
                        <Route path='/produits' element={<ProductsProvider />}>
                          <Route index element={<ProductsProviderList />} />
                          <Route path=':id' element={<ProductsProviderDetail />} />
                        </Route>
                        <Route path='/paniers' element={<Orders />}>
                          <Route index element={<OrdersList />} />
                          <Route path=':id' element={<OrdersDetail />} />
                        </Route>
                      </>
                    )}
                    <Route path='/animateur/formations/*' element={<AnimatorFormations />} />
                    <Route path='/scan-qr' element={<QRReader />} />
                    <Route path='/account' element={<Account />} />
                  </Routes>
                </Layout>
              }/>
            )}
            <Route path='*' element={<Redirect />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
