/* eslint-disable import/prefer-default-export */
import {
  useQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ArgsGetOrders, DataHandleOrders, DataDeleteOrderProduct, IOrder,
} from '~/types/orders';
import {
  getOrders, getOrder, handleOrder, updateOrder,
  getOrdersList,
  deleteOrderProduct,
} from '~/api/orders';
import { IProduct } from '~/types/products';
import { getProduct } from '~/api/products';

function useGetOrders(params: ArgsGetOrders, enabled = true) {
  const productsParams = { ...params };

  return useQuery({
    queryKey: ['orders', productsParams],
    queryFn: () => getOrders(productsParams),
    keepPreviousData: true,
    enabled,
  });
}

function useGetOrder(_id: string, enabled = true) {
  return useQuery({
    queryKey: ['orders', _id],
    queryFn: () => getOrder(_id),
    keepPreviousData: true,
    enabled,
  });
}

function useGetOrdersList() {
  return useQuery({
    queryKey: ['orders-list'],
    queryFn: () => getOrdersList(),
    keepPreviousData: true,
  });
}

const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IOrder>) => updateOrder(data),
    onSuccess: async ({ order }) => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.setQueryData(['orders', order._id], order);
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

const useHandleOrder = (successMessage?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: DataHandleOrders) => handleOrder(data),
    onSuccess: async (order: IOrder, args: DataHandleOrders) => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });

      try {
        const product:IProduct | undefined = await getProduct(args.productId);

        if (!product) {
          toast.error('Un erreur est survenu');
        }

        //* Update data in query for product
        queryClient.setQueryData(['product', args.productId], product);

        //* Update data in queries on all products
        const cacheEntriesProducts: any = queryClient.getQueriesData(['products']);
        cacheEntriesProducts.forEach(([queryKeyAndFilter, data]: any) => {
          const udpatedData = data.products.map((d: any) => {
            if (d._id === args.productId) return product;
            return d;
          });
          queryClient.setQueryData(queryKeyAndFilter, {
            ...data,
            products: udpatedData,
          });
        });

        if (successMessage) {
          const message = successMessage.replace('__productName__', product?.name || 'Le produit');
          toast(message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
};

const useDeleteOrderProduct = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  return useMutation({
    // eslint-disable-next-line max-len
    mutationFn: (data) => deleteOrderProduct(data),
    onSuccess: async (order: IOrder, args: DataDeleteOrderProduct) => {
      if (order === null) {
        if (location.pathname === `/paniers/${args.orderId}`) {
          navigate(-1);
        }
        queryClient.removeQueries({ queryKey: ['orders', args.orderId] });
      }
      queryClient.invalidateQueries({ queryKey: ['products'] });
      queryClient.invalidateQueries(['product', args.productId]);
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      // queryClient.setQueryData(['orders', args.orderId], order);
    },
  });
};

export {
  useGetOrders,
  useGetOrder,
  useGetOrdersList,
  useUpdateOrder,
  useHandleOrder,
  useDeleteOrderProduct,
};
